import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import Modal from '../../../../Overlays/Modal/Modal';

import Cropy from '../CropComponent/Cropy';

const CropWrapper = (props) => {
  const [openCropModal, setOpenCropModal] = useState(true);
  const { saveCallBack, shape, file, change, cropWidth, cropHeight, enableResize } = props;

  useEffect(() => {
    if (file) setOpenCropModal(true);
  }, [file]);

  return (
    <>
      {openCropModal && (
        <Modal
          position="center"
          width="100%"
          height="100%"
          className="modal-full-screen-For-Cropy noAnimation"
          isCloseable
          onRequestClose={() => setOpenCropModal(false)}
        >
          <Cropy
            exitCallBack={() => setOpenCropModal(false)}
            saveCallBack={saveCallBack}
            cropWidth={cropWidth}
            cropHeight={cropHeight}
            shape={shape}
            file={file}
            change={change}
            enableResize={enableResize}
          />
        </Modal>
      )}
      <div className="CropWrapper-component" />
    </>
  );
};

CropWrapper.propTypes = {
  /* the width of thefinal crop */
  cropWidth: PropTypes.number,
  /* the height of the final crop */
  cropHeight: PropTypes.number,
  /* shape of the final crop  */
  shape: PropTypes.oneOf(['square', 'circle']),
  /* save callback the parameter is file */
  saveCallBack: PropTypes.func.isRequired,
  /* used to disable the resize proprety of the cropt img */
  enableResize: PropTypes.bool,
  file: PropTypes.string,
  change: PropTypes.func,
};

CropWrapper.defaultProps = {
  cropWidth: 200,
  cropHeight: 200,
  shape: 'circle',
  enableResize: true,
  file: '',
  change: () => {},
};

export default CropWrapper;
