import React from 'react';
import './Badge.less';
import PropTypes from 'prop-types';
import { BADGE_TYPE } from './constants';

const Badge = (props) => {
  const { integerNumber } = props;
  return (
    <span className="gradetype-img-container badge-comp">
      <img src={BADGE_TYPE[integerNumber] ? BADGE_TYPE[integerNumber].svg : BADGE_TYPE[34].svg} alt="badge" />
    </span>
  );
};

Badge.propTypes = {
  /** numarul de tip int care vine din api. tipul imaginii  */
  integerNumber: PropTypes.number,
};

Badge.defaultProps = {
  integerNumber: null,
};

export default Badge;
