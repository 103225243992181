const removeDuplicates = (arr, comp) => {
  const unique = arr
    .map((e) => {
      return e[comp];
    })

    // store the keys of the unique objects
    .map((e, i, final) => {
      return final.indexOf(e) === i && i;
    })

    // eliminate the dead keys & store unique objects
    .filter((e) => {
      return arr[e];
    })
    .map((e) => {
      return arr[e];
    });

  return unique;
};

export default removeDuplicates;
