/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';

import Content from './Content';

import './Tooltip.less';

const Tooltip = ({ children, hAlign, vAlign, worksOnClick, isOpen, triggerProps, className }) => {
  const nodeRef = useRef();
  const contentRef = useRef();
  const triggerRef = useRef();

  const [trigger, content] = children;

  const [open, setOpen] = useState(false);

  const contentOptions = { triggerRef, hAlign, vAlign, worksOnClick };

  const handleClick = (e) => {
    const { current: currentNodeRef } = nodeRef;
    const { current: currentContentRef } = contentRef;
    const { target } = e;

    if (
      (nodeRef && currentNodeRef && currentNodeRef.contains(target)) ||
      (contentRef && currentContentRef && currentContentRef.contains(target))
    ) {
      return;
    }

    setOpen(false);
  };

  const handleMouseClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleMouseOver = () => {
    setOpen(true);
  };

  const handleMouseOut = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (isOpen) {
      setOpen(true);
    }

    if (isOpen === false) {
      setOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (!worksOnClick) {
      return () => {};
    }

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [worksOnClick]);

  const renderBody = () => {
    return (
      <>
        <span className="tooltip__trigger" ref={triggerRef} {...triggerProps}>
          {trigger}
        </span>
        {open && <Content contentRef={contentRef} content={content} options={contentOptions} className={className} />}
      </>
    );
  };

  const renderTooltipOnClick = () => (
    <span role="button" tabIndex={0} className="tooltip" onClick={handleMouseClick} ref={nodeRef} onKeyPress={() => {}}>
      {renderBody()}
    </span>
  );

  const renderTooltipOnHover = () => (
    <span className="tooltip" onMouseEnter={handleMouseOver} onMouseLeave={handleMouseOut}>
      {renderBody()}
    </span>
  );

  return worksOnClick ? renderTooltipOnClick() : renderTooltipOnHover();
};

Tooltip.defaultProps = {
  hAlign: 'left',
  vAlign: 'bottom',
  worksOnClick: false,
  openOnInit: false,
  triggerProps: {},
  isOpen: false,
};

Tooltip.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  isOpen: PropTypes.bool,
  vAlign: PropTypes.string,
  hAlign: PropTypes.string,
  worksOnClick: PropTypes.bool,
  openOnInit: PropTypes.bool,
  triggerProps: PropTypes.instanceOf(Object),
};

export default Tooltip;
