import React from 'react';

import PropTypes from 'prop-types';

import './Breadcrumbs.less';

class Breadcrumbs extends React.PureComponent {
  render() {
    const { children, className } = this.props;
    return (
      <ul className={`comp-breadcrumbs ${className}`}>
        {children.map((child, index) => {
          if (child && child.length) {
            return child.map((el) => {
              return (
                <li key={el.key} className={`${el.className ? el.className : ''} `}>
                  <button type="button" className="comp-breadcrumbs__element" onClick={el.props.onClick}>
                    {el.props.children}
                  </button>
                  <span> /</span>
                </li>
              );
            });
          }
          return (
            child && (
              // eslint-disable-next-line react/no-array-index-key
              <li key={index} className={`${child.props.className ? child.props.className : ''} `}>
                <button type="button" className="comp-breadcrumbs__element" onClick={child.props.onClick}>
                  {child.props.children}
                </button>
                <span> /</span>
              </li>
            )
          );
        })}
      </ul>
    );
  }
}

Breadcrumbs.propTypes = {
  /** navigation list */
  children: PropTypes.node,
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  children: '',
  className: '',
};

export default Breadcrumbs;
