import React from 'react';

import PropTypes from 'prop-types';

import { FSTIP_DIR } from '../../../../constants';

import imageIcon from '../../../Helpers/buildImageIcon';

import './FileIcon.less';

import album from './images/album.svg';
import excel from './images/excel.svg';
import word from './images/word.svg';
import image from './images/image.svg';
import pdf from './images/pdf.svg';
import powerpoint from './images/powerpoint.svg';
import text from './images/text.svg';
import video from './images/video.svg';
import audio from './images/audio.svg';
import arhiva from './images/arhiva.svg';
import othersEmpty from './images/others-empty.svg';

const FileIcon = (props) => {
  let icon;
  let ext = null;
  const { fsExt, fsTip, file } = props;
  let pathImg = '';

  if (fsTip && fsTip === FSTIP_DIR) {
    icon = <span className="_icon icon-folder" />;
    if (file?.isAlbum) {
      icon = <img src={album} alt="iconPath" className="inlineb middle" />;
    }
  } else if (fsExt) {
    const iconObj = imageIcon(fsExt);
    switch (iconObj.image) {
      case 'arhiva':
        pathImg = arhiva;
        break;
      case 'image':
        pathImg = image;
        break;
      case 'audio':
        pathImg = audio;
        break;
      case 'video':
        pathImg = video;
        break;
      case 'text':
        pathImg = text;
        break;
      case 'powerpoint':
        pathImg = powerpoint;
        break;
      case 'pdf':
        pathImg = pdf;
        break;
      case 'word':
        pathImg = word;
        break;
      case 'excel':
        pathImg = excel;
        break;
      default:
        pathImg = othersEmpty;
        break;
    }
    icon = <img src={pathImg} alt="iconPath" className="inlineb middle" />;
    if (iconObj?.showExtension) {
      ext = <span className="extension">.{fsExt}</span>;
    }
  }

  return (
    <span className="fileicon">
      {icon}
      {ext}
    </span>
  );
};

FileIcon.propTypes = {
  /** Tipul fisierului: 1 - folder; 2 - fisier normal */
  fsTip: PropTypes.string,
  /** Extensia fisierului */
  fsExt: PropTypes.string,
  file: PropTypes.instanceOf(Object),
};
FileIcon.defaultProps = {
  fsTip: null,
  fsExt: '',
  file: {},
};

export default FileIcon;
