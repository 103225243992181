import React, { useState, useEffect } from 'react';

import PropTypes from 'prop-types';

import getPositions from './helpers/getPositions';

import debounce from './helpers/debounce';

import Portal from '../../Portal';

import './Tooltip.less';

const Content = ({ contentRef, content, options, className }) => {
  const [positions, setPositions] = useState();

  const { triggerRef, hAlign, vAlign, worksOnClick } = options;

  const {
    vertical: { value: top, cssClass: verticalCssClass } = {},
    horizontal: { value: left, cssClass: horizontalCssClass } = {},
  } = positions || {};

  const computePositions = () => {
    const computedPositions = getPositions({ triggerRef, contentRef, hAlign, vAlign });

    setPositions(computedPositions);
  };

  useEffect(() => {
    computePositions();
  }, []);

  useEffect(() => {
    if (!worksOnClick) {
      return () => {};
    }

    const debounceRef = debounce(computePositions, 10);

    window.addEventListener('resize', debounceRef);

    return () => {
      window.removeEventListener('resize', debounceRef);
    };
  }, []);

  const recalcTop = vAlign === 'top' ? top - 2 : top + 2;
  return (
    <Portal>
      <div
        className={`tooltip__content ${verticalCssClass} ${horizontalCssClass} ${className}`}
        style={{ top: top ? recalcTop : top, left }}
        ref={contentRef}
      >
        {content}
      </div>
    </Portal>
  );
};

Content.propTypes = {
  content: PropTypes.instanceOf(Object).isRequired,
  options: PropTypes.instanceOf(Object).isRequired,
  contentRef: PropTypes.instanceOf(Object).isRequired,
  className: PropTypes.string,
};

Content.defaultProps = {
  className: '',
};

export default Content;
