import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import './GroupTag.less';

const GroupTag = (props) => {
  const { group, tooltip, styleTag } = props;
  const { t } = useTranslation('common');
  const [tagDetails, setTagDetails] = useState({});

  useEffect(() => {
    let userInfo = {};

    switch (group.value) {
      case 'g-elevi-all':
        userInfo = {
          type: t('text_toti_elevii_scoala'),
          prefAvatar: 'icon-elevi',
        };
        break;
      case `g-elevi-ast-${group.astID}`:
      case 'g-elevi-ast':
        userInfo = {
          type: t('text_toti_elevii_din_', { 0: `${group.astNume}` }),
          prefAvatar: 'icon-elevi',
        };
        break;
      case `g-elevi-cl-${group.clID}`:
      case 'g-elevi-cl':
        userInfo = {
          type: t('text_toti_elevii_din_', { 0: `${group.clNume}` }),
          prefAvatar: 'icon-elevi',
        };
        break;

      case 'g-parinti-all':
        userInfo = {
          type: t('text_toti_parintii_scoala'),
          prefAvatar: 'icon-parinti',
        };
        break;
      case `g-parinti-ast-${group.astID}`:
      case 'g-parinti-ast':
        userInfo = {
          type: t('text_toti_parintii_elevilor_', { 0: `${group.astNume}` }),
          prefAvatar: 'icon-parinti',
        };
        break;
      case `g-parinti-cl-${group.clID}`:
      case 'g-parinti-cl':
        userInfo = {
          type: t('text_toti_parintii_elevilor_', { 0: `${group.clNume}` }),
          prefAvatar: 'icon-parinti',
        };
        break;

      case 'g-cadre-all':
        userInfo = {
          type: t('text_toate_cadrele'),
          prefAvatar: 'icon-cadre-2',
        };
        break;
      case `g-cadre-ast-${group.astID}`:
      case 'g-cadre-ast':
        userInfo = {
          type: t('text_toate_cadrele_clasa_', { 0: `${group.astNume}` }),
          prefAvatar: 'icon-cadre-2',
        };
        break;
      case `g-cadre-cl-${group.clID}`:
      case 'g-cadre-cl':
        userInfo = {
          type: t('text_toate_cadrele_nume_clasa_', { 0: `${group.clNume}` }),
          prefAvatar: 'icon-cadre-2',
        };
        break;
      case `g-cadre-mat-${group.matID}`:
      case 'g-cadre-mat':
        userInfo = {
          type: t('text_toate_cadrele_materia_', { 0: `${group.matNume}` }),
          prefAvatar: 'icon-cadre-2',
        };
        break;
      case 'g-group':
        userInfo = {
          type: t('text_group_share_', { 0: `${t(`${group.grpName}`)}` }),
          prefAvatar: 'icon-groups',
        };
        break;

      case 'g-adservio':
        userInfo = {
          type: t('text_echipa_adservio'),
          prefAvatar: 'icon-admin',
        };
        break;

      case 'colegii-clasei':
        userInfo = {
          type: t('text_colegi_clasa'),
          subType: t('text_toti_colegii_de_clasa'),
          prefAvatar: 'icon-clasa',
        };
        break;
      case 'elevii-clasei':
        userInfo = {
          type: t('text_elevi_clasa'),
          subType: t('text_toti_elevii_din_', { 0: `${group.clNume}` }),
          prefAvatar: 'icon-clasa',
        };
        break;

      case 'parintii-clasei':
        userInfo = {
          type: t('text_parinti_clasa'),
          subType: t('text_toti_parintii_elevilor_', { 0: `${group.clNume}` }),
          prefAvatar: 'icon-parinti',
        };
        break;

      case 'diriginte':
        userInfo = {
          type: t('text_diriginte'),
          subType: t('text_diriginte_la_', { 0: `${group.clNume}` }),
          prefAvatar: 'icon-cadre-stick',
        };
        break;
      case 'invatator':
        userInfo = {
          type: t('text_invatator'),
          subType: t('text_invatator_la_', { 0: `${group.clNume}` }),
          prefAvatar: 'icon-cadre-stick',
        };
        break;
      case 'educator':
        userInfo = {
          type: t('text_educator'),
          subType: t('text_educator_la_', { 0: `${group.clNume}` }),
          prefAvatar: 'icon-cadre-stick',
        };
        break;

      case 'generic':
        userInfo = {
          type: group.title,
          subType: group.subTitle,
          prefAvatar: group.icon,
          extraIcon: group.extraIcon,
        };
        break;

      case 'g-scoli-all':
        userInfo = {
          type: t('text_toate_scolile'),
          prefAvatar: false,
        };
        break;
      case `g-scoala-${group.school && group.school.liceuID ? group.school.liceuID : ''}`:
        userInfo = {
          type: `${group.school.liceuClasificare} ${group.school.liceuNume}, ${group.school.liceuAdresa.adrJudet.judNume}, ${group.school.liceuAdresa.adrLocalitate}`,
          prefAvatar: false,
        };
        break;

      case 'g-judete-all':
        userInfo = {
          type: t('text_toate_scoli_judet_', { 0: `${group.judNume}` }),
          prefAvatar: false,
        };
        break;
      case `g-judet-${group.judID}`:
        userInfo = {
          type: t('text_toate_scoli_judet_', { 0: `${group.judNume}` }),
          prefAvatar: false,
        };
        break;

      case 'g-orase-all':
        userInfo = {
          type: t('text_toate_orasele'),
          prefAvatar: false,
        };
        break;
      case `g-oras-${group.orasNume}`:
        userInfo = {
          type: t('text_toate_scoli_oras_', { 0: `${group.orasNume}` }),
          prefAvatar: false,
        };
        break;
      case `g-contract-${group.tctrID}`:
        userInfo = {
          type: t('text_toate_scoli_contract_', { 0: `${group.tctrNume}` }),
          prefAvatar: false,
        };
        break;
      default:
        break;
    }

    setTagDetails(userInfo);
  }, []);

  const getInfo = () => {
    return (
      <span>
        <span className={`fcupper ${tagDetails.prefAvatar === 'noAvatar' ? 'cursor-default' : 'bold-title'} `}>
          {tagDetails.type}
        </span>
        {tagDetails.subType && <span className="infoSubType">{tagDetails.subType}</span>}
      </span>
    );
  };

  return (
    <div className={`comp-group-tag group-tag-${styleTag === undefined ? 'full' : styleTag}`}>
      <div className="comp-group-tag-share">
        {tagDetails.prefAvatar && (
          <span className={`avatar tooltip group-share ${`${tooltip}` === false ? 'withoutAvatar' : ''}`}>
            {`${tagDetails.prefAvatar}` ? (
              <span className={`_icon ${tagDetails.prefAvatar} avatar-icon`} />
            ) : (
              <span className="_icon icon-profile-neutral-round avatar-icon" />
            )}
            <span className="tooltip-box">{getInfo()}</span>
          </span>
        )}
        <p className="info-left-side">
          <span className="small">{getInfo()}</span>
        </p>

        {tagDetails.extraIcon && <span className={`_icon ${tagDetails.extraIcon} extraIconAbs`} />}
      </div>
    </div>
  );
};

GroupTag.propTypes = {
  /** Group's details. */
  group: PropTypes.shape({
    value: PropTypes.string,
    astID: PropTypes.string,
    astNume: PropTypes.string,
    clID: PropTypes.string,
    clNume: PropTypes.string,
    matID: PropTypes.string,
    matNume: PropTypes.string,
    roleID: PropTypes.string,
    roleNume: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    icon: PropTypes.string,
    extraIcon: PropTypes.string,
    orasNume: PropTypes.string,
    judID: PropTypes.string,
    judNume: PropTypes.string,
    tctrID: PropTypes.string,
    tctrNume: PropTypes.string,
    grpName: PropTypes.string,
    school: PropTypes.shape({
      liceuID: PropTypes.string,
      liceuClasificare: PropTypes.string,
      liceuNume: PropTypes.string,
      liceuAdresa: PropTypes.shape({
        adrJudet: PropTypes.shape({
          judNume: PropTypes.string,
        }),
        adrLocalitate: PropTypes.string,
      }),
    }),
  }),
  /** Doar pentru styleTag=`"avatar`" <br/>
	La hover apare tooltipul cu informatii grupului. */
  tooltip: PropTypes.bool,
  /** full - afiseaza avatarul + informatiile grupului <br/>
	avatar - afiseaza doar avatarul, iar la hover apare un tooltip cu informatiile grupului <br/>
	inline - afiseaza doar informatiile grupului */
  styleTag: PropTypes.oneOf(['full', 'avatar', 'inline']),
};

GroupTag.defaultProps = {
  group: {
    value: '',
    astID: '',
    astNume: '',
    clID: '',
    clNume: '',
    matID: '',
    matNume: '',
    roleID: '',
    roleNume: '',
    title: '',
    subTitle: '',
    icon: '',
    extraIcon: '',
    orasNume: '',
    judID: '',
    judNume: '',
    tctrID: '',
    tctrNume: '',
    fssgDetails: '',
    school: {
      liceuID: '',
      liceuClasificare: '',
      liceuNume: '',
      liceuAdresa: {
        adrJudet: {
          judNume: '',
        },
        adrLocalitate: '',
      },
    },
  },
  styleTag: 'full',
  tooltip: true,
};

export default GroupTag;
