/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */
import inteligent from './badges/inteligent.svg';
import activ from './badges/activ.svg';
import curios from './badges/curios.svg';
import sociabil from './badges/sociabil.svg';
import empatic from './badges/empatic.svg';
import responsabil from './badges/responsabil.svg';
import creativ from './badges/creativ.svg';
import comunicativ from './badges/comunicativ.svg';
import curajos from './badges/curajos.svg';
import ganditor from './badges/ganditor.svg';
import principial from './badges/principial.svg';
import maestru from './badges/maestru.svg';
import prezentare_material from './badges/prezentare_material.svg';
import utilizare_material from './badges/utilizare_material.svg';
import neatent from './badges/neatent.svg';
import egoist from './badges/egoist.svg';
import poznas from './badges/poznas.svg';
import blue from './badges/blue.svg';
import yellow from './badges/yellow.svg';
import green from './badges/green.svg';
import red from './badges/red.svg';
import black from './badges/black.svg';

import inteligent_png from './badges/inteligent.png';
import activ_png from './badges/activ.png';
import curios_png from './badges/curios.png';
import sociabil_png from './badges/sociabil.png';
import empatic_png from './badges/empatic.png';
import responsabil_png from './badges/responsabil.png';
import creativ_png from './badges/creativ.png';
import comunicativ_png from './badges/comunicativ.png';
import curajos_png from './badges/curajos.png';
import ganditor_png from './badges/ganditor.png';
import principial_png from './badges/principial.png';
import maestru_png from './badges/maestru.png';
import prezentare_material_png from './badges/prezentare_material.png';
import utilizare_material_png from './badges/utilizare_material.png';
import neatent_png from './badges/neatent.png';
import egoist_png from './badges/egoist.png';
import poznas_png from './badges/poznas.png';
import blue_png from './badges/blue.png';
import yellow_png from './badges/yellow.png';
import green_png from './badges/green.png';
import red_png from './badges/red.png';
import black_png from './badges/black.png';

export const BADGE_TYPE = {
  1: {
    svg: inteligent,
    png: inteligent_png,
    text: 'inteligent',
  },
  2: {
    svg: activ,
    png: activ_png,
    text: 'activ',
  },
  3: {
    svg: curios,
    png: curios_png,
    text: 'curios',
  },
  4: {
    svg: sociabil,
    png: sociabil_png,
    text: 'sociabil',
  },
  5: {
    svg: empatic,
    png: empatic_png,
    text: 'empatic',
  },
  6: {
    svg: responsabil,
    png: responsabil_png,
    text: 'responsabil',
  },
  7: {
    svg: creativ,
    png: creativ_png,
    text: 'creativ',
  },
  8: {
    svg: comunicativ,
    png: comunicativ_png,
    text: 'comunicativ',
  },
  9: {
    svg: curajos,
    png: curajos_png,
    text: 'curajos',
  },
  10: {
    svg: ganditor,
    png: ganditor_png,
    text: 'ganditor',
  },
  11: {
    svg: principial,
    png: principial_png,
    text: 'principial',
  },
  12: {
    // doar pentru Gradinita tessori
    svg: maestru,
    png: maestru_png,
    text: 'maestru',
  },
  13: {
    // doar pentru Gradinita tessori
    svg: prezentare_material,
    png: prezentare_material_png,
    text: 'prezentare_material',
  },
  14: {
    // doar pentru Gradinita tessori
    svg: utilizare_material,
    png: utilizare_material_png,
    text: 'utilizare_material',
  },

  20: {
    svg: neatent,
    png: neatent_png,
    text: 'neatent',
  },
  21: {
    svg: egoist,
    png: egoist_png,
    text: 'egoist',
  },
  22: {
    svg: poznas,
    png: poznas_png,
    text: 'poznas',
  },

  30: {
    svg: blue,
    png: blue_png,
    text: 'blue',
  },
  31: {
    svg: yellow,
    png: yellow_png,
    text: 'yellow',
  },
  32: {
    svg: green,
    png: green_png,
    text: 'green',
  },
  33: {
    svg: red,
    png: red_png,
    text: 'red',
  },
  34: {
    svg: black,
    png: black_png,
    text: 'black',
  },
};
