import { setTop, setBottom, setRight, setLeft, setCenter } from './setters';

import {
  checkSpaceForTop,
  checkSpaceForLeft,
  checkSpaceForRight,
  checkSpaceForBottom,
  checkLeftSpaceForCenter,
  checkRightSpaceForCenter,
} from './validators';

export const handleTop = (props) => {
  return checkSpaceForTop(props) ? setTop(props) : setBottom(props);
};

export const handleLeft = (props) => {
  return checkSpaceForLeft(props) ? setLeft(props) : setRight(props);
};

export const handleRight = (props) => {
  return checkSpaceForRight(props) ? setRight(props) : setLeft(props);
};

export const handleBottom = (props) => {
  return checkSpaceForBottom(props) ? setBottom(props) : setTop(props);
};

export const handleCenter = (props) => {
  let position = 'center';

  const options = {
    left: setLeft(props),
    center: setCenter(props),
    right: setRight(props),
  };

  if (!checkLeftSpaceForCenter(props)) {
    position = 'left';
  }

  if (!checkRightSpaceForCenter(props)) {
    position = 'right';
  }

  return options[position];
};
