import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import Button from '../../Forms/SimpleButton/Button';

import './HeaderTitle.less';

class HeaderTitle extends PureComponent {
  renderContent = () => {
    const { children } = this.props;

    if (children) {
      if (children.length > 0) {
        return children.map((item, i) => {
          return (
            <div className={i === 0 ? 'title' : ''} key={`${item?.props?.children}`}>
              {item?.props?.children}
            </div>
          );
        });
      }
      return <div>{children.props.children}</div>;
    }
    return null;
  };

  render() {
    const { description, title, actionGoBack, className, clickEvent } = this.props;

    return (
      <div
        className={`comp-header-title ${
          (title && description) || React.Children.count(this.renderContent()) === 2 ? 'header-title-top' : ''
        } ${className || ''} `}
      >
        {!!actionGoBack && <Button color="blue" goBack onClick={clickEvent || null} />}

        <div className="content-text">
          {!title && !description ? (
            this.renderContent()
          ) : (
            <>
              {title && <p>{title}</p>}

              {description && <p>{description}</p>}
            </>
          )}
        </div>
      </div>
    );
  }
}

HeaderTitle.propTypes = {
  /** titlul propriu-zis */
  title: PropTypes.string,
  /** continutul descrierii */
  description: PropTypes.string,
  /** afisarea butonului de back */
  actionGoBack: PropTypes.bool,
  /** clase optionale */
  className: PropTypes.string,
  /** event pt back */
  clickEvent: PropTypes.func,
  /** continut pt componneta */
  children: PropTypes.node,
};

HeaderTitle.defaultProps = {
  actionGoBack: false,
  title: '',
  description: '',
  className: '',
  clickEvent: () => {},
  children: undefined,
};

export default HeaderTitle;
