import React from 'react';
import PropTypes from 'prop-types';

import './Icon.less';

const Icon = (props) => {
  const { icon, className, onClick } = props;
  return (
    <span
      className={`comp-icon _icon ${icon} ${className || ''}`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
      aria-label="icon"
    />
  );
};

export default Icon;

Icon.propTypes = {
  /** Icon name */
  icon: PropTypes.string,
  /** General class name */
  className: PropTypes.string,
  onClick: PropTypes.func,
};

Icon.defaultProps = {
  icon: '',
  className: '',
  onClick: () => null,
};
