export default [
  '#d1b1cb',
  '#8daa9d',
  '#fe4a49',
  '#54426b',
  '#f1d302',
  '#ffba49',
  '#c8e0f4',
  '#32908f',
  '#1f2041',
  '#e75a7c',
];
