import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import './ToastLoader.less';

class ToastLoader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showToast: false,
    };
  }

  componentDidMount() {
    const { delay } = this.props;
    this.timer = setTimeout(() => {
      this.setState({
        showToast: true,
      });
    }, delay);

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate() {
    if (document.getElementsByClassName('comp-toast-loader')[0]) this.handleScroll();
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.setState({
      showToast: false,
    });
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 20) {
      document.getElementsByClassName('comp-toast-loader')[0]?.classList.add('fixed-toaster-modal');
    } else {
      document.getElementsByClassName('comp-toast-loader')[0]?.classList.remove('fixed-toaster-modal');
    }
  };

  render() {
    const { t } = this.props;
    const { showToast } = this.state;

    return showToast ? <div className="comp-toast-loader">{t('loading')}</div> : null;
  }
}

ToastLoader.propTypes = {
  /** timpul de asteptare pana apare loaderul */
  delay: PropTypes.number,
  t: PropTypes.func.isRequired,
};

ToastLoader.defaultProps = {
  delay: 1000,
};

export default withTranslation('common')(ToastLoader);
