/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CheckBox from '../CheckBox';

import './CheckBoxList.less';

export default function CheckBoxList({
  options,
  isCheckedAll,
  onCheck,
  checkedAllLabel,
  onClickItem,
  person,
  toggleItems,
  singleValue,
  restrictii,
  uaTip,
  adsTeamOption,
  selectMoreUsers,
  restrictiiGrupAllItem,
  firstStep,
}) {
  const { t } = useTranslation('ComponentSelectGroup');

  const [toggleElements, setToggleElements] = useState(false);

  const onToggleElements = () => {
    setToggleElements(!toggleElements);
  };

  const renderChackBox = (key, opt) => {
    return (
      <CheckBox
        key={key}
        name={opt.name}
        value={opt.value}
        label={opt.label}
        tick={opt.checked}
        onCheck={(e) => onCheck(opt.value, e.target.checked)}
        onClickItem={() => onClickItem(opt)}
        person={person && opt}
        restrictii={restrictii}
        uaTip={uaTip}
        adsTeamOption={adsTeamOption}
        selectMoreUsers={selectMoreUsers}
        restrictiiGrupAllItem={restrictiiGrupAllItem}
        firstStep={firstStep}
      />
    );
  };

  const checkBoxOptions = (
    <div className={`checkbox-list ${singleValue ? 'checkbox-list__two-column' : ''}`}>
      {options.map((option, index) => {
        if (toggleItems) {
          if (index < 18 && !toggleElements) {
            return renderChackBox(index, option);
          }
          if (toggleElements) {
            return renderChackBox(index, option);
          }
        }
        if (!toggleItems) {
          return renderChackBox(index, option);
        }

        return null;
      })}

      {toggleItems && !toggleElements && options.length > 18 && (
        <div className="select-group-style-list toggleElements" onClick={onToggleElements} role="presentation">
          {t('select_group.toate_materiile')} (+
          {options && options.length - 18})
        </div>
      )}
    </div>
  );

  return (
    <div className="checkbox-warpper">
      {!singleValue && (
        <CheckBox
          name={checkedAllLabel.value}
          value={checkedAllLabel.value}
          label={checkedAllLabel.label}
          tick={isCheckedAll}
          onCheck={(e) => onCheck('all', e.target.checked)}
          allItem
          restrictii={restrictii}
          uaTip={uaTip}
          adsTeamOption={adsTeamOption}
          restrictiiGrupAllItem={restrictiiGrupAllItem}
        />
      )}
      {checkBoxOptions}
    </div>
  );
}

CheckBoxList.propTypes = {
  options: PropTypes.arrayOf(PropTypes.instanceOf(Object)),
  isCheckedAll: PropTypes.bool,
  onCheck: PropTypes.func,
  checkedAllLabel: PropTypes.instanceOf(Object),
  onClickItem: PropTypes.func,
  toggleItems: PropTypes.bool,
  person: PropTypes.bool,
  singleValue: PropTypes.bool,
  restrictii: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  adsTeamOption: PropTypes.bool,
  uaTip: PropTypes.string,
  selectMoreUsers: PropTypes.bool,
  restrictiiGrupAllItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  firstStep: PropTypes.bool,
};

CheckBoxList.defaultProps = {
  options: [],
  isCheckedAll: false,
  onCheck: () => {},
  checkedAllLabel: {},
  onClickItem: () => {},
  toggleItems: false,
  person: false,
  singleValue: false,
  restrictii: '',
  adsTeamOption: false,
  uaTip: '',
  selectMoreUsers: true,
  restrictiiGrupAllItem: false,
  firstStep: false,
};
