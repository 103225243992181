import React from 'react';

import BadgeComponent from '../../Badge/Badge';

const gradeParser = (grade, gradeType, forceDecimals) => {
  if (grade) {
    let decimal = '';
    const decimalLen = 2;
    const integerNumber = parseInt(grade.notaValoare, 10) ? parseInt(grade.notaValoare, 10) : grade;
    const decimalToBe = parseFloat(grade.notaValoare ? grade.notaValoare : grade) - integerNumber;
    const decimalAddZero = Number(decimalToBe.toFixed(2));
    const array = decimalAddZero.toString().split('.');

    if (decimalToBe > 0 || forceDecimals) {
      if (!array[1]) {
        decimal = '00';
      } else decimal = array[1].padEnd(decimalLen, '0');
    }
    if (`${gradeType}` === 'int') {
      return integerNumber;
    }
    if (`${gradeType}` === 'decimal') {
      return decimal;
    }
    if (`${gradeType}` === 'ratings') {
      switch (grade.notaValoare ? parseInt(grade.notaValoare, 10) : grade) {
        case 10:
        case 9:
          return 'FB';
        case 8:
        case 7:
          return 'B';
        case 6:
        case 5:
          return 'S';
        case 4:
        case 3:
        case 2:
        case 1:
          return 'I';
        default:
          return '';
      }
      // logica pentru calificative
    }
    if (`${gradeType}` === 'cambridge') {
      switch (grade.notaValoare ? parseInt(grade.notaValoare, 10) : grade) {
        case 10:
          return 'A*';
        case 9:
          return 'A';
        case 8:
          return 'B';
        case 6:
          return 'C';
        case 5:
          return 'D';
        case 4:
          return 'E';
        case 3:
          return 'F';
        case 2:
          return 'G';

        default:
          return '';
      }
      // logica pentru calificative
    }
    if (`${gradeType}` === 'img') {
      return <BadgeComponent integerNumber={integerNumber} />;
      // logica pt buline/img src
    }
  }
  return null;
};

export default gradeParser;
