import React from 'react';
import PropTypes from 'prop-types';

import './Switch.less';

const Switch = ({ checked, handleChange, name, color, size, text }) => {
  return (
    <>
      <label className={`switch ${size}`} htmlFor={name}>
        <input type="checkbox" id={name} checked={checked} onChange={(e) => handleChange(e.target.checked)} />
        <span className={`slider_switch round ${color}`} />
      </label>

      {text && (
        <p onClick={(e) => handleChange(e.target.checked)} type="button" role="presentation" className="switch-text">
          {text}
        </p>
      )}
    </>
  );
};

export default Switch;

Switch.propTypes = {
  handleChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  color: PropTypes.oneOf(['green', 'red']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  text: PropTypes.string,
};

Switch.defaultProps = {
  color: 'green',
  size: 'medium',
  text: '',
};
