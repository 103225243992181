const getGradeColor = (tipNota) => {
  switch (tipNota) {
    case '1': // oral
    case '7': // test
    case '9': // proiect
      return 'black';

    case '2': // teza
    case '3': // corigenta
    case '5': // examen specialitate
      return 'red';

    case '4': // testare initiala
    case '6': // testare finala
    case '8': // simulare
      return 'blue';
    default:
      return 'black';
  }
};

export default getGradeColor;
