import React from 'react';

import PropTypes from 'prop-types';

import Select from 'react-select';

import './Select.less';

import customStyles from './constants';

const SelectComp = (props) => {
  const {
    value,
    onChangeOptions,
    options,
    className,
    isSearchable,
    name,
    children,
    onInputValueChange,
    noOptionsMessage,
    noClientFilter,
  } = props;

  const onChange = (filter) => {
    onChangeOptions(filter, filter.id, filter.filterName);
  };

  const optionsArr =
    children && children.length
      ? children
          .filter((item) => !!item.props)
          .map((item, index) => ({
            value: item.props.children,
            label: item.props.children,
            id: index,
            filterName: name,
            item: item.props,
          }))
      : options;

  return (
    <Select
      className={`comp-select ${className || ''}`}
      isSearchable={isSearchable}
      name={`${name}`}
      onChange={onChange}
      onInputChange={(data) => {
        if (onInputValueChange && typeof onInputValueChange === 'function') {
          onInputValueChange(data);
        }
      }}
      options={optionsArr}
      styles={customStyles}
      value={!value?.label || !value?.value ? null : value}
      noOptionsMessage={noOptionsMessage}
      filterOption={noClientFilter ? () => true : undefined}
    />
  );
};

const optionItem = PropTypes.shape({
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.shape({})]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
});

SelectComp.propTypes = {
  /** array de obiecte cu valorile din lista */
  options: PropTypes.arrayOf(optionItem),
  /** nume select */
  name: PropTypes.string,
  /** actiunea de search in lista */
  isSearchable: PropTypes.bool,
  /** valoare actuala  selectata */
  value: optionItem,
  /** actiunea de select a opt */
  onChangeOptions: PropTypes.func,
  /** actiunea de a modifica mesajul de "no options" */
  noOptionsMessage: PropTypes.func,
  /** clasa particulara */
  onInputValueChange: PropTypes.func,
  noClientFilter: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
};

SelectComp.defaultProps = {
  options: [],
  isSearchable: false,
  children: [],
  className: '',
  value: undefined,
  name: '',
  onChangeOptions: () => {},
  noOptionsMessage: () => {},
  onInputValueChange: () => {},
  noClientFilter: false,
};

export default SelectComp;
