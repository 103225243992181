import React from 'react';
import PropTypes from 'prop-types';

import Link from '../../Content/Link';

import './Blink.less';

const Blink = (props) => {
  const { className, children, color, size, linkPath, disabled } = props;

  return (
    <Link
      to={`${linkPath || '#'}`}
      className={`btn-component blink-component
                        ${color || 'orange'}
                        ${size || 'btn-lg'}
                        ${disabled ? 'disabled' : ''}
                        ${className || ''}`}
    >
      <span className="btn_hover" />
      <span className="btn_text">{children}</span>
    </Link>
  );
};

Blink.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  linkPath: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

Blink.defaultProps = {
  className: '',
  color: '',
  size: '',
  linkPath: '',
  disabled: false,
  children: null,
};

export default Blink;
