import React, { useEffect, useRef } from 'react';

import colors from './colors';
import debounce from '../utils/hooks';

import './PieChart.less';

const { google } = window;

google.charts.load('current', { packages: ['corechart'] });

export default ({ data }) => {
  const pieChartRef = useRef();

  const pieOptions = {
    sliceVisibilityThreshold: 0,
    colors,
    animation: {
      duration: 2000,
      easing: 'out',
      startup: true,
    },
    legend: {
      position: 'right',
      alignment: 'center',
      textStyle: { fontSize: 12 },
    },
    height: '300',
    pieSliceText: 'none',
    chartArea: { width: '80%', height: '80%' },
  };

  const drawChart = () => {
    if (!pieChartRef.current) {
      return;
    }
    const pieChartData = new google.visualization.DataTable();

    const { chartColumns } = data;
    const [firstColumn, secondColumn] = chartColumns;

    pieChartData.addColumn(firstColumn.type, firstColumn.value);
    pieChartData.addColumn(secondColumn.type, secondColumn.value);
    pieChartData.addRows(data.chartData);

    const chart = new google.visualization.PieChart(pieChartRef.current);

    chart.draw(pieChartData, pieOptions);
  };

  useEffect(() => {
    google.charts.setOnLoadCallback(drawChart);

    const debouncedHandleResize = debounce(drawChart, 100);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  const render = () => (
    <div className="pie-chart">
      <div className="pie-chart__title">{data.chartTitle}</div>
      <div className="pie-chart__render-container" ref={pieChartRef} />
    </div>
  );

  return render();
};
