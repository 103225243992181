import React, { Component } from 'react';

import PropTypes from 'prop-types';

class Size extends Component {
  formatBytes = (bytes) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Bytes';
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(2)} ${sizes[i]}`;
  };

  render() {
    const { fsSize } = this.props;

    return <span className="size">{this.formatBytes(fsSize)}</span>;
  }
}

Size.propTypes = {
  /** Spatiu ocupat in bytes */
  fsSize: PropTypes.number,
};

Size.defaultProps = {
  fsSize: null,
};

export default Size;
