export default () => {
  const minWidthHeight = Math.min(window.screen.width, window.screen.height);
  const navigatorMobi = navigator.userAgent.indexOf('Mobi') > -1;

  if ((minWidthHeight < 768 && navigatorMobi) || window.innerWidth < 768) {
    return 'smallScreen';
  }

  if ((minWidthHeight <= 1280 && navigatorMobi) || window.innerWidth <= 1280) {
    return 'mediumScreen';
  }

  return 'largeScreen';
};
