import React, { useEffect, useRef } from 'react';

import debounce from '../utils/hooks';

import './ColumnChart.less';

const { google } = window;

google.charts.load('current', { packages: ['corechart'] });

export default ({ data }) => {
  const columnChartRef = useRef();

  const { progress, chartTitle, isPositive } = data;

  const pieOptions = {
    bar: { groupWidth: '90%' },
    chartArea: { width: '70%', height: '80%' },
    legend: 'none',
    animation: {
      duration: 0,
      easing: 'out',
      startup: false,
    },
    hAxis: {
      textStyle: {
        color: '#8a8a8a',
        fontSize: 12,
      },
    },
    vAxis: {
      viewWindowMode: 'explicit',
      viewWindow: {
        max: 10,
        min: 1,
      },
    },
  };

  const drawChart = () => {
    if (!columnChartRef.current) {
      return;
    }

    const columnChartData = new google.visualization.DataTable();

    const { chartColumns } = data;
    const [firstColumn, secondColumn] = chartColumns;

    columnChartData.addColumn(firstColumn.type, firstColumn.value);
    columnChartData.addColumn(secondColumn.type, secondColumn.value);
    columnChartData.addColumn({ role: 'style' });
    columnChartData.addRows(data.chartData);

    const chart = new google.visualization.ColumnChart(columnChartRef.current);

    chart.draw(columnChartData, pieOptions);
  };

  useEffect(() => {
    google.charts.setOnLoadCallback(drawChart);

    const debouncedHandleResize = debounce(drawChart, 100);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  const render = () => (
    <div className="column-chart">
      <div className={`column-chart__value ${isPositive && 'column-chart__value--positive'}`}>
        {isPositive ? `+${progress}` : progress}
      </div>
      <div className="column-chart__label">{chartTitle}</div>
      <div className="column-chart__render-container" ref={columnChartRef} />
    </div>
  );

  return render();
};
