import React, { Component } from 'react';

import PropTypes from 'prop-types';

import Croppie from 'croppie/croppie';

import { withTranslation } from 'react-i18next';

import Button from '../../../../Forms/SimpleButton/Button';

import HeaderTitle from '../../../HeaderTitle/HeaderTitle';

import Tooltip from '../../../Tooltip/Tooltip';

import './Cropy.less';

class CropComponent extends Component {
  cropper = false;

  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.state = {
      initialise: false,
      fileName: '',
      fileselected: true,
    };
  }

  componentDidMount() {
    const { cropWidth, cropHeight, shape, enableResize, file } = this.props;

    const options = {
      viewport: {
        width: cropWidth,
        height: cropHeight,
        type: shape,
      },
      enableResize,
      enableOrientation: true,
    };
    this.cropper = new Croppie(document.getElementById('cropy'), options);
    this.cropper.bind({
      url: file,
    });
  }

  componentWillUnmount() {
    const { initialise } = this.state;
    if (initialise) {
      this.cropper.destroy();
    }
  }

  verifyFile = (files) => {
    const { acceptedFileTypesArray, imageMaxSize } = this.props;
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        // eslint-disable-next-line no-alert
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        // eslint-disable-next-line no-alert
        alert('This file is not allowed. Only images are allowed.');
        return false;
      }
      return true;
    }
    return false;
  };

  rotate = (orientation) => {
    this.cropper.rotate(orientation);
  };

  save = () => {
    const { saveCallBack, exitCallBack } = this.props;
    const { fileName } = this.state;
    this.cropper.result('base64').then((base64img) => {
      saveCallBack(base64img, fileName);
      exitCallBack();
    });
  };

  exit = () => {
    const { exitCallBack } = this.props;
    exitCallBack();
  };

  render() {
    const { fileselected } = this.state;
    const { change, t } = this.props;
    return (
      <div className="Cropy-component">
        <HeaderTitle title={t('Adauga_Img2')} clickEvent={this.exit} description={t('Aplica_Mod2')} actionGoBack />
        <div className="Cropy-wrapper">
          <div id="cropy" />
        </div>
        <div className="cropy-button-area">
          <div className="first-line">
            <div className="rotate">
              {fileselected && (
                <Tooltip arrowPosition="left" WrapperClass="cropy_tooltip_left" poupClass="poupClass ">
                  <Button color="ghost-blue" onClick={() => this.rotate(90)} ladda={false}>
                    <span className="_icon icon-turn-left _icon-size left_poz" />
                  </Button>
                  <div className="tooltipContent">{t('Roleft2')}</div>
                </Tooltip>
              )}
              {fileselected && (
                <Tooltip arrowPosition="left" WrapperClass="cropy_tooltip_right" poupClass="poupClass">
                  <Button color="ghost-blue" onClick={() => this.rotate(-90)} ladda={false}>
                    <span className="_icon icon-turn-right _icon-size right_poz" />
                  </Button>
                  <div className="tooltipContent">{t('Roright2')}</div>
                </Tooltip>
              )}
            </div>
            <div className="uploade">
              {fileselected && (
                <Tooltip arrowPosition="right" WrapperClass="adjust-right" poupClass="poupClass">
                  <Button color="ghost-blue" onClick={() => change()} ladda={false}>
                    <span className="_icon icon-upload _icon-size" />
                  </Button>
                  <div className="tooltipContent">{t('incarca3')}</div>
                </Tooltip>
              )}
            </div>
          </div>
          <div className="second-line">
            {fileselected && (
              <Button color="orange" onClick={() => this.save()}>
                {t('Save2')}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

CropComponent.propTypes = {
  /* the width of thefinal crop */
  cropWidth: PropTypes.number,
  /* the height of the final crop */
  cropHeight: PropTypes.number,
  /* shape of the final crop  */
  shape: PropTypes.oneOf(['square', 'circle']),
  /* save callback the parameter is file */
  saveCallBack: PropTypes.func.isRequired,
  /* array of file types  */
  acceptedFileTypesArray: PropTypes.arrayOf(PropTypes.string),
  /* img dimension in bites */
  imageMaxSize: PropTypes.number,
  /* exit from the crop compoent */
  exitCallBack: PropTypes.func.isRequired,
  /* used to disable the resize proprety of the cropt img */
  enableResize: PropTypes.bool,
  /* functie care semnaleaza ca sa selectat o imagine */
  file: PropTypes.string,
  /* change */
  change: PropTypes.func,
  t: PropTypes.func.isRequired,
};

CropComponent.defaultProps = {
  cropWidth: 200,
  cropHeight: 200,
  shape: 'circle',
  acceptedFileTypesArray: ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  imageMaxSize: 1000000000,
  enableResize: true,
  file: '',
  change: () => {},
};
export default withTranslation('ComponentImgCropper')(CropComponent);
