import React from 'react';
import PropTypes from 'prop-types';

import './Loader.less';

const Loader = ({ color, size }) => <div className={`comp-loader ${color} ${size}`} />;

Loader.propTypes = {
  /** marimea loader-ului */
  size: PropTypes.oneOf(['xm', 'sm', 'md', 'lg']),
  /** culoarea loader-ului */
  color: PropTypes.oneOf(['light', 'dark']),
};

Loader.defaultProps = {
  size: 'md',
  color: 'light',
};

export default Loader;
