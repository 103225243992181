import Api2 from '../../../Libs/api';

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getAniStudiu = () => {
  return Api2.get('/ani/studiu');
};

export const removeDuplicates = (arr, comp) => {
  const unique = arr
    .map((e) => {
      return e[comp];
    })

    // store the keys of the unique objects
    .map((e, i, final) => {
      return final.indexOf(e) === i && i;
    })

    // eliminate the dead keys & store unique objects
    .filter((e) => {
      return arr[e];
    })
    .map((e) => {
      return arr[e];
    });

  return unique;
};
