import React from 'react';
import PropTypes from 'prop-types';

import './ProgressBar.less';

const ProgressBar = ({ customType, percent }) => {
  return (
    <div className={`myProgress ${customType}`}>
      <div className="myBar" style={{ width: `${percent}%` }} />
    </div>
  );
};

ProgressBar.propTypes = {
  customType: PropTypes.string,
  percent: PropTypes.number.isRequired,
};

ProgressBar.defaultProps = {
  customType: '',
};

export default ProgressBar;
