/* eslint-disable no-useless-escape */
import React from 'react';

import PropTypes from 'prop-types';

import { Editor } from '@tinymce/tinymce-react';

import { useTranslation } from 'react-i18next';

import './TextEditor.less';

function TextEditor(props) {
  const { i18n } = useTranslation();

  const {
    editorId,
    toolbarOptions,
    autoFocus,
    value,
    onEditorChange,
    placeholder,
    disabled,
    contentStyle,
    plugins,
    height,
    maxHeight,
    onKeyDown,
    maxLength,
    onFocus,
  } = props;

  const defaultContentStyle =
    'html, .mce-content-body { height: 89%; } .mce-content-body p { margin-block-start: 0em; margin-block-end: 0em; padding: 0; }';

  return (
    <Editor
      id={`message${editorId ? `-${editorId}` : ''}`}
      init={{
        plugins: plugins || ['lists link image paste'],
        mobile: {
          theme: 'silver',
        },
        paste_as_text: true,
        contextmenu: false,
        menubar: false,
        toolbar: toolbarOptions || 'bold italic underline forecolor bullist link image',
        auto_focus: autoFocus,
        content_css: '/css/ads_style.css',
        language: i18n.language === 'hu' ? `${i18n.language}'_HU'` : `${i18n.language}`,
        language_url: `/react/dist/tinymce/${i18n.language}.js`,
        content_style: `${contentStyle || defaultContentStyle}`,
        default_link_target: '_blank',
        placeholder,
        height,
        max_height: maxHeight,
        autoresize_bottom_margin: 0,
        link_assume_external_targets: true,
      }}
      onKeyDown={onKeyDown}
      disabled={disabled}
      value={value}
      onEditorChange={onEditorChange}
      onFocus={onFocus}
    />
  );
}

export default TextEditor;

TextEditor.propTypes = {
  /** The id of the editor */
  /** The paramas that you pass to the editor in order to be able to do more actions.Default is 'bold italic underline forecolor bullist numlist link image' .
   * You can remove some of them if you don't need them */
  toolbarOptions: PropTypes.string,
  /** You can pass this prop if you want autofocus on component */
  autoFocus: PropTypes.bool,
  /** The text you want to send */
  value: PropTypes.string,
  /** The callback function when text is changed */
  onEditorChange: PropTypes.func.isRequired,
  editorId: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  contentStyle: PropTypes.string,
  onKeyDown: PropTypes.func,
  /** Plugins other than default ones */
  plugins: PropTypes.arrayOf(PropTypes.string),
  /** Input's height */
  height: PropTypes.number,
  /** Input's max height when using autoresize plugin */
  maxHeight: PropTypes.number,
  maxLength: PropTypes.number,
  onFocus: PropTypes.func,
};

TextEditor.defaultProps = {
  toolbarOptions: 'bold italic underline forecolor bullist link image',
  value: '',
  autoFocus: false,
  editorId: '',
  placeholder: '',
  disabled: false,
  contentStyle: '',
  onKeyDown: () => {},
  plugins: undefined,
  height: undefined,
  maxHeight: undefined,
  maxLength: undefined,
  onFocus: undefined,
};

export function htmlToBBCode(html) {
  let htmlLocal = html;
  // break row
  htmlLocal = htmlLocal.replace(/<br>/gi, '[br]');
  htmlLocal = htmlLocal.replace(/<br\/>/gi, '[br]');
  htmlLocal = htmlLocal.replace(/<br \/>/gi, '[br]');
  htmlLocal = htmlLocal.replace(/<\/p>/gi, '[br]');

  htmlLocal = htmlLocal.replace(/<b>/gi, '[b]');
  htmlLocal = htmlLocal.replace(/<i>/gi, '[i]');
  htmlLocal = htmlLocal.replace(/<\/b>/gi, '[/b]');
  htmlLocal = htmlLocal.replace(/<\/i>/gi, '[/i]');
  htmlLocal = htmlLocal.replace(/<(em|i)>/gi, '[i]');
  htmlLocal = htmlLocal.replace(/<\/(em|i)>/gi, '[/i]');
  htmlLocal = htmlLocal.replace(/<strong>/gi, '[b]');
  htmlLocal = htmlLocal.replace(/<\/strong>/gi, '[/b]');
  htmlLocal = htmlLocal.replace(/<blockquote>/gi, '[quote]');
  htmlLocal = htmlLocal.replace(/<\/blockquote>/gi, '[/quote]');
  htmlLocal = htmlLocal.replace(/<a(.*?)href="(.*?)"(.*?)>(.*?)<\/a>/gi, '[url=$2" target="_blank]$4[/url]');

  // size
  htmlLocal = htmlLocal.replace(/<span style=\"font-size:(.*?);\">(.*?)<\/span>/gi, '[size=$1]$2[/size]');

  // color
  htmlLocal = htmlLocal.replace(
    /<span style=\"color: ?(.*?);\"text-decoration: ?underline;\">(.*?)<\/span>/gi,
    '[u][color=$1]$2[/color][/u]'
  );
  htmlLocal = htmlLocal.replace(/<span style=\"color: ?(.*?);\">(.*?)<\/span>/gi, '[color=$1]$2[/color]');
  htmlLocal = htmlLocal.replace(/<font.*?color=\"(.*?)\".*?>(.*?)<\/font>/gi, '[color=$1]$2[/color]');

  // underline
  htmlLocal = htmlLocal.replace(/<span style=\"text-decoration: ?underline;\">(.*?)<\/span>/gi, '[u]$1[/u]');
  htmlLocal = htmlLocal.replace(/<u>/gi, '[u]');
  htmlLocal = htmlLocal.replace(/<\/u>/gi, '[/u]');

  // list
  htmlLocal = htmlLocal.replace(/<ul>/gi, '[list]');
  htmlLocal = htmlLocal.replace(/<\/ul>/gi, '[/list]');
  htmlLocal = htmlLocal.replace(/<li>/gi, '[*]');
  htmlLocal = htmlLocal.replace(/<\/li>/gi, '');

  htmlLocal = htmlLocal.replace(/<font color="(.*?)">(.*?)<\/font>/gi, '[color=$1]$2[/color]');
  htmlLocal = htmlLocal.replace(/<img src="(.*?)" width="(.*?)" height="(.*?)"(.*?)>/gi, '[img$2x$3]$1[/img]');

  return htmlLocal;
}

export function BBCodeToHTML(html) {
  let htmlLocal = html;
  htmlLocal = htmlLocal.replace(/\[b\]/gi, '<strong>');
  htmlLocal = htmlLocal.replace(/\[br\]/gi, '<br>');
  htmlLocal = htmlLocal.replace(/\[list\]/gi, '<ul>');
  htmlLocal = htmlLocal.replace(/\[\/list\]/gi, '</ul>');
  htmlLocal = htmlLocal.replace(/\[\*\]/gi, '<li>');
  htmlLocal = htmlLocal.replace(/\[i\]/gi, '<i>');
  htmlLocal = htmlLocal.replace(/\[u\]/gi, '<u>');
  htmlLocal = htmlLocal.replace(/\[\/b\]/gi, '</strong>');
  htmlLocal = htmlLocal.replace(/\[\/i\]/gi, '</i>');
  htmlLocal = htmlLocal.replace(/\[\/u\]/gi, '</u>');
  htmlLocal = htmlLocal.replace(/\[quote\]/gi, '<blockquote>');
  htmlLocal = htmlLocal.replace(/\[\/quote\]/gi, '</blockquote>');
  htmlLocal = htmlLocal.replace(/&nbsp;/gi, ' ');
  htmlLocal = htmlLocal.replace(/\[url=(.*?)\](.*?)\[\/url\]/gi, '<a href="$1">$2</a>');
  htmlLocal = htmlLocal.replace(/\[color=(.*?)\](.*?)\[\/color\]/gi, '<span style="color:$1">$2</span>');
  htmlLocal = htmlLocal.replace(/\[img(.*?)x(.*?)\](.*?)\[\/img\]/gi, '<img src="$3" width="$1" height="$2">');
  return htmlLocal;
}
