/* eslint-disable no-alert */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CropWrapper from './Components/CropWrapper/CropWrapper';

import './ImgCropper.less';

class ImgCropper extends Component {
  cropper = false;

  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.fileInputRef2 = React.createRef();
    this.state = {
      fileselected: false,
      file: null,
      fileName: '',
    };
  }

  componentDidMount() {
    const { uploade } = this.props;
    if (uploade % 2 === 0) {
      this.fileInputRef.current.click();
    } else {
      this.fileInputRef2.current.click();
    }
  }

  componentDidUpdate(prevProps) {
    const { uploade } = this.props;
    if (prevProps.uploade !== uploade) {
      if (uploade % 2 === 0) {
        this.fileInputRef.current.click();
      } else {
        this.fileInputRef2.current.click();
      }
    }
  }

  componentWillUnmount() {
    const { initialise } = this.state;
    if (initialise) {
      this.cropper.destroy();
    }
  }

  verifyFile = (files) => {
    const { acceptedFileTypesArray, imageMaxSize } = this.props;
    if (files && files.length > 0) {
      const currentFile = files[0];
      const currentFileType = currentFile.type;
      const currentFileSize = currentFile.size;
      if (currentFileSize > imageMaxSize) {
        alert(`This file is not allowed. ${currentFileSize} bytes is too large`);
        return false;
      }
      if (!acceptedFileTypesArray.includes(currentFileType)) {
        alert('This file is not allowed. Only images are allowed.');
        return false;
      }
      return true;
    }
    return false;
  };

  handleFileSelect = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const isVerified = this.verifyFile(files);
      if (isVerified) {
        const currentFile = files[0];
        const fileName = currentFile.name;
        const myFileItemReader = new FileReader();
        myFileItemReader.addEventListener(
          'load',
          () => {
            const myResult = myFileItemReader.result;
            this.setState({
              fileselected: true,
              file: myResult,
              fileName,
            });
          },
          false
        );
        myFileItemReader.readAsDataURL(currentFile);
      }
    }
  };

  save = (base64img) => {
    const { saveCallBack } = this.props;
    const { fileName } = this.state;
    saveCallBack(base64img, fileName);
    this.setState({
      file: null,
    });
  };

  change = () => {
    const { uploade } = this.props;
    if (uploade % 2 === 0) {
      this.fileInputRef.current.click();
    } else {
      this.fileInputRef2.current.click();
    }
  };

  acceptedFileTypes = () => {
    let value = '';
    const { acceptedFileTypesArray } = this.props;
    acceptedFileTypesArray.forEach((item) => {
      if (value === '') {
        value = item;
      } else {
        value = `${value}, ${item}`;
      }
    });
    return value;
  };

  render() {
    const { fileselected, file } = this.state;
    const { uploade } = this.props;
    const acceptedFileTypes = this.acceptedFileTypes();
    return (
      <div className="ImgCropper-component">
        {uploade % 2 === 0 && (
          <input
            ref={this.fileInputRef}
            className="hideInput"
            type="file"
            accept={acceptedFileTypes}
            multiple={false}
            onChange={this.handleFileSelect}
          />
        )}
        {uploade % 2 === 1 && (
          <input
            ref={this.fileInputRef2}
            className="hideInput"
            type="file"
            accept={acceptedFileTypes}
            multiple={false}
            onChange={this.handleFileSelect}
          />
        )}
        {/* eslint-disable-next-line react/jsx-props-no-spreading */}
        {fileselected && <CropWrapper {...this.props} file={file} change={this.change} saveCallBack={this.save} />}
      </div>
    );
  }
}

ImgCropper.propTypes = {
  /* the width of thefinal crop */
  cropWidth: PropTypes.number,
  /* the height of the final crop */
  cropHeight: PropTypes.number,
  /* shape of the final crop  */
  shape: PropTypes.oneOf(['square', 'circle']),
  /* save callback the parameter is file */
  saveCallBack: PropTypes.func.isRequired,
  /* array of file types  */
  acceptedFileTypesArray: PropTypes.arrayOf(PropTypes.string),
  /* img dimension in bites */
  imageMaxSize: PropTypes.number,
  /* exit from the crop compoent */
  enableResize: PropTypes.bool,
  /* number of times the button was presed */
  uploade: PropTypes.number,
};

ImgCropper.defaultProps = {
  cropWidth: 200,
  cropHeight: 200,
  shape: 'circle',
  acceptedFileTypesArray: ['image/x-png', 'image/png', 'image/jpg', 'image/jpeg', 'image/gif'],
  imageMaxSize: 1000000000,
  enableResize: false,
  uploade: undefined,
};
export default ImgCropper;
