import { handleLeft, handleRight, handleCenter, handleTop, handleBottom } from './handlers';

const handle = (props) => {
  const { hAlign, vAlign } = props;

  const types = {
    left: handleLeft,
    right: handleRight,
    center: handleCenter,
    top: handleTop,
    bottom: handleBottom,
  };

  return { vertical: types[vAlign](props), horizontal: types[hAlign](props) };
};

const getPositions = ({ triggerRef, contentRef, hAlign, vAlign }) => {
  const { current: currentTrigger } = triggerRef;
  const { current: currentContent } = contentRef;
  const { scrollY: windowScrollY, scrollX: windowScrollX } = window;
  const { clientWidth: windowWidth, clientHeight: windowHeight } = document.body;

  const { offsetWidth: contentWidth, offsetHeight: contentHeight } = currentContent || {};
  const { x: triggerX, y: triggerY } = currentTrigger.getBoundingClientRect();

  const { offsetWidth: triggerWidth, offsetHeight: triggerHeight } = currentTrigger;

  const props = {
    hAlign,
    vAlign,
    triggerX,
    triggerY,
    triggerWidth,
    triggerHeight,
    contentWidth,
    contentHeight,
    windowWidth,
    windowHeight,
    windowScrollY,
    windowScrollX,
  };

  return handle(props);
};

export default getPositions;
