import React, { Component } from 'react';

import { connect } from 'react-redux';

import Select from 'react-select';

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import Api2 from '../../../Libs/api';

import { sessionSwitchLiceu as sessionSwitchLiceuAction } from '../../../Redux/SessionActions';

import GroupTag from '../GroupTag/GroupTag';

import './SelectSchools.less';

const queryString = require('query-string');

class SelectSchools extends Component {
  abortController = new AbortController();

  constructor(props) {
    super(props);

    const { school, value } = this.props;
    const queryParamsUrl = queryString.parse(window.location.search);

    if (queryParamsUrl.liceuID) {
      this.searchInput(false, queryParamsUrl?.liceuID);
    }

    this.state = {
      options: [], // optiunile afisate in select
      value:
        value && value.length
          ? value
          : {
              label: `${school.liceuClasificare} ${school.liceuNume}, ${school.liceuAdresa.adrJudet.judNume}, ${school.liceuAdresa.adrLocalitate}`,
              value: school.liceuID,
              info: {
                school,
                value: `g-scoala-${school.liceuID}`,
                type: 'scoala',
              },
            }, // valorile selectate

      loading: false,
      search: '',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { value } = this.props;
    const { state } = this;
    const queryParamsUrl = queryString.parse(window.location.search);

    if (prevProps.value !== value) {
      this.clearStateQ();
    }

    if (queryParamsUrl?.liceuID && prevState?.value?.value !== state?.value?.value) {
      if (!window.history.state?.detail) {
        this.handleChange(state.value);
      }
    }

    if (value !== prevProps.value) {
      this.setStateValue(this.removeDuplicates(value, 'value'));
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  handleChange = async (option) => {
    const { sessionSwitchLiceu, onChange } = this.props;
    const { value } = this.state;

    if (option && option.value) {
      await sessionSwitchLiceu(option.value);
    }

    this.setState({ value: option });

    if (typeof onChange === 'function') {
      onChange(option, value.value || option.value);
    }
  };

  setStateValue(value) {
    this.setState({ value });
  }

  formatOptionLabel = (option) => {
    return <GroupTag group={option.info} />;
  };

  removeDuplicates = (arr, comp) => {
    const unique = arr
      .map((e) => {
        return e[comp];
      })

      // store the keys of the unique objects
      .map((e, i, final) => {
        return final.indexOf(e) === i && i;
      })

      // eliminate the dead keys & store unique objects
      .filter((e) => {
        return arr[e];
      })
      .map((e) => {
        return arr[e];
      });

    return unique;
  };

  searchInput = (search, liceuID) => {
    if (search.length && search.length < 3) {
      this.setState({
        search,
        options: [],
      });
    }

    let data = {};
    if (search) {
      data = { search };
    } else {
      data = { liceuID: +liceuID };
    }

    if (search.length > 2 || liceuID) {
      this.setState({
        loading: true,
        search,
      });
      this.abortController.signal.onabort = () => {};

      Api2.get('/scoli', data, {
        signal: this.abortController.signal,
      })
        .then((response) => {
          if (this.abortController.signal.aborted === true) {
            const errorMessage = { name: 'AbortErrorMessageSend' };
            throw errorMessage;
          }

          const listSchools = response.data.map((school) => ({
            label: `${school.liceuClasificare} ${school.liceuNume}, ${school.liceuAdresa.adrJudet.judNume}, ${school.liceuAdresa.adrLocalitate}`,
            value: school.liceuID,
            info: {
              school,
              value: `g-scoala-${school.liceuID}`,
              type: 'scoala',
            },
          }));

          if (+liceuID > 0) {
            this.setState({
              value: listSchools[0],
            });
          }

          this.setState({
            options: listSchools,
            loading: false,
          });
        })
        .catch((error) => {
          if (error === 'AbortErrorMessageSend') {
            // console.log('AbortErrorMessageSend');
          }
        });
    }
  };

  clearStateQ() {
    this.setState({ search: '' });
  }

  render() {
    const { loading, options, value, search } = this.state;
    const customStyles = {
      control: (styles) => ({
        ...styles,
        border: 0,
        borderRadius: 0,
        boxShadow: 0,
        cursor: 'text',
      }),

      container: (styles) => {
        return {
          ...styles,
          zIndex: '2',
        };
      },

      option: (styles, state) => {
        return {
          ...styles,
          background: 'transparent',
          ':hover': {
            background: state.isDisabled ? 'transparent' : '#c9e9f2',
          },
          height: state.isDisabled ? '40px' : '',
          color: state.isDisabled ? '#888' : '',
          marginTop: state.isDisabled ? '-10px' : '',
          borderBottom: state.isDisabled ? '' : '1px solid #f4f4f4',
        };
      },

      menu: (styles) => ({
        ...styles,
        marginTop: '-1px',
        border: '1px solid #fff',
        borderTop: '0px',
        borderRadius: '0 0 3px 3px',
        boxShadow: '0px 3px 5px rgba(100,100,100, 0.1)',
      }),

      multiValue: (styles) => ({
        ...styles,
        background: '#06a7d3',
        color: '#fff',
      }),

      multiValueLabel: (styles) => ({
        ...styles,
        color: '#fff',
      }),

      placeholder: (styles) => ({
        ...styles,
        fontSize: 13,
        textAlign: 'center',
        width: '100%',
      }),
    };
    const { uaID, isMulti, selectIsDisabled, t } = this.props;

    return (
      <div key={uaID} className="comp-select-person comp-select-schools relative">
        <Select
          formatOptionLabel={this.formatOptionLabel}
          id="destinatarScoala"
          isLoading={loading}
          isMulti={isMulti !== false}
          isSearchable
          loadingMessage={() => {
            return t('common:wait_select');
          }}
          noOptionsMessage={() => {
            return search.length > 2 ? t('common:no_result_select') : t('common:add_characters_select');
          }}
          name="destinatar"
          onChange={this.handleChange}
          onInputChange={this.searchInput}
          isOptionDisabled={(option) => option.disabled || false}
          isDisabled={selectIsDisabled}
          options={options}
          placeholder={t('placeholder_select_schools')}
          styles={customStyles}
          value={value}
        />
      </div>
    );
  }
}

SelectSchools.propTypes = {
  /** valoarea selectata */
  value: PropTypes.arrayOf(PropTypes.shape({})),
  /** functia de change */
  onChange: PropTypes.func,
  /** selectul este disabled */
  selectIsDisabled: PropTypes.bool,
  /** permite utilizatorului sa selecteze mai multe valori */
  isMulti: PropTypes.bool,
  school: PropTypes.shape({
    liceuClasificare: PropTypes.string,
    liceuNume: PropTypes.string,
    liceuAdresa: PropTypes.shape({
      adrJudet: PropTypes.shape({
        judID: PropTypes.string,
        judNume: PropTypes.string,
      }),
      adrTara: PropTypes.shape({
        taraID: PropTypes.string,
        taraNume: PropTypes.string,
      }),
      adrLocalitate: PropTypes.string,
    }),
    liceuID: PropTypes.string,
  }).isRequired,
  t: PropTypes.func.isRequired,
  uaID: PropTypes.string,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  sessionSwitchLiceu: PropTypes.func.isRequired,
};

SelectSchools.defaultProps = {
  selectIsDisabled: false,
  isMulti: true,
  onChange: () => {},
  value: [],
  uaID: '',
  location: {},
};

const mapStateToProps = (state) => {
  return {
    school: state.session.school || {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sessionSwitchLiceu: (liceuID) => {
      return dispatch(sessionSwitchLiceuAction(liceuID));
    },
  };
};

const ConnectedSelectSchools = connect(mapStateToProps, mapDispatchToProps)(SelectSchools);
export default withTranslation(['ComponentSelectSchools', 'common'])(ConnectedSelectSchools);
