import React, { Component } from 'react';

import PropTypes from 'prop-types';

import './PhoneInput.less';

import PhoneInput from 'react-phone-input-2';

import 'react-phone-input-2/lib/style.css';

class PhoneInputWrapper extends Component {
  constructor(props) {
    super(props);

    let localPhoneExtension = 'ro';

    const location = window.location.host.split('.')[2];

    switch (location) {
      case 'com':
        localPhoneExtension = 'gb';
        break;
      case 'md':
        localPhoneExtension = 'md';
        break;
      case 'hu':
        localPhoneExtension = 'hu';
        break;
      default:
        localPhoneExtension = 'ro';
    }

    this.state = {
      phone: props.phone || '',
      curentLanguage: localPhoneExtension,
      languageObject: {},
      protoPhone: '',
      firstTime: true,
      roFormat: '+. .... ... ... ... ...',
      formatedPhone: '',
      featurevalue: '',
    };
  }

  componentDidMount() {
    const input = document.getElementsByClassName('form-control')[0];
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value').set;
    nativeInputValueSetter.call(input, '0');
    const ev2 = new Event('input', { bubbles: true });
    input.dispatchEvent(ev2);
  }

  onChangeLocal = (phoneNumber, country) => {
    const { languageObject, featurevalue, roFormat, firstTime, phone } = this.state;
    const { onChange } = this.props;
    if (firstTime) {
      const formatedPhone = this.formatPhone(phone, roFormat, country);
      this.setState({
        languageObject: country,
        firstTime: false,
        formatedPhone,
      });
    }

    if (languageObject.countryCode !== country.countryCode && !firstTime) {
      if (featurevalue === '') {
        this.setState({
          languageObject: country,
          phone: '',
          formatedPhone: '',
          featurevalue: '',
        });
      } else {
        const formatedPhone = this.formatPhone(featurevalue, roFormat, country);
        this.setState(
          {
            languageObject: country,
            phone: featurevalue,
            formatedPhone,
            featurevalue: '',
          },
          () => {
            onChange(featurevalue);
          }
        );
      }
    }
  };

  formatPhone = (phone, roFormat, languageObject) => {
    let { format } = languageObject;
    if (languageObject.countryCode === 'ro') {
      format = roFormat;
    }
    let finalText = '';
    let index = 0;
    [...format].forEach((value) => {
      if ([...phone].length > index) {
        switch (value) {
          case '+':
            finalText = '+';
            break;
          case '.':
            finalText = `${finalText}${phone[index]}`;
            index += 1;
            break;
          case ' ':
            finalText = `${finalText} `;
            break;
          case '(':
            finalText = `${finalText}(`;
            break;
          case '-':
            finalText = `${finalText}-`;
            break;
          case ')':
            finalText = `${finalText})`;
            break;
          default:
            break;
        }
      }
    });
    return finalText;
  };

  change = (e) => {
    const { roFormat, languageObject, phone } = this.state;
    const { onChange } = this.props;
    let { value } = e.target;
    const initValue = value;
    value = value.replace(/\D/g, ''); // replace all non numbers
    const oneDeletedFromValue = value.slice(0, -1);
    const oneDeletedFromState = phone.slice(0, -1);
    const lengthdif = [...value].length - [...phone].length;
    const featurevalue = value;

    // facem diferenta dintre editarea normala si copierea unui numar strain
    if (
      initValue.includes('+') &&
      ((lengthdif === 1 && oneDeletedFromValue !== phone) ||
        (lengthdif === 0 && value !== phone && value.substring(0, 3) !== phone.substring(0, 3)) ||
        (lengthdif === -1 && oneDeletedFromState !== value) ||
        lengthdif < -2 ||
        lengthdif > 1)
    ) {
      this.setState(
        {
          featurevalue,
        },
        () => {
          const input = document.getElementsByClassName('form-control')[0];
          const nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'value')
            .set;
          nativeInputValueSetter.call(input, featurevalue);
          const ev2 = new Event('input', { bubbles: true });
          input.dispatchEvent(ev2);
        }
      );
    } else {
      if (phone === '' || phone.length === 1) {
        if (languageObject.countryCode === 'ro') {
          value = `4${value}`;
        } else {
          value = `${languageObject.dialCode}${value}`;
        }
      }
      const formatedPhone = this.formatPhone(value, roFormat, languageObject);
      this.setState(
        {
          phone: value,
          formatedPhone,
        },
        () => {
          onChange(value);
        }
      );
    }
  };

  render() {
    const { curentLanguage, formatedPhone, protoPhone } = this.state;
    const { label, disabled } = this.props;

    return (
      <div className="phone_number_component">
        <div className="label">{label}</div>
        <div className="wrapper">
          <PhoneInput
            country={curentLanguage}
            value={protoPhone}
            disabled={disabled}
            disableCountryCode
            onFocus={this.onFocus}
            onChange={this.onChangeLocal}
            enableSearch
          />
          <input
            className={`secudary-input ${disabled ? 'secudary-input-disabled' : ''}`}
            type="tel"
            value={formatedPhone}
            onChange={this.change}
            disabled={disabled}
          />
        </div>
      </div>
    );
  }
}

PhoneInputWrapper.propTypes = {
  phone: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

PhoneInputWrapper.defaultProps = {
  phone: '',
  label: '',
  disabled: false,
  onChange: () => {},
};

export default PhoneInputWrapper;
