import React, { Component } from 'react';

import PropTypes from 'prop-types';

import './Pagination.less';

class Pagination extends Component {
  constructor(props) {
    super(props);

    this.state = {
      pages: 5,
      togglePagesListBefore: false,
      togglePagesListAfter: false,
      mobileWidth: null,
    };

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
    window.addEventListener('resize', this.resize.bind(this));
    this.resize();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.resize.bind(this));
  }

  /**
   * Remove list if clicked on outside of element
   */
  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        togglePagesListBefore: false,
        togglePagesListAfter: false,
      });
    }
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  resize = () => {
    const checkMobileWidth = window.innerWidth <= 768;
    const { mobileWidth } = this.state;

    if (checkMobileWidth !== mobileWidth) {
      this.setState({
        mobileWidth: checkMobileWidth,
      });
    }
  };

  changePage = (page) => {
    const { onChangePage } = this.props;
    onChangePage('page', page);
    this.setState({
      togglePagesListBefore: false,
      togglePagesListAfter: false,
    });
  };

  togglePagesList = (name, val) => {
    this.setState({
      togglePagesListBefore: false,
      togglePagesListAfter: false,
      [name]: !val,
    });
  };

  render() {
    const { mobileWidth, pages, togglePagesListBefore, togglePagesListAfter } = this.state;
    const { totalPages, currentPage, arrow, numberOfItems, dropdownDirection } = this.props;
    const noOfItems = numberOfItems || 3;
    const itemList = [];
    const initialPage = 1;
    const firstNumberOfString = parseInt(currentPage, 10) - parseInt(noOfItems / 2, 10);
    const lastNumberOfString = parseInt(currentPage, 10) + parseInt(noOfItems / 2, 10);
    let startCount = 0;
    const pagesBefore = [];
    const pagesAfter = [];

    if (totalPages > pages) {
      if (currentPage === 1) {
        for (let i = currentPage + 1; i <= lastNumberOfString; i += 1) {
          itemList.push(
            <button
              type="button"
              key={i}
              className={`comp-pagination__button ${i === currentPage ? 'active' : ''}`}
              onClick={this.changePage.bind(this, i)}
            >
              {i}
            </button>
          );
        }
      } else {
        if (currentPage === 2) {
          startCount = currentPage;
        } else if (currentPage === 3) {
          startCount = currentPage - 1;
        }

        if (currentPage === 2 || currentPage === 3) {
          for (let i = startCount; i <= lastNumberOfString; i += 1) {
            itemList.push(
              <button
                type="button"
                key={i}
                className={`comp-pagination__button ${i === currentPage ? 'active' : ''}`}
                onClick={this.changePage.bind(this, i)}
              >
                {i}
              </button>
            );
          }
        } else {
          for (let i = 0; i < noOfItems; i += 1) {
            const cursor = firstNumberOfString + i;
            if (cursor === totalPages) break;
            itemList.push(
              <button
                type="button"
                key={cursor}
                className={`comp-pagination__button ${cursor === currentPage ? 'active' : ''}`}
                onClick={this.changePage.bind(this, cursor)}
              >
                {cursor}
              </button>
            );
          }
        }
      }
    } else {
      for (let i = 2; i <= parseInt(totalPages, 10) - 1; i += 1) {
        itemList.push(
          <button
            type="button"
            key={i}
            className={`comp-pagination__button ${i === currentPage ? 'active' : ''}`}
            onClick={this.changePage.bind(this, i)}
          >
            {i}
          </button>
        );
      }
    }

    for (let i = mobileWidth ? 1 : 2; i < firstNumberOfString; i += 1) {
      pagesBefore.push(
        <button type="button" className="pages-list__button" key={i} onClick={() => this.changePage(i)}>
          {i}
        </button>
      );
    }

    for (let i = lastNumberOfString + 1; i < (mobileWidth ? totalPages + 1 : totalPages); i += 1) {
      pagesAfter.push(
        <button type="button" className="pages-list__button" key={i} onClick={() => this.changePage(i)}>
          {i}
        </button>
      );
    }

    return (
      <div className="comp-pagination">
        {totalPages > 1 && currentPage <= totalPages && (
          <div className="pagination-content" ref={this.setWrapperRef}>
            {currentPage !== 1 && arrow === true && (
              <button
                type="button"
                className="comp-pagination__button"
                onClick={this.changePage.bind(this, currentPage - 1)}
              >
                <span className="_icon icon-sageata-stanga comp-pagination__arrow" />
              </button>
            )}
            <button
              type="button"
              className={`comp-pagination__button p5 ${
                mobileWidth && (currentPage === 1 || currentPage === 2 || currentPage === 3) ? '' : 'first-page-mobile'
              } ${currentPage === 1 ? 'active' : ''}`}
              onClick={this.changePage.bind(this, 1)}
            >
              1
            </button>

            {firstNumberOfString > initialPage + 1 && totalPages > pages ? (
              <div className="more-page-content">
                <button
                  type="button"
                  onClick={() => this.togglePagesList('togglePagesListBefore', togglePagesListBefore)}
                  className="more-page"
                >
                  ...
                </button>
                <ul className={` ${togglePagesListBefore ? '' : 'hide'} ${dropdownDirection}`}>{pagesBefore}</ul>
              </div>
            ) : null}

            {itemList}

            {lastNumberOfString + 1 < totalPages && totalPages > pages && (
              <div className="more-page-content">
                <button
                  type="button"
                  onClick={() => this.togglePagesList('togglePagesListAfter', togglePagesListAfter)}
                  className="more-page"
                >
                  ...
                </button>
                <ul className={` ${togglePagesListAfter ? '' : 'hide'} ${dropdownDirection}`}>{pagesAfter}</ul>
              </div>
            )}

            {totalPages && (
              <button
                type="button"
                className={`comp-pagination__button p5 ${
                  mobileWidth &&
                  (currentPage === totalPages || currentPage === totalPages - 1 || currentPage === totalPages - 2)
                    ? ''
                    : 'last-page-mobile'
                } ${currentPage === totalPages ? 'active' : ''}`}
                onClick={this.changePage.bind(this, totalPages)}
              >
                {totalPages}
              </button>
            )}

            {currentPage !== totalPages && arrow === true && (
              <button
                type="button"
                className="comp-pagination__button"
                onClick={this.changePage.bind(this, currentPage + 1)}
              >
                <span className="_icon icon-sageata-dreapta comp-pagination__arrow" />
              </button>
            )}
          </div>
        )}
      </div>
    );
  }
}

Pagination.propTypes = {
  /** sageti pentru next/prev current page */
  arrow: PropTypes.bool,
  /** numarul de pagini vizibile, fara prima si ultima pagina */
  numberOfItems: PropTypes.number,
  /** Pagina curenta. */
  currentPage: PropTypes.number,
  /** Nr total de pagini. */
  totalPages: PropTypes.number,
  /** Seteaza state-ul cu valoarea paginii curente. */
  onChangePage: PropTypes.func,
  /** Directia in care se deschide dropdown-ul cu paginile intermediare: top sau bottom */
  dropdownDirection: PropTypes.string,
};

Pagination.defaultProps = {
  arrow: true,
  numberOfItems: 0,
  currentPage: 0,
  totalPages: 0,
  onChangePage: () => {},
  dropdownDirection: 'bottom',
};

export default Pagination;
