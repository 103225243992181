/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import PropTypes from 'prop-types';

import PersonTag from '../../../../Content/PersonTag';
import Icon from '../../../../Content/Icon';

import { TYPE_PERS_INST, TYPE_ADMIN } from '../../../../../constants';

import './CheckBox.less';

export default function CheckBox({
  name,
  value,
  label,
  tick,
  onCheck,
  onClickItem,
  person,
  allItem,
  restrictii,
  uaTip,
  selectMoreUsers,
  restrictiiGrupAllItem,
  firstStep,
}) {
  const renderIcon = () => {
    switch (name) {
      case 'elevi':
        return <Icon icon="icon-elevi" className="custom-icon__checkbox" />;
      case 'parinti':
        return <Icon icon="icon-parinti" className="custom-icon__checkbox" />;
      case 'cadreFirstStep':
        return <Icon icon="icon-cadre-2" className="custom-icon__checkbox" />;
      case 'administrativ':
        return <Icon icon="icon-admin" className="custom-icon__checkbox" />;
      case 'cadreRole':
        return <Icon icon="icon-elevi" className="custom-icon__checkbox" />;
      default:
        break;
    }

    return null;
  };

  const firstStepPersInt = () => {
    if (uaTip === TYPE_PERS_INST && (name === 'elevi' || name === 'parinti' || name === 'cadreFirstStep')) {
      return false;
    }

    return true;
  };

  const enabledClickItem = () => {
    if (
      uaTip !== TYPE_ADMIN ||
      (uaTip === TYPE_ADMIN && name === 'administrativ') ||
      (uaTip === TYPE_ADMIN && selectMoreUsers)
    ) {
      return onClickItem();
    }
    return null;
  };

  return (
    <>
      {firstStepPersInt() && (
        <div
          className={`comp-checkbox ${
            firstStep &&
            !allItem &&
            (person || restrictiiGrupAllItem) &&
            name === 'administrativ' &&
            'comp-checkbox-extra-margin'
          }`}
        >
          {allItem && +restrictii === 1 && restrictiiGrupAllItem && (
            <label htmlFor="comp-checkbox__all-item">
              <input
                id="comp-checkbox__all-item"
                name={name}
                type="checkbox"
                value={value}
                checked={tick || false}
                onChange={onCheck}
              />
              {label}
            </label>
          )}

          {!allItem && (person || restrictiiGrupAllItem) && name !== 'administrativ' && (
            <input
              name={name}
              type="checkbox"
              id={person ? `checkPersonTag-${person?.cadID || person?.elevID}` : 'checkPersonTag'}
              value={value}
              checked={tick || false}
              onChange={onCheck}
            />
          )}
          {!person && !allItem && (
            <p onClick={() => enabledClickItem()} role="presentation" className="comp-checkbox__item">
              {renderIcon()}
              {label}
              <Icon icon="icon-sageata-dreapta" className="comp-checkbox__arrow" />
            </p>
          )}
          {person && !allItem && (
            <label htmlFor={person ? `checkPersonTag-${person?.cadID || person?.elevID}` : 'checkPersonTag'}>
              <PersonTag person={person} showClass={false} ellipsisWidth={200} />
            </label>
          )}
        </div>
      )}
    </>
  );
}

CheckBox.propTypes = {
  name: PropTypes.string,
  onCheck: PropTypes.func,
  value: PropTypes.string,
  label: PropTypes.string,
  onClickItem: PropTypes.func,
  tick: PropTypes.bool,
  person: PropTypes.oneOfType([PropTypes.bool, PropTypes.instanceOf(Object)]),
  allItem: PropTypes.bool,
  restrictii: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uaTip: PropTypes.string,
  selectMoreUsers: PropTypes.bool,
  restrictiiGrupAllItem: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  firstStep: PropTypes.bool,
};

CheckBox.defaultProps = {
  name: '',
  onCheck: () => {},
  value: '',
  label: '',
  onClickItem: () => {},
  tick: false,
  person: {} || false,
  allItem: false,
  restrictii: '',
  uaTip: '',
  selectMoreUsers: true,
  restrictiiGrupAllItem: false,
  firstStep: false,
};
