import React, { useEffect, useRef } from 'react';

import debounce from '../utils/hooks';

import './LineChart.less';

const { google } = window;

google.charts.load('current', { packages: ['corechart'] });

export default ({ data }) => {
  const lineChartRef = useRef();

  const lineOptions = {
    hAxis: {
      textStyle: {
        color: '#fcb150',
        fontSize: 11,
      },
      slantedText: true,
      slantedTextAngle: 45,
    },
    vAxis: {
      textStyle: {
        fontSize: 12,
      },
      format: '#',
      gridlines: { color: '#eee' },
      viewWindow: {
        min: 1,
      },
      direction: '-1',
    },
    colors: ['#FF3939'],
    chartArea: { left: 30, right: 30, top: 20, bottom: 40, width: '100%' },
    pointSize: 6,
    legend: 'none',
    tooltip: { isHtml: true },
  };

  const drawChart = () => {
    if (!lineChartRef.current) {
      return;
    }

    const lineChartData = new google.visualization.DataTable();

    const { chartColumns } = data;

    const [firstColumn, secondColumn] = chartColumns;

    lineChartData.addColumn(firstColumn.type, firstColumn.value);
    lineChartData.addColumn(secondColumn.type, secondColumn.value);
    lineChartData.addRows(data.chartData);

    const chart = new google.visualization.LineChart(lineChartRef.current);

    chart.draw(lineChartData, lineOptions);
  };

  useEffect(() => {
    google.charts.setOnLoadCallback(drawChart);

    const debouncedHandleResize = debounce(drawChart, 100);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  const render = () => (
    <div className="line-chart">
      <div className="line-chart__title">{data.chartTitle}</div>
      <div className="line-chart__render-container" ref={lineChartRef} />
    </div>
  );

  return render();
};
