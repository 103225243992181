import React from 'react';

import PropTypes from 'prop-types';

import Button from 'Components/Forms/SimpleButton/Button';

class BrowseButton extends React.PureComponent {
  render() {
    const { ladda, progress, loading, className, id, content, processedPhoto, totalPhoto } = this.props;

    return (
      <Button
        className={`${className} `}
        type="button"
        id={`${id}`}
        ladda={ladda}
        haslink
        progress={progress}
        loading={loading}
        processedPhoto={processedPhoto}
        totalPhoto={totalPhoto}
      >
        {content}
      </Button>
    );
  }
}

BrowseButton.propTypes = {
  ladda: PropTypes.bool,
  progress: PropTypes.number,
  loading: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  content: PropTypes.string,
  processedPhoto: PropTypes.string,
  totalPhoto: PropTypes.number,
};

BrowseButton.defaultProps = {
  ladda: false,
  progress: 0,
  loading: false,
  className: '',
  id: '',
  content: '',
  processedPhoto: '',
  totalPhoto: 0,
};

export default BrowseButton;
