/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import { Link as ReactLink } from 'react-router-dom';

const Link = ({ to, children, ...props }) => {
  const hasCookiesAdsNative = !!Cookies.get('ads_native');

  if (hasCookiesAdsNative) {
    let href;

    if (typeof to === 'string') {
      href = to;
    } else {
      href = `${to.pathname}${to.search ? to.search : ''}`;
    }

    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  }

  return (
    <ReactLink to={to} {...props}>
      {children}
    </ReactLink>
  );
};

export default Link;

Link.propTypes = { to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), children: PropTypes.node };

Link.defaultProps = {
  to: '',
  children: [],
};
