import React, { Component } from 'react';

import PropTypes from 'prop-types';

import shortId from 'short-id';

import './PageTabs.less';

class PageTabs extends Component {
  constructor(props) {
    super(props);

    this.scrollDiv = React.createRef();
  }

  handleClick = (el) => {
    const totalElem = [];
    const prevElem = [];
    let sum = 0;
    let sumaPrev = 0;
    const currentElement = el - 1;
    const widthCurrentElem = el > 0 ? document.getElementById(`item-${currentElement}`).offsetWidth : 0;

    for (let i = 0; i < this.scrollDiv.current.children.length; i += 1) {
      totalElem.push(document.getElementById(`item-${i}`).offsetWidth);
    }

    totalElem.forEach((elm) => {
      sum += elm;
    });

    if (el > 0) {
      for (let i = 0; i < el; i += 1) {
        prevElem.push(document.getElementById(`item-${i}`).offsetWidth);
      }

      prevElem.forEach((val) => {
        sumaPrev += val;
      });
    }

    const leftMove = sumaPrev - sum / 2 + widthCurrentElem;

    const node = this.scrollDiv.current;

    if (node) {
      node.scrollTo({
        left: leftMove,
        behavior: 'smooth',
      });
    }
  };

  showLinks = () => {
    const { children } = this.props;
    const links = [];
    children.forEach((item, index) => {
      links.push(
        <button
          type="button"
          className="page-tabs__button _tab"
          data-count={index}
          key={shortId.generate()}
          id={`item-${index}`}
          onClick={() => this.handleClick(index)}
        >
          {item}
        </button>
      );
    });
    return links;
  };

  render() {
    return (
      <div className="comp-tabs">
        <div className="scroll-div" ref={this.scrollDiv}>
          {this.showLinks()}
        </div>
      </div>
    );
  }
}

PageTabs.propTypes = {
  /** noduri de tip <NavLink> */
  children: PropTypes.node,
};

PageTabs.defaultProps = {
  children: '',
};

export default PageTabs;
