/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import ReactDOM from 'react-dom';

import { withTranslation } from 'react-i18next';

import './DatePicker.less';

const oneDay = 60 * 60 * 24 * 1000;
const todayTimestamp = Date.now() - (Date.now() % oneDay) + new Date().getTimezoneOffset() * 1000 * 60;
const inputRef = React.createRef();

class DatePicker extends Component {
  constructor(props) {
    super(props);
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    this.state = {
      year,
      month,
      selectedDay: props.updateDate || todayTimestamp,
      monthDetails: this.getMonthDetails(year, month),
    };
  }

  componentDidMount() {
    const { defaultDate } = this.props;
    const { selectedDay } = this.state;
    window.addEventListener('click', this.addBackDrop);
    if (defaultDate !== 'missing') {
      this.setDateToInput(defaultDate || selectedDay);
    }
  }

  componentDidUpdate(prevProps) {
    const { updateDate } = this.props;
    if (!!updateDate && prevProps.updateDate !== updateDate) {
      this.updateCurrentDate(updateDate);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.addBackDrop);
  }

  updateCurrentDate = (myDate) => {
    if (myDate) {
      this.setState({
        selectedDay: myDate,
      });
      this.onChangePicker(myDate);
    }
  };

  addBackDrop = (e) => {
    const { showDatePicker } = this.state;
    if (showDatePicker && !ReactDOM.findDOMNode(this).contains(e.target)) {
      this.showDatePicker(false);
    }
  };

  showDatePicker = (showDatePicker = true) => {
    this.setState({ showDatePicker });
  };

  daysMap = () => {
    const { i18n } = this.props;
    const daysMapEn = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const daysMapRo = ['Luni', 'Marti', 'Miercuri', 'Joi', 'Vineri', 'Sambata', 'Duminica'];
    return i18n.language === 'ro' ? daysMapRo : daysMapEn;
  };

  monthMap = () => {
    const { i18n } = this.props;
    const monthMapEn = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const monthMapRo = [
      'Ianuarie',
      'Februarie',
      'Martie',
      'Aprilie',
      'Mai',
      'Iunie',
      'Iulie',
      'August',
      'Septembrie',
      'Octombrie',
      'Noiembrie',
      'Decembrie',
    ];
    return i18n.language === 'ro' ? monthMapRo : monthMapEn;
  };

  getDayDetails = (args) => {
    const date = args.index - args.firstDay;
    const day = args.index % 7;
    let prevMonth = args.month - 1;
    let prevYear = args.year;
    if (prevMonth < 0) {
      prevMonth = 11;
      prevYear -= 1;
    }
    const prevMonthNumberOfDays = this.getNumberOfDays(prevYear, prevMonth);
    const newDate = (date < 0 ? prevMonthNumberOfDays + date : date % args.numberOfDays) + 1;
    let month = -1;
    if (date >= 0) {
      if (date >= args.numberOfDays) {
        month = 1;
      } else {
        month = 0;
      }
    }
    const timestamp = new Date(args.year, args.month, newDate).getTime();

    return {
      date: newDate,
      day,
      month,
      timestamp,
      dayString: this.daysMap()[day],
    };
  };

  getNumberOfDays = (year, month) => {
    return 40 - new Date(year, month, 40).getDate();
  };

  getMonthDetails = (year, month) => {
    const firstDay = new Date(year, month).getDay() === 0 ? 7 : new Date(year, month).getDay();
    const numberOfDays = this.getNumberOfDays(year, month);
    const monthArray = [];
    const rows = 7;
    let currentDay = null;
    let index = 1;
    const cols = 7;

    for (let row = 0; row < rows; row += 1) {
      for (let col = 0; col < cols; col += 1) {
        currentDay = this.getDayDetails({
          index,
          numberOfDays,
          firstDay,
          year,
          month,
        });
        monthArray.push(currentDay);
        index += 1;
      }
    }

    return monthArray;
  };

  isCurrentDay = (day) => {
    return day.timestamp === todayTimestamp;
  };

  isSelectedDay = (day) => {
    const { selectedDay } = this.state;
    return day.timestamp === selectedDay;
  };

  getDateFromDateString = (dateValue) => {
    const dateData = dateValue.split('-').map((d) => d);
    if (dateData.length < 3) return null;

    const year = dateData[0];
    const month = dateData[1];
    const date = dateData[2];
    return { year, month, date };
  };

  getMonthStr = (month) => this.monthMap()[Math.max(Math.min(11, month), 0)] || 'Month';

  getDateStringFromTimestamp = (timestamp) => {
    const dateObject = new Date(timestamp);
    const month = dateObject.getMonth() + 1;
    const date = dateObject.getDate();
    return `${dateObject.getFullYear()}-${month < 10 ? `0${month}` : month}-${date < 10 ? `0${date}` : date}`;
  };

  setDate = (dateData) => {
    const selectedDay = new Date(dateData.year, dateData.month - 1, dateData.date).getTime();
    this.setState({
      selectedDay,
    });
    this.onChangePicker(selectedDay);
  };

  updateDateFromInput = () => {
    const dateValue = inputRef.current.value;
    const dateData = this.getDateFromDateString(dateValue);
    if (dateData !== null) {
      this.setDate(dateData);
      this.setState({
        year: dateData.year,
        month: dateData.month - 1,
        monthDetails: this.getMonthDetails(dateData.year, dateData.month - 1),
      });
    }
  };

  setDateToInput = (timestamp) => {
    this.onChangePicker(timestamp);
  };

  onChangePicker = (prop) => {
    const { getDateDatePicker } = this.props;
    const dayOfWeek = this.daysMap();

    const date = new Date(prop);
    const year = date.getFullYear();
    const month = this.monthMap()[date.getMonth()]?.slice(0, 3);
    const day = date.getDate() < 10 ? `0${date.getDate()}` : date.getDate();
    const weekDay = dayOfWeek[date.getDay()];
    const monthInt = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    this.setState(
      {
        currentSelDate: {
          year,
          month,
          monthInt,
          day,
        },
      },
      getDateDatePicker({
        year,
        month,
        monthInt,
        day,
        timestamp: prop,
        weekDay,
      })
    );
  };

  onDateClick = (day) => {
    this.setState(
      {
        selectedDay: day.timestamp,
        showDatePicker: false,
      },
      () => this.setDateToInput(day.timestamp)
    );
    this.onChangePicker(day.timestamp);
  };

  setYear = (offset) => {
    const { currYearNavigate } = this.props;
    const { month, year: stateYear } = this.state;
    const year = stateYear + offset;
    this.setState(
      {
        year,
        monthDetails: this.getMonthDetails(year, month),
      },
      () => currYearNavigate(year)
    );
  };

  setMonth = (offset) => {
    const { year: yearState, month: monthState } = this.state;
    let month = monthState + offset;
    let year = yearState;
    if (month === -1) {
      month = 11;
      year -= 1;
    } else if (month === 12) {
      month = 0;
      year += 1;
    }
    this.setState({
      year,
      month,
      monthDetails: this.getMonthDetails(year, month),
    });
  };

  /**
   *  Renderers
   */

  renderCalendar() {
    const { i18n } = this.props;
    const { monthDetails } = this.state;
    const english = ['MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT', 'SUN'];
    const romanian = ['LUN', 'MAR', 'MIE', 'JOI', 'VIN', 'SAM', 'DUM'];
    const days = monthDetails.map((day) => {
      return (
        <div
          className={`c-day-container ${day.month !== 0 ? ' disabled' : ''}${
            this.isCurrentDay(day) ? ' highlight' : ''
          }${this.isSelectedDay(day) ? ' highlight-green' : ''}`}
          key={`${day.date}-${day.month}-${day.day}`}
        >
          <div className="cdc-day">
            <span onClick={() => this.onDateClick(day)}>{day.date}</span>
          </div>
        </div>
      );
    });

    return (
      <div className="c-container">
        <div className="cc-head">
          {(i18n.language === 'ro' ? romanian : english).map((weekDay) => {
            return (
              <div key={weekDay} className="cch-name">
                {weekDay}
              </div>
            );
          })}
        </div>
        <div className="cc-body">{days}</div>
      </div>
    );
  }

  render() {
    const { currentSelDate, showDatePicker, year, month } = this.state;
    const { shortDisplay, prevYearDisabled, className, isInsideModal, t } = this.props;

    return (
      <div className={`datePicker ${className} ${showDatePicker && isInsideModal && 'datePicker-minHeight'}`}>
        <div className="datePicker-container">
          <div className="mdp-input" onClick={() => this.showDatePicker(true)}>
            <input type="date" onChange={(event) => this.updateDateFromInput(event.target.value)} ref={inputRef} />
            {!currentSelDate && <span className="mdp-input-placeholder">{t('common:placeholder-datepicker')}</span>}
            <div className="display-data-picker">
              {currentSelDate &&
                (!shortDisplay
                  ? `${currentSelDate.day} ${currentSelDate.month} ${currentSelDate.year}`
                  : `${currentSelDate.day}/${currentSelDate.monthInt}`)}
            </div>
          </div>
          <div className="_icon icon-calendar-round" />
        </div>
        {showDatePicker ? (
          <div className="mdp-container">
            <div className="mdpc-head">
              <button
                type="button"
                className={`mdpch-button ${prevYearDisabled === true ? 'not-allowed' : ''}`}
                onClick={() => {
                  if (prevYearDisabled === true) {
                    return;
                  }
                  this.setYear(-1);
                }}
              >
                <div className="mdpchb-inner">
                  <span className="mdpchbi-left-arrows" />
                </div>
              </button>
              <button type="button" className="mdpch-button" onClick={() => this.setMonth(-1)}>
                <div className="mdpchb-inner">
                  <span className="mdpchbi-left-arrow" />
                </div>
              </button>
              <div className="mdpch-container">
                <div className="mdpchc-year">{year}</div>
                <div className="mdpchc-month">{this.getMonthStr(month)}</div>
              </div>
              <button type="button" className="mdpch-button" onClick={() => this.setMonth(1)}>
                <div className="mdpchb-inner">
                  <span className="mdpchbi-right-arrow" />
                </div>
              </button>
              <button type="button" className="mdpch-button" onClick={() => this.setYear(1)}>
                <div className="mdpchb-inner">
                  <span className="mdpchbi-right-arrows" />
                </div>
              </button>
            </div>
            <div className="mdpc-body">{this.renderCalendar()}</div>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default withTranslation(['ComponentDate', 'common'])(DatePicker);

DatePicker.propTypes = {
  /** false : day month year)
        true : day / monthInt) */
  shortDisplay: PropTypes.bool,
  /** fct de callback .trimite date la fiecare schimbare de data cu parametrii actualizati */
  getDateDatePicker: PropTypes.func.isRequired,

  updateDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultDate: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currYearNavigate: PropTypes.func,
  prevYearDisabled: PropTypes.bool,
  t: PropTypes.instanceOf(Object),
  i18n: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
  isInsideModal: PropTypes.bool,
};

DatePicker.defaultProps = {
  shortDisplay: false,
  updateDate: '',
  defaultDate: '',
  prevYearDisabled: false,
  currYearNavigate: () => {},
  t: {},
  className: '',
  isInsideModal: false,
};
