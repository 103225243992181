import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Grade from '../Grade/Grade';

import Tooltip from '../../v2/Tooltip';

import FormatDate from '../../../Helpers/Date/FormatDate';

import { BADGE_TYPE } from '../../Badge/constants';

import { SN_INSIGNE } from '../../../../constants';

import './GradeBox.less';

import getGradeTypeName from '../helpers/getGradeTypeName';

const GradeBox = (props) => {
  const { isOpen, grade, forceDecimals, gradeSystem, exemption, gradeColor, gradeboxClass } = props;
  const { t } = useTranslation(['ComponentGradeBox', 'ComponentDate']);

  return (
    <div className={`gradebox-class ${gradeboxClass || ''}`}>
      {Object.keys(exemption).length ? (
        <span className="exemption-container">
          <Tooltip worksOnClick isOpen={isOpen}>
            <span className="exemption-text">{t('gradebox_exempt')}</span>
            <div className="new-tooltip-fixed">
              {exemption && (
                <>
                  <div>
                    {t('gradebox_exempt_desc')}
                    <br />
                    {exemption?.soText && <span className="grade-observation-text">{`"${exemption?.soText}"`}</span>}
                  </div>
                </>
              )}
            </div>
          </Tooltip>
        </span>
      ) : (
        <Tooltip worksOnClick isOpen={isOpen}>
          <Grade grade={grade || 0} forceDecimals={forceDecimals} gradeSystem={gradeSystem} gradeColor={gradeColor} />
          <div className="new-tooltip-fixed">
            {grade ? (
              <>
                {`${gradeSystem}` === SN_INSIGNE && grade.notaValoare && (
                  <div>
                    <b>{t(`badge_${BADGE_TYPE[parseInt(grade.notaValoare, 10)].text}`)}</b>
                  </div>
                )}
                <div>
                  {grade.notaData ? t('gradebox_given') : t('gradebox_given_extra')}
                  &nbsp;
                  {grade.notaData && (
                    <>
                      {t('gradebox_on_date')}
                      &nbsp;
                      <b>
                        <FormatDate date={grade.notaData} format="dd LLL yyyy" />
                      </b>
                      &nbsp;
                    </>
                  )}
                </div>
                <div>
                  {grade.notaData && t('gradebox_at')}
                  <b> {getGradeTypeName(grade.notaTip)}</b>
                  {grade.notaObs && <>,{t('gradebox_obs')}:</>}
                </div>
                {grade.notaObs && (
                  <>
                    <span className="grade-observation-text">{`"${grade.notaObs}"`}</span>
                  </>
                )}

                {grade.notaDataInsert && (
                  <>
                    <div className="data-insert-separator" />
                    <div className="nota-data-insert">
                      {`${t('gradebox_adding_date')} `}
                      <FormatDate date={grade.notaDataInsert.split(' ')[0]} format="dd LLL yyyy" />
                      <br />
                      {grade.notaDataInsert.split(' ')[1]}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div>{t('gradebox_description')}</div>
            )}
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default GradeBox;

GradeBox.propTypes = {
  /** The actual grade that will be parsed by the component.It can be object, number or string */
  grade: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  /** This option can add decimals on a certain grade if you want */
  forceDecimals: PropTypes.bool,
  /** Different type of grade system (grades/ratings/badges) */
  gradeSystem: PropTypes.oneOf(['0', '1', '2']),
  /** Some grades have different color code (the average/some exams)
   * Currently "red","blue","green","black" are supported, but more colors can be added if needed
   */
  gradeColor: PropTypes.string,
  /** Specifies the state of the tooltip.This param is passed to Tooltip component.It is boolean */
  isOpen: PropTypes.bool,
  /** It is the param received prom the API :it can be false or an object containing data about the exemption */
  exemption: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  /** General class name */
  gradeboxClass: PropTypes.string,
};

GradeBox.defaultProps = {
  grade: '',
  forceDecimals: false,
  gradeSystem: undefined,
  gradeColor: '',
  isOpen: false,
  exemption: {},
  gradeboxClass: '',
};
