/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Icon from '../../Content/Icon';
import './Filter.less';

const Option = (props) => {
  const {
    isSelected,
    label,
    data: { value, asc },
    selectProps: { isMulti },
    formatOptionLabel,
  } = props;

  const isSortType = typeof asc === 'boolean';

  let addSelectedClass = !isMulti && isSelected;

  if (typeof asc === 'boolean' && addSelectedClass) {
    // eslint-disable-next-line react/destructuring-assignment
    addSelectedClass = props.selectProps.value?.asc === asc;
  }

  return (
    <div>
      <components.Option
        {...props}
        className={`option-container ${addSelectedClass ? 'selected' : ''} ${!isMulti ? 'single-choice' : ''}`}
      >
        {isSortType ? (
          <div className="option sort">
            <span className="field">{`${label}: `}</span>
            {asc ? 'Asc.' : 'Desc.'}
          </div>
        ) : (
          <label htmlFor="input" className="option">
            <input type={isMulti ? 'checkbox' : 'radio'} id={value} checked={isSelected} onChange={() => null} />
            {formatOptionLabel ? formatOptionLabel(label) : label}
          </label>
        )}
      </components.Option>
    </div>
  );
};

const ClearIndicator = (props) => {
  const {
    innerProps: { ref, ...restInnerProps },
    selectProps: { value },
  } = props;

  const show = value?.length || value?.value;

  return (
    <div {...restInnerProps} ref={ref} className="clear-indicator">
      {show ? <Icon icon="icon-inchide-meniu" /> : null}
    </div>
  );
};

const SelectContainer = ({ children, ...props }) => {
  const { t } = useTranslation('ComponentFilter');
  const {
    selectProps: { value, menuIsOpen },
  } = props;

  let content = null;
  const noOptionsSelcted = !value?.length && !value?.label && !menuIsOpen;

  if (!menuIsOpen) {
    if (!value?.length) {
      content = t('filtru-selecteaza');
    } else {
      content = value[0].label;
    }

    if (value?.length > 1) {
      content = `${value?.length} ${t('filtru-selectate')}`;
    }

    if (value?.label) {
      if (typeof value?.asc === 'boolean') {
        content = (
          <div className="option">
            <span className="field">{`${value.label}: `}</span>
            {value?.asc ? 'Asc.' : 'Desc.'}
          </div>
        );
      } else {
        content = value.label;
      }
    }
  }

  return (
    <components.SelectContainer
      {...props}
      className={`filter ${menuIsOpen ? 'is-open' : ''} ${noOptionsSelcted ? 'no-opt-selected' : ''}`}
    >
      {children}
      <div id="selected-element">{content}</div>
    </components.SelectContainer>
  );
};

const IndicatorSeparator = () => <span />;

const DropdownIndicator = (props) => {
  const {
    selectProps: { menuIsOpen },
  } = props;
  return menuIsOpen || <Icon icon="icon-down-arrow" />;
};

const ValueContainer = ({ children, ...props }) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  return (
    <components.ValueContainer {...props} className="value-container">
      {children} {menuIsOpen && <Icon icon="icon-cautare-lupa" />}
    </components.ValueContainer>
  );
};

const Filter = (props) => {
  const {
    options,
    onChange,
    filterLabel,
    defaultOptions,
    isMultiChoice,
    type,
    inline,
    expanded,
    delay,
    hideClearIndicator,
    formatOptionLabel,
    checkDisabled,
    isDisabled,
  } = props;
  const { t } = useTranslation('ComponentFilter');

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [currentOptions, setCurrentOptions] = useState([]);
  const [sortDireaction, setSortDirection] = useState();
  const selectRef = useRef();
  const debounceRef = useRef();

  const isMulti = type !== 'sort' && isMultiChoice;

  const sortOptions = (opt, selectedOpt) => {
    const sortedOptions = [...opt];

    if (selectedOpt.length) {
      const selectedArray = selectedOpt.map((op) => op.value);
      sortedOptions.sort((a) => (selectedArray.includes(a.value) ? -1 : 1));
    }

    if (selectedOpt.value) {
      sortedOptions.sort((a) => (selectedOpt.value === a.value ? -1 : 1));
    }

    return sortedOptions;
  };

  const handleChangeExpanded = (currentElement, isSelected) => {
    let newSelectedOptions;

    if (isMulti) {
      if (isSelected) {
        newSelectedOptions = selectedOptions.filter((el) => el.value !== currentElement.value);
      } else {
        newSelectedOptions = [...selectedOptions, currentElement];
      }
    } else if (isSelected) {
      return;
    } else {
      newSelectedOptions = [currentElement];
    }

    setSelectedOptions(newSelectedOptions);

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => onChange(newSelectedOptions), isMulti ? delay : 0);
  };

  const expandedFilter = () => {
    // eslint-disable-next-line no-nested-ternary
    const selectedValues = selectedOptions?.length
      ? selectedOptions.map((opt) => opt.value)
      : selectedOptions?.value
      ? [selectedOptions.value]
      : [];

    return (
      <div className="expanded">
        {currentOptions.map((el) => {
          const isSelected = selectedValues.includes(el.value);

          return (
            <div
              className={`option ${isSelected ? 'selected' : ''}`}
              role="button"
              key={el.value}
              tabIndex={0}
              onClick={() => handleChangeExpanded(el, isSelected)}
              onKeyDown={() => handleChangeExpanded(el, isSelected)}
            >
              <span>{el.label}</span>
            </div>
          );
        })}
      </div>
    );
  };

  useEffect(() => {
    let selectedOpt;

    if (isMulti) {
      selectedOpt = defaultOptions;
    } else {
      selectedOpt = { ...defaultOptions[0] };

      if (type !== 'sort' && typeof selectedOpt.asc === 'boolean') {
        selectedOpt.asc = undefined;
      }
    }

    setSelectedOptions(selectedOpt);

    if (type === 'sort' && !expanded) {
      const duplicatedOptions = [];

      options.forEach((el) => {
        const ascEl = { ...el, asc: true };
        const descEl = { ...el, asc: false };
        duplicatedOptions.push(ascEl, descEl);
      });
      setCurrentOptions(duplicatedOptions);
      setSortDirection(selectedOpt.asc);
    } else if (selectedOpt?.value || selectedOpt?.length) {
      if (expanded) {
        setCurrentOptions(options);
      } else {
        setCurrentOptions(sortOptions(options, selectedOpt));
      }
    } else {
      setCurrentOptions(options);
    }
  }, [isMultiChoice, type, expanded, options, isDisabled]);

  const onMenuClose = () => {
    if (type !== 'sort') {
      if (selectedOptions?.length || selectedOptions?.value) {
        setCurrentOptions(sortOptions(options, selectedOptions));
      }
    }
  };

  const handleChange = (vals) => {
    // cel putin o valoare selectata
    if (!(vals?.length === 0 && checkDisabled)) setSelectedOptions(vals);

    if (type !== 'sort') {
      if (!isMulti && vals?.value) {
        setCurrentOptions(options);
      }
    } else {
      setSortDirection(vals?.asc);
    }

    if (debounceRef.current) {
      clearTimeout(debounceRef.current);
    }

    debounceRef.current = setTimeout(() => onChange(vals), isMulti ? delay : 0);
  };

  let icon;

  if (type === 'sort') {
    icon = (
      <div
        className={`sort-icons ${sortDireaction === true ? 'ascending' : ''} ${
          sortDireaction === false ? 'descending' : ''
        }`}
      >
        <Icon icon="icon-drop-up" />
        <Icon icon="icon-drop-down" />
      </div>
    );
  }

  if (type === 'filter' || expanded) {
    icon = <Icon icon="icon-filter" />;
  }

  if (type === 'group') {
    icon = <Icon icon="icon-meniu-site" />;
  }

  const isClearable = selectedOptions && (selectedOptions.value || selectedOptions.length);

  return (
    <div
      className={`filter-container ${inline ? 'inline' : ''} ${
        !!isClearable && !hideClearIndicator ? 'clearable' : ''
      }`}
    >
      <div className="filter-label">
        {icon}
        {filterLabel}
      </div>
      {expanded ? (
        expandedFilter()
      ) : (
        <Select
          closeMenuOnSelect={!isMulti}
          isMulti={isMulti}
          components={{
            Option: (params) => Option({ ...params, formatOptionLabel }),
            ClearIndicator,
            SelectContainer,
            IndicatorSeparator,
            DropdownIndicator,
            ValueContainer,
          }}
          ref={selectRef}
          controlShouldRenderValue={false}
          isClearable={!hideClearIndicator}
          value={selectedOptions}
          options={currentOptions}
          hideSelectedOptions={false}
          backspaceRemovesValue={false}
          onChange={handleChange}
          onMenuClose={onMenuClose}
          placeholder=""
          noOptionsMessage={() => t('filtru-no-options')}
          isDisabled={isDisabled}
        />
      )}
    </div>
  );
};

export default Filter;

Filter.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
      value: PropTypes.string,
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  filterLabel: PropTypes.string.isRequired,
  defaultOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)]),
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      asc: PropTypes.bool,
    })
  ),
  isMultiChoice: PropTypes.bool,
  /**
   * When type == "sort", isMultiChoice = false
   */
  type: PropTypes.oneOf(['filter', 'group', 'sort']),
  inline: PropTypes.bool,
  /**
   * Should be used when options.length <= 5
   */
  expanded: PropTypes.bool,
  /**
   * Delay until the onChange is called. When isMultiChoice == false or type == "sort", there is no delay
   */
  delay: PropTypes.number,
  /**
   * To hide the clear indicator
   */
  hideClearIndicator: PropTypes.bool,
  /**
   * To format the option label. It receives the label as parameter
   */
  formatOptionLabel: PropTypes.func,
  /**
   * At least one box is checked
   */
  checkDisabled: PropTypes.bool,
  /**
   * The Filter is grayed out
   */
  isDisabled: PropTypes.bool,
};

Filter.defaultProps = {
  defaultOptions: [],
  isMultiChoice: true,
  type: 'filter',
  inline: false,
  expanded: false,
  delay: 1000,
  hideClearIndicator: false,
  formatOptionLabel: undefined,
  checkDisabled: false,
  isDisabled: false,
};
