const tooltipArrowHeight = 10;

export const setTop = (props) => {
  const { triggerY, windowScrollY, contentHeight } = props;

  return { value: triggerY + windowScrollY - tooltipArrowHeight - contentHeight, cssClass: 'tooltip__content--top' };
};

export const setCenter = (props) => {
  const { triggerX, windowScrollX, triggerWidth, contentWidth } = props;

  return {
    value: triggerX + windowScrollX + triggerWidth / 2 - contentWidth / 2,
    cssClass: 'tooltip__content--center',
  };
};

export const setBottom = (props) => {
  const { triggerY, windowScrollY, triggerHeight } = props;

  return { value: triggerY + windowScrollY + triggerHeight + tooltipArrowHeight, cssClass: 'tooltip__content--bottom' };
};

export const setLeft = (props) => {
  const { triggerX } = props;

  return { value: triggerX, cssClass: 'tooltip__content--left' };
};

export const setRight = (props) => {
  const { triggerX, windowScrollX, triggerWidth, contentWidth } = props;

  return { value: triggerX + windowScrollX + triggerWidth - contentWidth, cssClass: 'tooltip__content--right' };
};
