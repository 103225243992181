import React, { Component } from 'react';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Api2 from '../../../Libs/api';

import i18n from '../../../Libs/i18n/i18n';

import {
  getUaTip,
  getClasaCurenta,
  getCurrentElevID,
  isDirector,
  isSecretar,
  isCadruDidactic,
} from '../../../Redux/SessionHelper';
import { sessionSwitchLiceu } from '../../../Redux/SessionActions';

import Button from '../../Forms/SimpleButton/Button';
import EmptyState from '../../Content/EmptyState/EmptyState';
import Breadcrumbs from '../../Navigation/Breadcrumbs/Breadcrumbs';

import Loader from '../Loader/Loader/Loader';
import Modal from '../Modal/Modal';

import {
  TYPE_ELEV,
  TYPE_PARINTE,
  TYPE_CADRU,
  TYPE_PERS_INST,
  PERM_CAD_DIRECTOR,
  // PERM_CAD_AUX,
  PERM_CAD_SECRETAR,
  TYPE_ADMIN,
} from '../../../constants';

import { capitalizeFirstLetter, getAniStudiu, removeDuplicates } from './helpers';

import missingMembers from './images/missingMembers.svg';

import List from './components/List';

import ro from './Lang/ro.json';
import en from './Lang/en.json';

import './SelectGroup.less';

class SelectGroup extends Component {
  constructor(props) {
    super(props);

    i18n.addTranslations({
      ro,
      en,
    });

    const { uaTip, schools, t } = this.props;

    this.state = {
      selectGroup: null, // mat sau ast -> pt cadre || administrativ
      astID: null,
      clID: null,
      roleID: null, // id-ul rolului
      materii: null,
      aniStudiu: null,
      clase: null,
      elevi: null,
      cadre: null,
      matID: null,
      loading: true,

      selectMoreUsers: !!(
        uaTip === TYPE_ELEV ||
        uaTip === TYPE_PARINTE ||
        uaTip === TYPE_CADRU ||
        ((uaTip === TYPE_PERS_INST || uaTip === TYPE_ADMIN) &&
          schools &&
          schools.length === 1 &&
          schools[0].info.school)
      ),

      selectedPersons: [],
      firstStep: [
        {
          checked: false,
          value: 'g-elevi-all',
          name: 'elevi',
          label: `${capitalizeFirstLetter(t('common:text_students'))}`,
        },
        {
          checked: false,
          value: 'g-cadre-all',
          name: 'cadreFirstStep',
          label: `${capitalizeFirstLetter(t('common:text_profesori'))}`,
        },
        {
          checked: false,
          value: 'g-parinti-all',
          name: 'parinti',
          label: `${capitalizeFirstLetter(t('common:text_parents'))}`,
        },
        {
          checked: false,
          value: 'g-adservio',
          name: 'administrativ',
          label: `${t('common:text_admin_staff')}`,
        },
      ],
      administrativScolarStep: [
        // {
        //   checked: false,
        //   value: `g-cadre-cf-${PERM_CAD_DIRECTOR}`,
        //   name: 'cadreFct',
        //   label: `${capitalizeFirstLetter(t('common:text_director'))}`,
        //   cfNume: `${capitalizeFirstLetter(t('common:text_director'))}`,
        //   cfID: PERM_CAD_DIRECTOR,
        // },
        // {
        //   checked: false,
        //   value: `g-cadre-cf-${PERM_CAD_SECRETAR}`,
        //   name: 'cadreFct',
        //   label: `${capitalizeFirstLetter(t('common:text_secretar'))}`,
        //   cfID: PERM_CAD_SECRETAR,
        //   cfNume: `${capitalizeFirstLetter(t('common:text_secretar'))}`,
        // },
        // {
        //   checked: false,
        //   value: `g-cadre-cf-${PERM_CAD_AUX}`,
        //   name: 'cadreFct',
        //   label: `${capitalizeFirstLetter(t('common:text_pers_inst'))}`,
        //   cfID: PERM_CAD_AUX,
        //   cfNume: `${capitalizeFirstLetter(t('common:text_pers_inst'))}`,
        // },
      ],
    };
  }

  componentDidMount() {
    const { uaTip, schoolSession, schools } = this.props;
    const { selectMoreUsers } = this.state;
    // daca am o sg scoala fac update la id-ul liceului
    if ((uaTip === TYPE_ADMIN || uaTip === TYPE_PERS_INST) && selectMoreUsers) {
      if (schoolSession.liceuID !== schools[0].info.school.liceuID) {
        sessionSwitchLiceu(schools[0].scoala.liceuID);
      }
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    const { selectGroup, astID, roleID, matID, clID, clasa, materie, anStudiu } = this.state;
    const { clasaCurenta, restrictii, uaTip, anScolar } = this.props;

    if (!anScolar) return;

    if (
      (selectGroup !== prevState.selectGroup && selectGroup) ||
      prevState.selectGroup !== selectGroup ||
      clID !== prevState.clID ||
      astID !== prevState.astID ||
      matID !== prevState.matID
    ) {
      if (selectGroup === 'elevi' || selectGroup === 'parinti' || selectGroup === 'cadre') {
        let newData = [];
        let name = '';
        let label = '';

        if (+restrictii === 1) {
          await getAniStudiu().then((response) => {
            newData = response.data;
            if (newData) {
              newData.forEach((elm) => {
                const newElm = elm;
                switch (selectGroup) {
                  case 'elevi':
                    name = 'eAstGroup';
                    label = newElm.astNume;
                    break;
                  case 'parinti':
                    name = 'pAstGroup';
                    label = newElm.astNume;
                    break;
                  case 'cadre':
                    name = 'cAstGroup';
                    label = newElm.astNume;
                    break;
                  default:
                    break;
                }

                newElm.checked = false;
                newElm.value = `g-${selectGroup}-ast-${newElm.astID}`;
                newElm.name = name;
                newElm.label = label;
              });
            }

            return newData;
          });
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            aniStudiu: newData,
            loading: false,
          });
        } else if (+restrictii === 3) {
          if (uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) {
            newData = [
              {
                checked: false,
                name: '',
                value: `g-${selectGroup}-ast-${clasaCurenta.ast.astID}`,
                label: clasaCurenta.ast.astNume,
                astID: clasaCurenta.ast.astID,
                astNume: clasaCurenta.ast.astNume,
              },
            ];
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
              aniStudiu: newData,
              loading: false,
            });
          } else if (uaTip === TYPE_CADRU) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
              loading: true,
            });
            this.getCadreRepartizare().then((response) => {
              newData = this.extractAstRepartizare(response);

              if (newData) {
                newData.forEach((elm) => {
                  const newElm = elm;

                  newElm.checked = false;
                  newElm.value = `g-${selectGroup}-ast-${newElm.astID}`;
                  newElm.name = newElm.astNume;
                  newElm.label = newElm.astNume;
                });
              }

              // eslint-disable-next-line react/no-did-update-set-state
              this.setState({
                aniStudiu: newData,
                loading: false,
              });
            });
          }
        }
      }

      if (selectGroup === 'anStudiuElevi' || selectGroup === 'anStudiuParinti' || selectGroup === 'anStudiuCadre') {
        let newData = [];
        let name = '';
        let label = '';
        let value = '';

        if (+restrictii === 1) {
          await this.getClase(anStudiu.astID).then((response) => {
            newData = response.data;
            if (newData) {
              newData.forEach((elm) => {
                const newElm = elm;
                switch (selectGroup) {
                  case 'anStudiuElevi':
                    name = 'eClGroup';
                    label = newElm.clNume;
                    value = `g-elevi-cl-${newElm.clID}`;
                    break;
                  case 'anStudiuParinti':
                    name = 'pClGroup';
                    label = newElm.clNume;
                    value = `g-parinti-cl-${newElm.clID}`;
                    break;
                  case 'anStudiuCadre':
                    name = 'cClGroup';
                    label = newElm.clNume;
                    value = `g-cadre-cl-${newElm.clID}`;
                    break;
                  default:
                    break;
                }

                newElm.checked = false;
                newElm.value = value;
                newElm.name = name;
                newElm.label = label;
              });
            }

            return newData;
          });
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            clase: newData,
            loading: false,
          });
        } else if (+restrictii === 3) {
          if (uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) {
            newData = [
              {
                checked: false,
                name: '',
                value: `${
                  selectGroup === 'anStudiuElevi'
                    ? `g-elevi-cl-${clasaCurenta.clID}`
                    : `g-parinti-cl-${clasaCurenta.clID}`
                }`,
                label: clasaCurenta.clNume,
                clID: clasaCurenta.clID,
                clNume: clasaCurenta.clNume,
              },
            ];
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
              clase: newData,
              loading: false,
            });
          } else if (uaTip === TYPE_CADRU) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
              loading: true,
            });
            this.getCadreRepartizare().then((response) => {
              newData = this.extractClaseRepartizare(response);

              if (newData) {
                newData.forEach((elm) => {
                  const newElm = elm;

                  newElm.checked = false;
                  newElm.value = `${
                    selectGroup === 'anStudiuElevi' ? `g-elevi-ast-${elm.clID}` : `g-parinti-ast-${elm.clID}`
                  }`;
                  newElm.name = newElm.clNume;
                  newElm.label = newElm.clNume;
                });
              }

              this.setState({
                clase: newData,
                loading: false,
              });
            });
          }
        }
      }

      if (selectGroup === 'clasaAnStudiuElevi' || selectGroup === 'clasaAnStudiuParinti') {
        let newData = [];
        let name = '';
        let label = '';
        let value = '';
        await this.getElevi(anScolar.ascID, clasa.clID).then((response) => {
          newData = response;
          if (newData) {
            newData.forEach((elm) => {
              const newElm = elm;
              switch (selectGroup) {
                case 'clasaAnStudiuElevi':
                  name = 'ebGroup';
                  label = `${newElm.uaTip}-${newElm.elevID}`;
                  value = `${newElm.uaTip}-${newElm.elevID}`;
                  break;
                case 'clasaAnStudiuParinti':
                  name = 'pbGroup';
                  label = `${newElm.uaTip}-${newElm.elevID}`;
                  value = `${newElm.uaTip}-${newElm.elevID}`;
                  break;
                default:
                  break;
              }

              newElm.checked = false;
              newElm.value = value;
              newElm.name = name;
              newElm.label = label;
              newElm.clasa = clasa;
            });
          }

          return newData;
        });

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          elevi: newData,
          loading: false,
        });
      }

      if (selectGroup === 'clasaAnStudiuCadre') {
        let newData = [];
        let name = '';
        let label = '';
        let value = '';
        await this.getCadre(clasa.clID, anScolar.ascID, null, null).then((response) => {
          newData = response;
          if (newData) {
            newData.forEach((elm) => {
              const newElm = elm;
              name = 'cbGroup';
              label = `${newElm.uaTip}-${newElm.cadID}`;
              value = `${newElm.uaTip}-${newElm.cadID}`;

              newElm.checked = false;
              newElm.value = value;
              newElm.name = name;
              newElm.label = label;
            });
          }

          return newData;
        });

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          cadre: newData,
          loading: false,
        });
      }

      if (selectGroup === 'materieListaMaterii') {
        if (+restrictii === 1) {
          let newData = [];
          let name = '';
          let label = '';
          let value = '';
          await this.getCadre(null, anScolar.ascID, materie.matID, null).then((response) => {
            newData = response;
            if (newData) {
              newData.forEach((elm) => {
                const newElm = elm;
                name = 'cbGroup';
                label = `${newElm.uaTip}-${newElm.cadID}`;
                value = `${newElm.uaTip}-${newElm.cadID}`;

                newElm.checked = false;
                newElm.value = value;
                newElm.name = name;
                newElm.label = label;
              });
            }

            return newData;
          });

          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({
            cadre: newData,
            loading: false,
          });
        } else if (+restrictii === 3) {
          if (uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) {
            let newData = [];
            let name = '';
            let label = '';
            let value = '';
            await this.getCadre(clasaCurenta.clID, anScolar.ascID, materie.matID, null).then((response) => {
              newData = response;
              if (newData) {
                newData.forEach((elm) => {
                  const newElm = elm;
                  name = 'cbGroup';
                  label = `${newElm.uaTip}-${newElm.cadID}`;
                  value = `${newElm.uaTip}-${newElm.cadID}`;

                  newElm.checked = false;
                  newElm.value = value;
                  newElm.name = name;
                  newElm.label = label;
                });
              }

              return newData;
            });

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
              cadre: newData,
              loading: false,
            });
          } else if (uaTip === TYPE_CADRU) {
            let newData = [];
            let name = '';
            let label = '';
            let value = '';
            await this.getCadre(null, anScolar.ascID, materie.matID, null).then((response) => {
              newData = response;
              if (newData) {
                newData.forEach((elm) => {
                  const newElm = elm;
                  name = 'cbGroup';
                  label = `${newElm.uaTip}-${newElm.cadID}`;
                  value = `${newElm.uaTip}-${newElm.cadID}`;

                  newElm.checked = false;
                  newElm.value = value;
                  newElm.name = name;
                  newElm.label = label;
                });
              }

              return newData;
            });

            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({
              cadre: newData,
              loading: false,
            });
          }
        }
      }

      if (selectGroup === 'cadreRole') {
        let newData = [];
        let name = '';
        let label = '';
        let value = '';
        await this.getCadre(null, null, null, roleID).then((response) => {
          newData = response;
          if (newData) {
            newData.forEach((elm) => {
              const newElm = elm;
              name = 'cbGroup';
              label = `${newElm.uaTip}-${newElm.cadID}`;
              value = `${newElm.uaTip}-${newElm.cadID}`;

              newElm.checked = false;
              newElm.value = value;
              newElm.name = name;
              newElm.label = label;
            });
          }

          return newData;
        });

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          cadre: newData,
          loading: false,
        });
      }

      if (selectGroup === 'listaMaterii') {
        let newData = [];
        let name = '';
        let label = '';
        let value = '';
        await this.getMaterii(anScolar.ascID).then((response) => {
          newData = response;
          if (newData) {
            newData.forEach((elm) => {
              name = 'cMatGroup';
              label = `${elm.matNume}`;
              value = `g-cadre-mat-${elm.matID}`;

              const newElm = elm;
              newElm.checked = false;
              newElm.value = value;
              newElm.name = name;
              newElm.label = label;
            });
          }

          return newData;
        });

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          materii: newData,
          loading: false,
        });
      }

      if (selectGroup === 'administrativ') {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          loading: true,
        });
        await this.getRoleIsAdministrative().then((resp) => {
          this.setState({ administrativScolarStep: resp, loading: false });
        });
      }
    }
  }

  onSelectGroup = (val) => {
    this.setState({
      astID: null,
      clID: null,

      roleID: null,

      materii: null,
      aniStudiu: null,
      clase: null,
      elevi: null,
      cadre: null,
      matID: null,

      ...val,

      loading: true,
    });
    if (typeof val === 'object' && val?.selectGroup?.name) {
      this.setState({
        selectGroup: val.selectGroup.name,
      });
    }
    if (val?.selectGroup?.roleID) {
      this.setState({
        roleID: val.selectGroup.roleID,
        roleNume: val.selectGroup.label,
      });
    }
  };

  getClase = (astID) => {
    const { anScolar } = this.props;
    return Api2.get('/clase', {
      clAscID: anScolar.ascID,
      clAstID: astID,
    });
  };

  getMaterii = (ascID) => {
    return Api2.get('/materii', {
      cuNrClase: 1,
      order: '-matCountClase',
      ascID,
    }).then((response) => {
      const newMaterii = response.data;

      if (newMaterii) {
        newMaterii.forEach((resp, index) => {
          newMaterii[index].checked = false;
        });
      }

      return newMaterii;
    });
  };

  getCadre = (clID, ascID, matID, roleID) => {
    const { anStudiu } = this.state;
    let newAstID = null;

    if (roleID || matID) {
      newAstID = '';
    } else if (anStudiu) {
      newAstID = anStudiu.astID;
    }
    return Api2.get('/cadre', {
      astID: newAstID,
      ascID,
      matID,
      roleID,
      clID,
    }).then((response) => {
      const newCadre = response.data;
      if (newCadre) {
        newCadre.forEach((resp, index) => {
          newCadre[index].checked = false;
          newCadre[index].uaFirstName = resp.cadPrenume;
          newCadre[index].uaLastName = resp.cadNume;
          newCadre[index].uaSex = resp.cadSex;
          newCadre[index].prefAvatar = resp.prefAvatar;
          newCadre[index].uaTip = TYPE_CADRU;
          newCadre[index].role = [];
          resp.roles.forEach((role) => {
            newCadre[index].role.push(role.roleName);
          });

          newCadre[index].roles = newCadre[index].role;
        });
      }

      return newCadre;
    });
  };

  getElevi = (ascID, clID) => {
    const { selectGroup } = this.state;
    return Api2.get('/elevi', {
      ascID,
      clID,
      _limit: 999,
    }).then((response) => {
      const newElevi = response.data;
      if (newElevi) {
        newElevi.forEach((resp, index) => {
          newElevi[index].checked = false;
          newElevi[index].uaFirstName = resp.elevPrenume;
          newElevi[index].uaLastName = resp.elevNume;
          newElevi[index].uaSex = resp.elevSex;
          newElevi[index].prefAvatar = resp.prefAvatar;
          let newTip = 'type';
          if (selectGroup === 'clasaAnStudiuElevi') {
            newTip = TYPE_ELEV;
          }
          if (selectGroup === 'clasaAnStudiuParinti') {
            newTip = TYPE_PARINTE;
          }
          newElevi[index].uaTip = newTip;

          newElevi[index].uaID = resp.elevID;
        });
      }

      return newElevi;
    });
  };

  getCadreRepartizare = () => {
    const { anScolar, uaID } = this.props;
    return Api2.get('/cadre/repartizare', {
      cadID: uaID,
      ascID: anScolar.ascID,
    });
  };

  getRoleIsAdministrative = () => {
    return Api2.get('/roles', {
      roleIsAdministrative: 1,
    }).then((response) => {
      const newRoleIsAdministrative = response.data;
      if (newRoleIsAdministrative) {
        newRoleIsAdministrative.forEach((resp, index) => {
          newRoleIsAdministrative[index].checked = false;
          newRoleIsAdministrative[index].label = resp.roleName;
          newRoleIsAdministrative[index].roleNume = resp.roleName;
          newRoleIsAdministrative[index].roleID = resp.roleID;
          newRoleIsAdministrative[index].name = 'cadreRole';
          newRoleIsAdministrative[index].value = 'g-cadre-role';
        });
      }
      return newRoleIsAdministrative;
    });
  };

  extractAstRepartizare = (rapartizare) => {
    const newRep =
      rapartizare &&
      rapartizare.data.map((rep) => {
        return {
          astID: rep.cmcAst.astID,
          astNume: rep.cmcAst.astNume,
        };
      });

    return removeDuplicates(newRep, 'astID');
  };

  extractClaseRepartizare = (rapartizare) => {
    const newRep =
      rapartizare &&
      rapartizare.data.map((rep) => {
        return {
          clID: rep.cmcClasa.clID,
          clNume: rep.cmcClasa.clNume,
        };
      });

    return removeDuplicates(newRep, 'clID');
  };

  onAfterClose = () => {
    const { onAfterClose, onRequestClose } = this.props;
    const { selectedPersons } = this.state;
    if (typeof onAfterClose === 'function') onAfterClose(selectedPersons);
    onRequestClose();
  };

  itemSelected = (data) => {
    const { t } = this.props;

    const newData = [];

    if (data.isAllSelected) {
      newData.push({
        value: data.allValue.value,
        label: data.allValue.label,
        info: data.allValue.info || data.allValue,
      });
    } else {
      const checkedData = data?.checkList.filter((el) => el.checked === true);

      checkedData.forEach((el) => {
        newData.push({
          value: el.value,
          label: el.label,
          info:
            el.info ||
            (el?.matID
              ? {
                  ...el,
                  value: `g-cadre-mat-${el.matID}`,
                  label: `${t('common:text_toate_cadrele_materia_', { 0: `${el.label}` })} `,
                }
              : el),
        });
      });
    }

    this.setState({
      selectedPersons: newData,
    });
  };

  render() {
    const {
      clase,
      elevi,
      cadre,
      materii,
      aniStudiu,
      selectGroup,
      roleNume,
      clasa,
      anStudiu,
      materie,
      administrativScolarStep,
      loading,
      firstStep,
      selectMoreUsers,
    } = this.state;

    const { restrictii, uaTip, t, adsTeamOption, onRequestClose, restrictiiGrup } = this.props;

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Modal {...this.props} closeable color="blue" className="comp-select-group">
        {selectGroup == null && (
          <div className="select-group-style">
            <header>{t('select_group.utilizatori')}</header>

            <List
              checkList={firstStep}
              itemSelected={(data) => this.itemSelected(data)}
              onClickItem={(data) =>
                this.onSelectGroup({
                  selectGroup: data,
                })
              }
              singleValue
              restrictii={restrictii}
              uaTip={uaTip}
              adsTeamOption={adsTeamOption}
              selectMoreUsers={selectMoreUsers}
              restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
              firstStep
            />
          </div>
        )}

        {/* lista materie || an studiu */}
        {selectGroup === 'cadreFirstStep' && (
          <div className="select-group-style">
            <header>{t('select_group.select_teachers')}</header>

            <Breadcrumbs>
              <button type="button" onClick={() => this.onSelectGroup({ selectGroup: null })}>
                {t('select_group.categorii')}
              </button>
              <button type="button">{capitalizeFirstLetter(t('common:text_profesori'))}</button>
            </Breadcrumbs>

            <div className="content-select-group">
              <ul
                className={`select-group-style-list ${selectGroup === 'cadreFirstStep' ? 'select-group-center' : ''} `}
              >
                <li>
                  <span
                    className="select-info"
                    onClick={this.onSelectGroup.bind(this, { selectGroup: 'cadre' })}
                    role="presentation"
                  >
                    <span className="_icon icon-elevi icon-style" />
                    <span>{t('select_group.cadre_an_studiu')}</span>
                    <span className="_icon icon-sageata-dreapta" />
                  </span>
                </li>
                <li>
                  <span
                    className="select-info"
                    onClick={this.onSelectGroup.bind(this, { selectGroup: 'listaMaterii' })}
                    role="presentation"
                  >
                    <span className="_icon icon-elevi icon-style" />
                    <span>{t('select_group.cadre_materie')}</span>
                    <span className="_icon icon-sageata-dreapta" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}

        {selectGroup === 'administrativ' && (
          <div className="select-group-style">
            <header>{t('select_group.cadre_role')}</header>
            {loading && <Loader />}

            {!loading && (
              <>
                <Breadcrumbs>
                  <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </button>
                  <button type="button">{t('common:text_admin_staff')}</button>
                </Breadcrumbs>

                <div className="content-select-group">
                  <List
                    checkList={administrativScolarStep}
                    itemSelected={(data) => this.itemSelected(data)}
                    onClickItem={(data) =>
                      this.onSelectGroup({
                        selectGroup: data,
                      })
                    }
                    uaTip={uaTip}
                    singleValue
                    restrictii={restrictii}
                    selectMoreUsers={selectMoreUsers}
                  />
                </div>
              </>
            )}
          </div>
        )}

        {/* lista ani studiu  */}
        {(selectGroup === 'elevi' || selectGroup === 'parinti' || selectGroup === 'cadre') && (
          <div className="select-group-style">
            {selectGroup === 'elevi' && <header>{t('select_group.select_elevi')}</header>}
            {selectGroup === 'parinti' && <header>{t('select_group.select_parinti')}</header>}
            {selectGroup === 'cadre' && <header>{t('select_group.select_cadre')}</header>}
            {loading && <Loader />}

            {!loading && (
              <>
                <Breadcrumbs>
                  <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </button>

                  {selectGroup === 'elevi' && (
                    <button type="button">{capitalizeFirstLetter(t('common:text_students'))}</button>
                  )}

                  {selectGroup === 'parinti' && (
                    <button type="button">{capitalizeFirstLetter(t('common:text_parents'))}</button>
                  )}

                  {selectGroup === 'cadre' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'cadreFirstStep' })}>
                      {capitalizeFirstLetter(t('common:text_profesori'))}
                    </button>
                  )}

                  {selectGroup === 'cadre' && <button type="button">{t('select_group.an_studiu')}</button>}
                </Breadcrumbs>

                {aniStudiu && aniStudiu.length > 0 && (
                  <>
                    {selectGroup === 'elevi' && (
                      <List
                        checkList={aniStudiu}
                        itemSelected={(data) => this.itemSelected(data)}
                        allValue={{
                          value: 'g-elevi-all',
                          label: `${t('common:text_toti_elevii_scoala')}`,
                          info: { value: 'g-elevi-all' },
                        }}
                        onClickItem={(option) =>
                          this.onSelectGroup({
                            selectGroup: `anStudiu${selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                            anStudiu: option,
                          })
                        }
                        restrictii={restrictii}
                        uaTip={uaTip}
                        selectMoreUsers={selectMoreUsers}
                        restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                      />
                    )}
                    {selectGroup === 'parinti' && (
                      <List
                        checkList={aniStudiu}
                        itemSelected={(data) => this.itemSelected(data)}
                        allValue={{
                          value: 'g-parinti-all',
                          label: `${t('common:text_toti_parintii_scoala')}`,
                          info: { value: 'g-parinti-all' },
                        }}
                        onClickItem={(option) =>
                          this.onSelectGroup({
                            selectGroup: `anStudiu${selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                            anStudiu: option,
                          })
                        }
                        restrictii={restrictii}
                        uaTip={uaTip}
                        selectMoreUsers={selectMoreUsers}
                        restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                      />
                    )}
                    {selectGroup === 'cadre' && (
                      <List
                        checkList={aniStudiu}
                        itemSelected={(data) => this.itemSelected(data)}
                        allValue={{
                          value: 'g-cadre-all',
                          label: `${t('common:text_toate_cadrele')}`,
                          info: { value: 'g-cadre-all' },
                        }}
                        onClickItem={(option) =>
                          this.onSelectGroup({
                            selectGroup: `anStudiu${selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                            anStudiu: option,
                          })
                        }
                        restrictii={restrictii}
                        uaTip={uaTip}
                        selectMoreUsers={selectMoreUsers}
                        restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {/* lista clase */}
        {(selectGroup === 'anStudiuElevi' || selectGroup === 'anStudiuParinti' || selectGroup === 'anStudiuCadre') && (
          <div className="select-group-style">
            {selectGroup === 'anStudiuElevi' && (
              <header>{t('select_group.select_students_from_', { 0: `${anStudiu.astNume}` })}</header>
            )}
            {selectGroup === 'anStudiuParinti' && (
              <header>{t('select_group.select_parents_from_', { 0: `${anStudiu.astNume}` })}</header>
            )}
            {selectGroup === 'anStudiuCadre' && (
              <header>{t('select_group.select_teacher_from_', { 0: `${anStudiu.astNume}` })}</header>
            )}
            {loading && <Loader />}

            {!loading && (
              <>
                <Breadcrumbs>
                  <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </button>
                  {selectGroup === 'anStudiuElevi' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'elevi' })}>
                      {capitalizeFirstLetter(t('common:text_students'))}
                    </button>
                  )}

                  {selectGroup === 'anStudiuParinti' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'parinti' })}>
                      {capitalizeFirstLetter(t('common:text_parents'))}
                    </button>
                  )}

                  {selectGroup === 'anStudiuCadre' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'cadreFirstStep' })}>
                      {capitalizeFirstLetter(t('common:text_profesori'))}
                    </button>
                  )}

                  {selectGroup === 'anStudiuCadre' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'cadre' })}>
                      {t('select_group.an_studiu')}
                    </button>
                  )}

                  {(selectGroup === 'anStudiuElevi' ||
                    selectGroup === 'anStudiuParinti' ||
                    selectGroup === 'anStudiuCadre') && <button type="button">{anStudiu.astNume}</button>}
                </Breadcrumbs>

                {clase && clase.length > 0 && (
                  <>
                    {selectGroup === 'anStudiuElevi' && (
                      <List
                        checkList={clase}
                        itemSelected={(data) => this.itemSelected(data)}
                        allValue={{
                          value: `g-elevi-ast-${anStudiu.astID}`,
                          label: `${t('common:text_toti_elevii_din_', { 0: `${anStudiu.astNume}` })}`,
                          info: anStudiu,
                        }}
                        onClickItem={(option) =>
                          this.onSelectGroup({
                            selectGroup: `clasa${selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                            clasa: option,
                          })
                        }
                        restrictii={restrictii}
                        selectMoreUsers={selectMoreUsers}
                        restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                      />
                    )}

                    {selectGroup === 'anStudiuParinti' && (
                      <List
                        checkList={clase}
                        itemSelected={(data) => this.itemSelected(data)}
                        allValue={{
                          value: `g-parinti-ast-${anStudiu.astID}`,
                          label: `${t('common:text_toti_parintii_elevilor_', { 0: `${anStudiu.astNume}` })}`,
                          info: anStudiu,
                        }}
                        onClickItem={(option) =>
                          this.onSelectGroup({
                            selectGroup: `clasa${selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                            clasa: option,
                          })
                        }
                        restrictii={restrictii}
                        selectMoreUsers={selectMoreUsers}
                        restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                      />
                    )}

                    {selectGroup === 'anStudiuCadre' && (
                      <List
                        checkList={clase}
                        itemSelected={(data) => this.itemSelected(data)}
                        allValue={{
                          value: `g-cadre-ast-${anStudiu.astID}`,
                          label: `${t('common:text_toate_cadrele_nume_clasa_', { 0: `${anStudiu.astNume}` })}`,
                          info: anStudiu,
                        }}
                        onClickItem={(option) =>
                          this.onSelectGroup({
                            selectGroup: `clasa${selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                            clasa: option,
                          })
                        }
                        restrictii={restrictii}
                        selectMoreUsers={selectMoreUsers}
                        restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {/* lista materii */}
        {selectGroup === 'listaMaterii' && (
          <div className="select-group-style">
            <header>{t('common:text_toate_cadrele')}</header>
            {loading && <Loader />}

            {!loading && (
              <>
                <Breadcrumbs>
                  <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </button>
                  <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'cadreFirstStep' })}>
                    {capitalizeFirstLetter(t('common:text_profesori'))}
                  </button>
                  <button type="button" className="fcupper">
                    {capitalizeFirstLetter(t('common:text_materie'))}
                  </button>
                </Breadcrumbs>

                {materii && materii.length > 0 && (
                  <div className="select-group-style-list full-width">
                    <List
                      checkList={materii}
                      itemSelected={(data) => this.itemSelected(data)}
                      allValue={{
                        value: 'g-cadre-all',
                        label: `${t('common:text_toate_cadrele')}`,
                        info: { value: 'g-cadre-all' },
                      }}
                      onClickItem={(option) =>
                        this.onSelectGroup({
                          selectGroup: `materie${selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                          materie: option,
                        })
                      }
                      toggleItems
                      restrictii={restrictii}
                      selectMoreUsers={selectMoreUsers}
                      restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}

        {/* lista elevi || parinti || cadre */}
        {(selectGroup === 'clasaAnStudiuElevi' ||
          selectGroup === 'clasaAnStudiuParinti' ||
          selectGroup === 'clasaAnStudiuCadre' ||
          selectGroup === 'materieListaMaterii' ||
          selectGroup === 'cadreRole') && (
          <div className="select-group-style">
            {selectGroup === 'cadreRole' && (
              <header>{t('common:text_toate_cadrele_role_', { 0: `${roleNume}` })}</header>
            )}

            {selectGroup === 'materieListaMaterii' && (
              <header>{t('common:text_toate_cadrele_materia_', { 0: `${materie.matNume}` })}</header>
            )}

            {selectGroup === 'clasaAnStudiuElevi' && (
              <header>{t('common:text_toti_elevii_din_', { 0: `${clasa.clNume}` })}</header>
            )}
            {selectGroup === 'clasaAnStudiuParinti' && (
              <header>{t('common:text_toti_parintii_elevilor_', { 0: `${clasa.clNume}` })}</header>
            )}
            {selectGroup === 'clasaAnStudiuCadre' && (
              <header>{t('select_group.teacher_from_', { 0: `${clasa.clNume}` })}</header>
            )}
            {loading && <Loader />}

            {!loading && (
              <>
                <Breadcrumbs>
                  <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </button>

                  {selectGroup === 'clasaAnStudiuElevi' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'elevi' })}>
                      {capitalizeFirstLetter(t('common:text_students'))}
                    </button>
                  )}
                  {selectGroup === 'clasaAnStudiuElevi' && (
                    <button
                      type="button"
                      onClick={this.onSelectGroup.bind(this, { selectGroup: 'anStudiuElevi', anStudiu })}
                    >
                      {anStudiu.astNume}
                    </button>
                  )}

                  {selectGroup === 'clasaAnStudiuParinti' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'parinti' })}>
                      {capitalizeFirstLetter(t('common:text_parents'))}
                    </button>
                  )}
                  {selectGroup === 'clasaAnStudiuParinti' && (
                    <button
                      type="button"
                      onClick={this.onSelectGroup.bind(this, { selectGroup: 'anStudiuParinti', anStudiu })}
                    >
                      {anStudiu.astNume}
                    </button>
                  )}

                  {selectGroup === 'clasaAnStudiuCadre' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'cadreFirstStep' })}>
                      {capitalizeFirstLetter(t('common:text_profesori'))}
                    </button>
                  )}

                  {selectGroup === 'clasaAnStudiuCadre' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'cadre' })}>
                      {t('select_group.an_studiu')}
                    </button>
                  )}

                  {selectGroup === 'clasaAnStudiuCadre' && (
                    <button
                      type="button"
                      onClick={this.onSelectGroup.bind(this, { selectGroup: 'anStudiuCadre', anStudiu })}
                    >
                      {anStudiu.astNume}
                    </button>
                  )}

                  {(selectGroup === 'clasaAnStudiuElevi' ||
                    selectGroup === 'clasaAnStudiuParinti' ||
                    selectGroup === 'clasaAnStudiuCadre') && <button type="button">{clasa.clNume}</button>}

                  {selectGroup === 'materieListaMaterii' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'cadreFirstStep' })}>
                      {capitalizeFirstLetter(t('common:text_profesori'))}
                    </button>
                  )}
                  {selectGroup === 'materieListaMaterii' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'listaMaterii' })}>
                      {capitalizeFirstLetter(t('common:text_materie'))}
                    </button>
                  )}
                  {selectGroup === 'materieListaMaterii' && <button type="button">{materie.matNume}</button>}

                  {selectGroup === 'cadreRole' && (
                    <button type="button" onClick={this.onSelectGroup.bind(this, { selectGroup: 'administrativ' })}>
                      {t('common:text_admin_staff')}
                    </button>
                  )}
                  {selectGroup === 'cadreRole' && <button type="button">{roleNume}</button>}
                </Breadcrumbs>

                {/* Lista cadre  */}
                {(selectGroup === 'clasaAnStudiuCadre' ||
                  selectGroup === 'materieListaMaterii' ||
                  selectGroup === 'cadreRole') &&
                  cadre &&
                  cadre.length > 0 && (
                    <div className="select-group-style-list full-width">
                      {selectGroup === 'clasaAnStudiuCadre' && (
                        <List
                          checkList={cadre}
                          itemSelected={(data) => this.itemSelected(data)}
                          allValue={{
                            value: `g-cadre-cl-${clasa.clID}`,
                            label: `${t('common:text_toate_cadrele_nume_clasa_', { 0: `${clasa.clNume}` })}`,
                            info: clasa,
                          }}
                          person
                          restrictii={restrictii}
                          selectMoreUsers={selectMoreUsers}
                          restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                        />
                      )}

                      {selectGroup === 'materieListaMaterii' && (
                        <List
                          checkList={cadre}
                          itemSelected={(data) => this.itemSelected(data)}
                          allValue={{
                            value: `g-cadre-mat-${materie.matID}`,
                            label: `${t('common:text_toate_cadrele_materia_', { 0: `${materie.matNume}` })}`,
                            info: materie,
                          }}
                          person
                          restrictii={restrictii}
                          selectMoreUsers={selectMoreUsers}
                          restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                        />
                      )}
                      {selectGroup === 'cadreRole' && (
                        <List
                          checkList={cadre}
                          itemSelected={(data) => this.itemSelected(data)}
                          person
                          restrictii={restrictii}
                        />
                      )}
                    </div>
                  )}

                {/* Lista elevi || parinti  */}
                {(selectGroup === 'clasaAnStudiuElevi' || selectGroup === 'clasaAnStudiuParinti') &&
                  elevi &&
                  elevi.length > 0 && (
                    <>
                      {selectGroup === 'clasaAnStudiuElevi' && (
                        <List
                          checkList={elevi}
                          itemSelected={(data) => this.itemSelected(data)}
                          allValue={{
                            value: `g-elevi-cl-${clasa.clID}`,
                            label: `${t('common:text_toti_elevii_din_', { 0: `${clasa.clNume}` })}`,
                            info: clasa,
                          }}
                          person
                          restrictii={restrictii}
                          selectMoreUsers={selectMoreUsers}
                          restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                        />
                      )}

                      {selectGroup === 'clasaAnStudiuParinti' && (
                        <List
                          checkList={elevi}
                          itemSelected={(data) => this.itemSelected(data)}
                          allValue={{
                            value: `g-parinti-cl-${clasa.clID}`,
                            label: `${t('common:text_toti_parintii_elevilor_', { 0: `${clasa.clNume}` })}`,
                            info: clasa,
                          }}
                          person
                          restrictii={restrictii}
                          selectMoreUsers={selectMoreUsers}
                          restrictiiGrupAllItem={+restrictiiGrup === 1 || false}
                        />
                      )}
                    </>
                  )}
              </>
            )}
          </div>
        )}

        {/* empty state */}
        {(((selectGroup === 'elevi' || selectGroup === 'parinti' || selectGroup === 'cadre') &&
          anStudiu &&
          anStudiu.length === 0) ||
          ((selectGroup === 'anStudiuElevi' || selectGroup === 'anStudiuParinti' || selectGroup === 'anStudiuCadre') &&
            clase &&
            clase.length === 0) ||
          ((selectGroup === 'clasaAnStudiuElevi' || selectGroup === 'clasaAnStudiuParinti') &&
            elevi &&
            elevi.length === 0) ||
          ((selectGroup === 'clasaAnStudiuCadre' ||
            selectGroup === 'materieListaMaterii' ||
            selectGroup === 'cadreRole') &&
            cadre &&
            cadre.length === 0) ||
          (selectGroup === 'listaMaterii' && materie && materie.length === 0)) &&
          !loading && (
            <EmptyState image={missingMembers}>
              <p>{t('select_group.empty_state')}</p>
            </EmptyState>
          )}

        <footer>
          <Button color="ghost-orange" onClick={onRequestClose}>
            {t('common:text_cancel')}
          </Button>
          <Button onClick={() => this.onAfterClose()}> {t('common:text_add')} </Button>
        </footer>
      </Modal>
    );
  }
}

SelectGroup.propTypes = {
  uaTip: PropTypes.string,
  schools: PropTypes.instanceOf(Object),
  clasaCurenta: PropTypes.instanceOf(Object),
  elevID: PropTypes.string,
  schoolSession: PropTypes.instanceOf(Object),
  restrictii: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  anScolar: PropTypes.instanceOf(Object),
  t: PropTypes.func.isRequired,
  uaID: PropTypes.string.isRequired,
  onAfterClose: PropTypes.func,
  onRequestClose: PropTypes.func,

  restrictiiGrup: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  adsTeamOption: PropTypes.bool,
};

SelectGroup.defaultProps = {
  uaTip: '',
  schools: [],
  clasaCurenta: {},
  elevID: '',
  schoolSession: {},
  restrictii: '',
  restrictiiGrup: '',
  anScolar: {},
  onAfterClose: () => {},
  onRequestClose: () => {},
  adsTeamOption: false,
};

function getRestrictii(uaTip, cadPermisiuni, restrictii) {
  if (uaTip === TYPE_ELEV) return restrictii.restrictiiElevi;
  if (uaTip === TYPE_PARINTE) return restrictii.restrictiiParinti;
  if (uaTip === TYPE_CADRU) {
    // eslint-disable-next-line no-param-reassign
    cadPermisiuni = cadPermisiuni.map((perm) => perm.permID);
    if (cadPermisiuni.includes(PERM_CAD_DIRECTOR) || cadPermisiuni.includes(PERM_CAD_SECRETAR)) {
      return 1;
    }
    return restrictii.restrictiiCadre;
  }
  return 1;
}

function getRestrictiiGrup(uaTip, cadPermisiuni, restrictii) {
  if (uaTip === TYPE_ELEV) return restrictii.restrictiiGrupElevi;
  if (uaTip === TYPE_PARINTE) return restrictii.restrictiiGrupParinti;
  if (uaTip === TYPE_CADRU) {
    // eslint-disable-next-line no-param-reassign
    cadPermisiuni = cadPermisiuni.map((perm) => perm.permID);
    if (cadPermisiuni.includes(PERM_CAD_DIRECTOR) || cadPermisiuni.includes(PERM_CAD_SECRETAR)) return 1;
    return restrictii.restrictiiGrupCadre;
  }
  return 1;
}

const mapStateToProps = (state) => {
  return {
    schoolSession: state.session.school,
    clasaCurenta: getClasaCurenta(state),
    anScolar: state.session.anScolar,
    elevID: getCurrentElevID(state),
    uaTip: getUaTip(state),
    uaID: state.session.profile.uaID,
    cadru: state.session.cadru,
    isDirector: isDirector(state),
    isSecretar: isSecretar(state),
    isCadruDidactic: isCadruDidactic(state),
    restrictii: getRestrictii(
      getUaTip(state),
      state.session.profile.permisiuni ? state.session.profile.permisiuni : [],
      state.session.schoolConfig.mesaje
    ),
    restrictiiGrup: getRestrictiiGrup(
      getUaTip(state),
      state.session.profile.permisiuni ? state.session.profile.permisiuni : [],
      state.session.schoolConfig.mesaje
    ),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sessionSwitchLiceu: (liceuID) => {
      return dispatch(sessionSwitchLiceu(liceuID));
    },
  };
};

const ConnectedSelectGroup = connect(mapStateToProps, mapDispatchToProps)(SelectGroup);
export default withTranslation(['ComponentSelectGroup', 'common'])(ConnectedSelectGroup);
