const getGradeTypeName = (tipNota) => {
  switch (tipNota) {
    // oral
    case '1':
      return 'oral';
    // teza
    case '2':
      return 'teza';
    // corigenta
    case '3':
      return 'corigenta';
    // testare initiala
    case '4':
      return 'testare initiala';
    // examen specialitate
    case '5':
      return 'examen specialitate';
    // testare finala
    case '6':
      return 'testare finala';
    // test
    case '7':
      return 'test';
    // simulare
    case '8':
      return 'simulare';
    // proiect
    case '9':
      return 'proiect';
    default:
      return '';
  }
};

export default getGradeTypeName;
