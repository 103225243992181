import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

import queryString from 'query-string';

import { useHistory } from '../../../../Helpers';
import Preview from '../../../Overlays/Preview/Preview';
import Size from '../../../Helpers/Size/Size';
import FileIcon from '../FileIcon/FileIcon';
import { FSTIP_DIR, FSTIP_FILE, FSTIP_GROUP } from '../../../../constants';

import './Attachment.less';

const Attachment = (props) => {
  const { i18n } = useTranslation();

  const [showPreviewModal, setShowPreviewModal] = useState(false);
  const [filePreview, setFilePreview] = useState(false);
  const { location } = props;
  const history = useHistory();

  const query = queryString.parse(window?.location?.search);

  const { deleteButton, fileInfo, children } = props;

  const clearPreviewUrl = () => {
    if (location?.search) {
      delete query.previewFsID;
      const objectArray = Object.entries(query); // convert into array
      const newArr = [];
      objectArray.forEach(([key, value]) => {
        newArr.push(`${key}=${value}`);
      });
      history.push(window?.location?.pathname + (newArr.length ? '?' : '') + newArr.join('&'));
    } else {
      history.push(window?.location?.pathname);
    }
  };

  const previewPhoto = (file, preview) => {
    const hasCookiesAdsNative = !!Cookies.get('ads_native');

    if (`${file?.fsID}` === `${query?.previewFsID}` && !preview) {
      if (!hasCookiesAdsNative) {
        setShowPreviewModal(true);
        setFilePreview(file);
      }
    }

    if (preview) {
      history.push(
        window?.location?.pathname +
          (location?.search ? `${location?.search}&previewFsID=${file?.fsID}` : `?previewFsID=${file?.fsID}`)
      );
      if (!hasCookiesAdsNative) {
        setShowPreviewModal(true);
        setFilePreview(file);
      }
    }
  };

  const redirect = () => {
    if (fileInfo.fsTip === FSTIP_DIR && !fileInfo?.isAlbum) {
      history.push(`/${i18n.language}/files/${fileInfo.fsID}`);
    }
    if (fileInfo.isAlbum) {
      history.push({
        pathname: `/${i18n.language}/foto/album/${fileInfo.fsID}`,
        state: { detail: `${fileInfo.grpID}` },
      });
    }
  };

  useEffect(() => {
    if (query.previewFsID) {
      previewPhoto(fileInfo, false);
    }
  }, []);

  return (
    <>
      {showPreviewModal && (
        <Preview
          file={filePreview}
          width="100%"
          height="100%"
          onRequestClose={() => setShowPreviewModal(false)}
          onAfterClose={clearPreviewUrl}
        />
      )}
      <div className={`comp-attachment ${deleteButton ? 'deleteFs' : 'defaultAttach'} `}>
        <div className="style-preview">
          <span
            className={`modul_section_flex ${fileInfo?.fsTip !== FSTIP_DIR ? '' : 'center_flex'}`}
            onClick={() =>
              fileInfo.fsTip === FSTIP_FILE || fileInfo.fsTip === FSTIP_GROUP || fileInfo.fsExt !== null
                ? previewPhoto(fileInfo, true)
                : redirect()
            }
            onTouchStart={() =>
              fileInfo.fsTip === FSTIP_FILE || fileInfo.fsTip === FSTIP_GROUP
                ? previewPhoto(fileInfo, true)
                : redirect()
            }
            role="presentation"
          >
            <FileIcon fsTip={fileInfo?.fsTip} fsExt={fileInfo?.fsExt} file={fileInfo} />

            <span className={`fs-info ${deleteButton ? '' : 'fs-info-delete'}`}>
              <span className="fs-info-desc">{fileInfo?.fsNume}</span>
              {fileInfo?.fsTip !== FSTIP_DIR && (
                <small className="gray">
                  <Size fsSize={parseFloat(fileInfo?.fsSize)} />
                </small>
              )}
            </span>
          </span>
        </div>

        {children}
      </div>
    </>
  );
};

Attachment.propTypes = {
  /** File's details */
  fileInfo: PropTypes.shape({
    fsNume: PropTypes.string,
    fsSize: PropTypes.string,
    fsExt: PropTypes.string,
    fsID: PropTypes.string,
    fsTip: PropTypes.string,
    isAlbum: PropTypes.bool,
    grpID: PropTypes.string,
  }),
  /** Content to be shown */
  children: PropTypes.node,
  /** Show delete button */
  deleteButton: PropTypes.bool,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

Attachment.defaultProps = {
  fileInfo: {
    fsNume: '',
    fsSize: '',
    fsExt: '',
    fsID: '',
    isAlbum: false,
  },
  children: '',
  deleteButton: false,
  history: {
    push: () => {},
  },
  location: {
    search: '',
  },
};

export default Attachment;
