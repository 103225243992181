import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

require('intersection-observer');

const InfiniteScroll = (props) => {
  const { handleScroll } = props;
  const loader = useRef(null);

  const handleObserver = (entities) => {
    const target = entities[0];
    if (target.isIntersecting && typeof handleScroll === 'function') {
      handleScroll();
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      threshold: 1.0,
    };

    const observer = new IntersectionObserver((entities) => {
      handleObserver(entities);
    }, options);
    if (loader.current) {
      observer.observe(loader.current);
    }
  }, []);

  return <div ref={loader} />;
};

InfiniteScroll.propTypes = {
  handleScroll: PropTypes.func,
};

InfiniteScroll.defaultProps = {
  handleScroll: () => {},
};

export default InfiniteScroll;
