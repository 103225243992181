import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Parser from 'html-react-parser';

import { useTranslation } from 'react-i18next';
import buildLegacyUrl from '../../../../Modules/Site/helpers/build-legacy-url';
import { getUaTip } from '../../../../Redux/SessionHelper';
import EmptyState from '../EmptyState';

import './EmptyStateSubscription.less';

const EmptyStateSubscription = ({ uaTip }) => {
  const { t, i18n } = useTranslation(['Site', 'common', 'ComponentEmptyState']);
  const { language } = i18n;

  return (
    <div className="comp-empty-state-subscription">
      <EmptyState icon="icon-inchide-x">
        <p>{t('ComponentEmptyState:empty_state_subscription.title')}</p>
        <p>{t('ComponentEmptyState:empty_state_subscription.content_main')}</p>
        <p>{Parser(t('ComponentEmptyState:empty_state_subscription.content_second'))}</p>

        <a href={buildLegacyUrl(language, uaTip, 'subscription')}>
          {t('ComponentEmptyState:empty_state_subscription.content_cta')}
        </a>
      </EmptyState>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    uaTip: getUaTip(state),
  };
};

EmptyStateSubscription.propTypes = {
  uaTip: PropTypes.string,
};

EmptyStateSubscription.defaultProps = {
  uaTip: '',
};

export default connect(mapStateToProps)(EmptyStateSubscription);
