/* eslint-disable import/no-named-as-default */
/* eslint-disable react/jsx-props-no-spreading */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withTranslation, useTranslation } from 'react-i18next';

import Button from '../../Forms/SimpleButton/Button';
import Modal from '../Modal/Modal';

import './Confirmation.less';

class Confirmation extends Component {
  confirmHandler = () => {
    const { onConfirm, onRequestClose } = this.props;
    onConfirm();
    onRequestClose();
  };

  render() {
    const {
      t,
      overlay,
      className,
      width,
      position,
      children,
      onRequestClose,
      cancelBtn,
      confirmBtn,
      color,
    } = this.props;

    return (
      <Modal
        {...this.props}
        isCloseable
        overlay={overlay}
        className={className ? `reactModal-confirmation ${className}` : 'reactModal-confirmation'}
        width={width || '400px'}
        color={color}
        position={position || 'center-small'}
      >
        <div className="confirmation-modal">
          {children}

          <div className="modal-btns">
            <Button className="modal-btn-no" onClick={onRequestClose} color={`ghost-${color}`}>
              {cancelBtn || t('confirmation.cancel')}
            </Button>

            <Button className="modal-btn-yes" onClick={this.confirmHandler} color={color}>
              {confirmBtn || t('confirmation.delete')}
            </Button>
          </div>
        </div>
      </Modal>
    );
  }
}

export const HeaderConf = (props) => {
  const { children } = props;
  const { t } = useTranslation('ComponentConfirmation');
  return <div className="modal-header">{props && children ? children : t('confirmation.confirm_del')}</div>;
};

HeaderConf.propTypes = {
  children: PropTypes.element,
};

HeaderConf.defaultProps = {
  children: undefined,
};

Confirmation.propTypes = {
  /* A callback function that is called when the modal close */
  onRequestClose: PropTypes.func,
  /* A callback function that is called after the modal open */
  onAfterOpen: PropTypes.func,

  /* false if you don't whant it,  else className  */
  overlay: PropTypes.bool,
  /* add a classname for the overlay to be styled */
  overlayClassName: PropTypes.string,
  /* color of the left border */
  color: PropTypes.oneOf(['red', 'green', 'orange', 'blue', 'black']),
  /* position of the modal to one of the sides and animate it from that side. is true for center. */
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'top-center',
    'bottom-left',
    'bottom-right',
    'bottom-center',
    'center-left',
    'center-right',
    'center-small',
    'center',
  ]),
  /* the width of the modal */
  width: PropTypes.string,
  /* the height of the modal */
  height: PropTypes.string,
  /* the content of the modal */
  children: PropTypes.node,
  /* extra class for the modal is used for styling purposes */
  className: PropTypes.string,
  /* if you have the x icon for closing */
  isCloseable: PropTypes.bool,
  /* flag to see if the modal is visible */
  isVisible: PropTypes.bool,
  /* the confirmation callback when you click the button */
  onConfirm: PropTypes.func,
  cancelBtn: PropTypes.string,
  confirmBtn: PropTypes.string,
  t: PropTypes.func.isRequired,
};

Confirmation.defaultProps = {
  cancelBtn: '',
  confirmBtn: '',
  onConfirm: () => {},
  isVisible: true,
  isCloseable: false,
  className: '',
  children: undefined,
  height: '',
  width: '',
  overlayClassName: '',
  overlay: true,
  onAfterOpen: () => {},
  onRequestClose: () => {},
  position: 'center',
  color: 'red',
};

export default withTranslation('ComponentConfirmation')(Confirmation);
