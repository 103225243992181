/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import BrowseButton from './BrowseButton';
import UploadButton from './UploadButton';

const EVENTS = [
  'PostInit',
  'Browse',
  'Refresh',
  'StateChanged',
  'QueueChanged',
  'OptionChanged',
  'BeforeUpload',
  'UploadProgress',
  'FileFiltered',
  'FilesAdded',
  'FilesRemoved',
  'FileUploaded',
  'ChunkUploaded',
  'UploadComplete',
  'Destroy',
  'Error',
];

const checkUploader = () => window.plupload !== undefined;
class Plupload extends React.Component {
  constructor(props) {
    super(props);

    const { showList, buttonUpload } = this.props;

    this.id = new Date().valueOf();
    this.state = {
      files: [],
      progress: {},
      showList: showList === undefined ? false : showList,
      buttonUpload: buttonUpload !== undefined ? buttonUpload : false,
    };
    this.runUploader = this.runUploader.bind(this);
    this.getComponentId = this.getComponentId.bind(this);
    this.refresh = this.refresh.bind(this);
    this.initUploader = this.initUploader.bind(this);
    this.list = this.list.bind(this);
    this.clearAllFiles = this.clearAllFiles.bind(this);
    this.clearFailedFiles = this.clearFailedFiles.bind(this);
    this.removeFile = this.removeFile.bind(this);
    this.doUpload = this.doUpload.bind(this);
    this.container = null;
  }

  componentDidMount() {
    const self = this;
    if (checkUploader()) {
      setTimeout(() => {
        this.runUploader();
      }, 10);
    } else {
      setTimeout(() => {
        if (self.checkUploader()) {
          self.runUploader();
        }
      }, 500);
    }
  }

  componentDidUpdate() {
    if (checkUploader()) {
      this.refresh();
    }
  }

  getComponentId() {
    const { id } = this.props;
    return id || `react_plupload_${this.id}`;
  }

  filter = (array, check) => {
    const result = [];
    array.forEach((item, i) => {
      if (check && check(item, i)) {
        result.push(item);
      }
    });
    return result;
  };

  runUploader() {
    const self = this;
    this.initUploader();
    this.uploader.init();
    const { multi_selection } = self.props;
    EVENTS.forEach((event) => {
      const handler = self.props[`on${event}`];
      if (typeof handler === 'function') {
        self.uploader.bind(event, handler);
      }
    });

    // Put the selected files into the current state
    this.uploader.bind('FilesAdded', (up, files) => {
      if (multi_selection === false) {
        self.clearAllFiles();
      } else {
        self.clearFailedFiles();
      }

      const f = self.state.files;
      files.forEach((file) => {
        f.push(file);
      });
      self.setState({ files: f }, () => {
        if (self.props.autoUpload === true) {
          self.uploader.start();
        }
      });
    });

    this.uploader.bind('FilesRemoved', (up, rmFiles) => {
      const stateFiles = self.state.files;
      const files = this.filter(stateFiles, (file) => {
        const filterdremovefiles = this.filter(rmFiles, (rm) => {
          return rm.id !== file.id;
        });
        return filterdremovefiles.length === 0;
      });
      self.setState({ files });
    });

    this.uploader.bind('StateChanged', (up) => {
      if (up.state === window.plupload.STARTED && self.state.uploadState === false) {
        self.setState({ uploadState: true });
      }
      if (up.state !== window.plupload.STARTED && self.state.uploadState === true) {
        self.setState({ uploadState: false });
      }
    });

    this.uploader.bind('FileUploaded', (up, file) => {
      const stateFiles = self.state.files;
      stateFiles.forEach((val, key) => {
        if (val.id === file.id) {
          stateFiles[key] = { ...val, uploaded: true };
        }
      });
      self.setState({ files: stateFiles }, () => {
        self.removeFile(file.id);
      });
    });

    this.uploader.bind('Error', (up, err) => {
      if (err.file !== undefined) {
        const stateFiles = self.state.files;
        stateFiles.forEach((val, key) => {
          if (val.id === err.file.id) {
            stateFiles[key] = { ...val, error: err };
          }
        });
        self.setState({ files: stateFiles });
      }
    });

    this.uploader.bind('UploadProgress', (up, file) => {
      const { progress } = this.state;
      const stateProgress = progress;
      stateProgress[file.id] = file.percent;
      this.setState({ progress: stateProgress });
    });
  }

  refresh() {
    // Refresh to append events to buttons again.
    this.uploader?.refresh();
  }

  initUploader() {
    const { autoUpload } = this.state;
    const { id } = this.props;
    this.uploader = new window.plupload.Uploader({
      container: `plupload_${id}`,
      runtimes: 'html5',
      multipart: true,
      chunk_size: 0,
      browse_button: this.getComponentId(),
      url: '/upload',
      autoUpload,
      ...this.props,
    });
  }

  // Display selected files
  list() {
    const self = this;
    const { files } = this.state;
    return files.map((val) => {
      const removeFile = (e) => {
        e.preventDefault();
        self.removeFile(val.id);
      };
      let delButton = '';
      if (self.state.uploadState === false && val.uploaded !== true) {
        delButton = React.createElement(
          'button',
          { type: 'button', onClick: removeFile, className: 'pull-right' },
          'X'
        );
      }

      let progressBar = '';
      if (self.state.uploadState === true && val.uploaded !== true && val.error === undefined) {
        const percent = self.state.progress[val.id] || 0;
        progressBar = React.createElement(
          'div',
          { className: 'progress' },
          React.createElement(
            'div',
            {
              className: 'progress-bar',
              role: 'progressbar',
              'aria-valuenow': percent,
              'aria-valuemin': 0,
              'aria-valuemax': 100,
              style: { width: `${percent}%` },
            },
            React.createElement('span', { className: 'sr-only' }, `${percent}complete`)
          )
        );
      }

      let errorDiv = '';
      if (val.error !== undefined) {
        errorDiv = React.createElement(
          'div',
          { className: 'alert alert-danger' },
          `Error: ${val.error.code}, Message: ${val.error.message}`
        );
      }

      let bgSuccess = '';
      if (val.uploaded !== undefined) {
        bgSuccess = 'bg-success';
      }

      return React.createElement(
        'li',
        { key: val.id },
        React.createElement('p', { className: bgSuccess }, val.name, ' ', delButton),
        progressBar,
        errorDiv
      );
    });
  }

  clearAllFiles() {
    const { files } = this.state;
    files.forEach((file) => {
      this.uploader.removeFile(file.id);
    });

    this.setState({ files: [] });
  }

  clearFailedFiles() {
    const { files } = this.state;

    const state = this.filter(files, (file) => {
      if (file.error) {
        this.uploader.removeFile(file.id);
      }
      return !file.error;
    });

    this.setState({ files: state });
  }

  removeFile(id) {
    const { files } = this.state;
    this.uploader.removeFile(id);
    const state = this.filter(files, (file) => {
      return file.id !== id;
    });
    this.setState({ files: state });
  }

  doUpload(e) {
    e.preventDefault();
    this.uploader.start();
  }

  render() {
    const { buttonUpload, showList, files } = this.state;
    const {
      ladda,
      loading,
      attchmentButton,
      id,
      buttonSelect,
      pluploadIcon,
      processedPhoto,
      totalPhoto,
      progress,
    } = this.props;

    const propsSelect = {
      id: this.getComponentId(),
      type: 'button',
      content: buttonSelect || 'Browse',
    };

    const propsUpload = {
      onClick: this.doUpload,
      type: 'button',
      content: buttonUpload || 'Upload',
    };
    if (files.length === 0) propsUpload.disabled = 'disabled';

    const list = this.list();

    return (
      <div
        id={`plupload_${id}`}
        className="my-list"
        ref={(ref) => {
          this.container = ref;
        }}
      >
        <ul className="list-unstyled">{showList !== false && list}</ul>
        <BrowseButton
          ladda={ladda}
          loading={loading}
          progress={progress}
          className={`_icon ${attchmentButton ? `${pluploadIcon || 'icon-fisiere'} attch-button ` : ''} `}
          id={propsSelect.id}
          type={propsSelect.type}
          content={propsSelect.content}
          processedPhoto={processedPhoto}
          totalPhoto={totalPhoto}
        />

        {buttonUpload && (
          <UploadButton onClick={propsUpload.onClick} type={propsUpload.type} content={propsUpload.content} />
        )}
      </div>
    );
  }
}

Plupload.propTypes = {
  ladda: PropTypes.bool,
  loading: PropTypes.bool,
  attchmentButton: PropTypes.bool,
  pluploadIcon: PropTypes.string,
  processedPhoto: PropTypes.string,
  totalPhoto: PropTypes.number,
  id: PropTypes.string.isRequired,
  buttonSelect: PropTypes.string.isRequired,
  buttonUpload: PropTypes.string,
  showList: PropTypes.string,
  progress: PropTypes.number,
};

Plupload.defaultProps = {
  ladda: false,
  loading: false,
  showList: undefined,
  attchmentButton: undefined,
  pluploadIcon: undefined,
  processedPhoto: undefined,
  totalPhoto: undefined,
  buttonUpload: undefined,
  progress: 0,
};

export default Plupload;
