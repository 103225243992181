/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';

import './MeetingLogo.less';

const providers = ['adservio', 'zoom', 'webex', 'google', 'facebook', 'microsoft'];

const MeetingLogo = ({ providerID, onClick, full, size, disabled }) => {
  const provider = providers[providerID];
  const icon = require(`./assets/icon_${provider}.png`);
  let logo;

  if (full) {
    logo = require(`../../../../../images/${provider}_logo_2x.png`);
  }

  return (
    <span
      className={`comp-meeting-logo ${size} ${disabled ? 'disabled' : ''}`}
      onClick={onClick}
      onKeyDown={onClick}
      tabIndex={0}
      aria-label="icon"
      role="button"
    >
      <img src={icon} alt={icon} />
      {full && <img src={logo} alt="logo" />}
    </span>
  );
};

export default MeetingLogo;

MeetingLogo.propTypes = {
  providerID: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  /** Icon + Logo */
  full: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
};

MeetingLogo.defaultProps = {
  onClick: () => null,
  full: false,
  size: 'normal',
  disabled: false,
};
