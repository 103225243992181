export const checkSpaceForBottom = (props) => {
  const { triggerY, contentHeight, windowHeight, triggerHeight } = props;

  const hasBottomSpace = contentHeight < windowHeight - (triggerY + triggerHeight);

  return hasBottomSpace;
};

export const checkSpaceForTop = (props) => {
  const { triggerY, contentHeight } = props;

  const hasTopSpace = contentHeight < triggerY;

  return hasTopSpace;
};

export const checkSpaceForLeft = (props) => {
  const { triggerX, contentWidth, windowWidth } = props;

  const hasLeftSpace = triggerX + contentWidth < windowWidth;

  return hasLeftSpace;
};

export const checkSpaceForRight = (props) => {
  const { triggerX, contentWidth } = props;

  const hasRightSpace = contentWidth < triggerX;

  return hasRightSpace;
};

export const checkLeftSpaceForCenter = (props) => {
  const { triggerX, triggerWidth, contentWidth } = props;

  const hasLeftSpaceForCenter = contentWidth / 2 < triggerX + triggerWidth / 2;

  return hasLeftSpaceForCenter;
};

export const checkRightSpaceForCenter = (props) => {
  const { triggerX, contentWidth, windowWidth, triggerWidth } = props;

  const hasRightSpaceForCenter = contentWidth / 2 < windowWidth - (triggerX + triggerWidth / 2);

  return hasRightSpaceForCenter;
};
