import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { TYPE_ELEV, TYPE_PARINTE, TYPE_CADRU, TYPE_ADMIN, TYPE_PERS_INST } from '../../../constants';

import Tooltip from '../v2/Tooltip';

import './PersonTag.less';

const PersonTag = (props) => {
  const {
    person,
    showClass,
    showTip,
    tooltip,
    styleTag,
    isFlippable,
    selected,
    checkable,
    onClick,
    isDisabled,
    ellipsisWidth,
  } = props;
  const { t } = useTranslation('common');

  const getInfoPersonRoles = () => {
    const persRoles = [];
    if (person?.roles) {
      person?.roles.forEach((role) => {
        if (role && role.roleID) {
          persRoles.push(`${role.roleName}`);
        } else {
          persRoles.push(person.roles);
        }
      });
    }

    return `${persRoles.length > 0 ? `${persRoles.join(', ')}` : ''}`;
  };

  const getInfo = () => {
    return (
      <span>
        {(person.uaTip === TYPE_ELEV || person.uaTip === TYPE_CADRU) && (
          <>
            {showTip && <b>{getInfoPersonRoles()}</b>}
            {person.uaTip === TYPE_ELEV && showTip && showClass && ' - '}
            {/* Elev - nume clasa */}
            {person.uaTip === TYPE_ELEV && showClass && `${person?.clasa?.clNume}`}
            {/* Elev - nume an scolar */}
            {person.uaTip === TYPE_ELEV && showClass && person?.clasa?.ascIsActiv === 0 && ` ${person.clasa.ascNume}`}
          </>
        )}
        {person.uaTip === TYPE_PARINTE && (
          <>
            {showTip && (
              <b>
                {t('text_parent')} {person?.elev && `${person?.elev?.elevNume} ${person?.elev?.elevPrenume}`}
              </b>
            )}
            {showClass && person?.clasa && <span>{' - '}</span>}
            {showClass && `${person?.clasa?.clNume}`}
            {showClass && person?.clasa?.ascIsActiv === 0 && `${person.clasa.ascNume}`}
          </>
        )}
        {person.uaTip === TYPE_PERS_INST && `${person.institutie.instNume}`}
        {person.uaTip === TYPE_ADMIN && `${t('text_admin')}`}
      </span>
    );
  };

  const isCheckable = selected || checkable;
  const canBeFlippable = isCheckable || isFlippable;

  const renderImage = () => {
    return (
      <>
        {person.prefAvatar ? (
          <img src={person.prefAvatar} alt="" className="avatar" />
        ) : (
          <span className="_icon icon-profile-neutral-round avatar-icon" />
        )}
      </>
    );
  };

  return (
    <div
      className={`comp-person-tag ${isDisabled && 'comp-person-tag__disabled'} person-tag-${
        styleTag === undefined ? 'full' : styleTag
      } `}
    >
      <div className="comp-person-tag-share " onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
        <div className={`${canBeFlippable ? 'flip-box-inner' : ''} ${isCheckable ? 'checkable' : ''}`}>
          <span className={`avatar person-share ${tooltip === false ? 'withoutAvatar' : 'tooltip'} `}>
            {tooltip ? (
              <Tooltip hAlign="center" vAlign="top" worksOnClick>
                {renderImage()}
                <div>
                  {(!!person.uaFirstName || !!person.uaLastName) && (
                    <>
                      {`${person.uaLastName} ${person.uaFirstName}`}
                      <br />
                    </>
                  )}
                  {getInfo()}
                </div>
              </Tooltip>
            ) : (
              renderImage()
            )}
          </span>

          {!!canBeFlippable && (
            <div className="avatar person-share-back">
              <div className={`circle-blue  _icon icon-checked ${selected ? 'selected' : ''}  `} />
            </div>
          )}
        </div>
        <p className="info-left-side" style={{ width: ellipsisWidth || 'inherited' }}>
          {(!!person.uaFirstName || !!person.uaLastName) && (
            <span>{`${person.uaLastName} ${person.uaFirstName} `}</span>
          )}
          <span className="small">{getInfo()}</span>
        </p>
      </div>
    </div>
  );
};

PersonTag.propTypes = {
  /** Persons's details */
  person: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.shape({
      roles: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Object)])),
      uaTip: PropTypes.string,
      uaID: PropTypes.string,
      clNume: PropTypes.string,
      uaFirstName: PropTypes.string,
      uaLastName: PropTypes.string,
      prefAvatar: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
      elev: PropTypes.shape({
        elevNume: PropTypes.string,
        elevPrenume: PropTypes.string,
      }),
      clasa: PropTypes.shape({
        ascIsActiv: PropTypes.string,
        clNume: PropTypes.string,
        ascNume: PropTypes.string,
      }),
      institutie: PropTypes.shape({
        ascIsActiv: PropTypes.string,
        instNume: PropTypes.string,
      }),
    }),
  ]),
  /** Doar pentru styleTag=`"avatar`"
	La hover apare tooltipul cu informatii. */
  tooltip: PropTypes.bool,
  /** Atunci cand este false, nu se afiseaza clasa */
  showClass: PropTypes.bool,
  /** Atunci cand este false, nu se afiseaza tipul user-ului */
  showTip: PropTypes.bool,
  /** full - afiseaza avatarul + informatiile persoanei
	avatar - afiseaza doar avatarul, iar la hover apare un tooltip
	inline - afiseaza doar informatiile persoanei */
  styleTag: PropTypes.oneOf(['full', 'avatar', 'inline']),
  /** Daca se roteste la hover */
  isFlippable: PropTypes.bool,
  /** Daca este selectata */
  selected: PropTypes.bool,
  /** Daca este selectabila */
  checkable: PropTypes.bool,
  /** Apelata la click */
  onClick: PropTypes.func,
  isDisabled: PropTypes.bool,
  ellipsisWidth: PropTypes.number,
};

PersonTag.defaultProps = {
  person: {
    roles: [],
    uaID: '',
    uaTip: '',
    clNume: '',
    uaFirstName: '',
    uaLastName: '',
    prefAvatar: '',
    elev: {
      elevNume: '',
      elevPrenume: '',
    },
    clasa: {
      ascIsActiv: '',
      clNume: '',
      ascNume: '',
    },
    institutie: {
      ascIsActiv: '',
      instNume: '',
    },
  },
  styleTag: 'full',
  tooltip: true,
  showClass: true,
  showTip: true,
  isFlippable: false,
  selected: false,
  checkable: false,
  onClick: () => null,
  isDisabled: false,
  ellipsisWidth: 0,
};

export default PersonTag;
