/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';

import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import Select from 'react-select';

import { withTranslation } from 'react-i18next';

import Api2 from '../../../Libs/api';

import {
  getUaID,
  getUaTip,
  getClasaCurenta,
  getCurrentElevID,
  isDirector as getIsDirector,
  isSecretar as getIsSecretar,
} from '../../../Redux/SessionHelper';

import GroupTag from '../GroupTag/GroupTag';
import PersonTag from '../PersonTag/PersonTag';

import SelectGroup from '../../Overlays/SelectGroup/SelectGroup';

import {
  TYPE_ELEV,
  TYPE_PARINTE,
  TYPE_CADRU,
  TYPE_ADMIN,
  TYPE_PERS_INST,
  SN_NOTE,
  SN_CALIFICATIVE,
} from '../../../constants';

import './SelectPerson.less';

class SelectPerson extends Component {
  abortController = new AbortController();

  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      options: [], // optiunile afisate in select
      defaultOptions: [],
      value: value || {}, // valorile selectate

      loading: false,
      q: '',

      elevDiriginte: [], // repartizarile elevului cu dirigintie
    };
  }

  componentDidMount = () => {
    const { clasaCurenta, currentElevID, anScolar } = this.props;
    if (clasaCurenta) {
      this.setDefaultOptions();
    }
    if (currentElevID) {
      this.getElevDiriginte();
    }
    if (anScolar) {
      this.getCadruDiriginte();
    }
  };

  componentDidUpdate(prevProps) {
    const { anScolar, uaTip, currentElevID, clasaCurenta, value } = this.props;

    if (!anScolar) {
      return;
    }
    if (uaTip === TYPE_ELEV && !currentElevID) {
      return;
    }
    if (uaTip === TYPE_ELEV && !clasaCurenta) {
      return;
    }

    if (prevProps.value !== value) {
      this.clearStateQ();
    }

    if (prevProps.currentElevID !== currentElevID) {
      this.getElevDiriginte();
    }
    if (prevProps.anScolar !== anScolar) {
      this.getCadruDiriginte();
    }
    if (prevProps.clasaCurenta !== clasaCurenta) {
      this.setDefaultOptions();
    }

    if (value !== prevProps.value) {
      this.setValue(this.removeDuplicates(value, 'value'));
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  setValue(value) {
    this.setState({ value });
  }

  handleChange = (options) => {
    const { onChange } = this.props;
    const newOptions = [];

    if (options) {
      options.forEach((option) => {
        if (option.value) newOptions.push(option);
        if (option.info && option.info.value === 'generic') option.onClick();
      });
    }

    this.setState({ value: this.removeDuplicates(newOptions, 'value') });

    if (typeof onChange === 'function') onChange(newOptions);
  };

  removeDuplicates = (arr, comp) => {
    const unique = arr
      .map((e) => {
        return e[comp];
      })

      // store the keys of the unique objects
      .map((e, i, final) => {
        return final.indexOf(e) === i && i;
      })

      // eliminate the dead keys & store unique objects
      .filter((e) => {
        return arr[e];
      })
      .map((e) => {
        return arr[e];
      });

    return unique;
  };

  getCadruDiriginte = () => {
    const { uaTip, uaID, anScolar } = this.props;
    const newClasa = [];
    if (uaTip === TYPE_CADRU) {
      this.abortController.signal.onabort = () => {};
      Api2.get(
        '/cadre/repartizare',
        {
          cadID: uaID,
          ascID: anScolar.ascID,
        },
        {
          signal: this.abortController.signal,
        }
      )
        .then((response) => {
          if (this.abortController.signal.aborted === true) {
            const errorMessage = { name: 'AbortErrorMessageSend' };
            throw errorMessage;
          }
          response.data.forEach((el) => {
            if (el.cmcMaterie.matID === 100) {
              newClasa.push(el.cmcClasa);
            }
          });

          this.setState({
            cadruClasaDiriginte: newClasa,
          });

          this.setDefaultOptions();
        })
        .catch((error) => {
          if (error === 'AbortErrorMessageSend') {
            // console.log('AbortErrorMessageSend');
          }
        });
    }
  };

  getElevDiriginte = () => {
    const { uaTip, currentElevID } = this.props;
    const diriginte = [];
    if (uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) {
      this.abortController.signal.onabort = () => {};
      Api2.get(
        '/elevi/repartizare',
        {
          elevID: currentElevID,
        },
        {
          signal: this.abortController.signal,
        }
      )
        .then((response) => {
          if (this.abortController.signal.aborted === true) {
            const errorMessage = { name: 'AbortErrorMessageSend' };
            throw errorMessage;
          }
          response.forEach((item) => {
            if (item.cmcMaterie.matID === '100') {
              diriginte.push(item);
            }
          });

          this.setState({
            elevDiriginte: diriginte,
          });

          this.setDefaultOptions();
        })
        .catch((error) => {
          if (error === 'AbortErrorMessageSend') {
            // console.log('AbortErrorMessageSend');
          }
        });
    }
  };

  setDefaultOptions = () => {
    const { t, uaTip, isDirector, isSecretar, adsTeamOption } = this.props;
    const { elevDiriginte, cadruClasaDiriginte } = this.state;
    const defaultOptions = [
      {
        label: `${t('select_person_sugestii')}`,
        value: 'sugestii',
        disabled: 'yes',
      },
    ];

    if (uaTip === TYPE_ELEV || uaTip === TYPE_PARINTE) {
      defaultOptions.push(this.formatPersoaneClasa());
      if (elevDiriginte) {
        defaultOptions.push(this.formatDiriginte(elevDiriginte));
      }
    } else if (uaTip === TYPE_CADRU) {
      if (isDirector || isSecretar) {
        defaultOptions.push(this.formatAllTeachers());
        defaultOptions.push(this.formatAllStudents());
        defaultOptions.push(this.formatAllParents());
      } else if (cadruClasaDiriginte) {
        cadruClasaDiriginte.forEach((clasa) => {
          defaultOptions.push(this.formatPersoaneDiriginte(clasa, 'elev'));
          defaultOptions.push(this.formatPersoaneDiriginte(clasa, 'parinte'));
        });
      }
    }

    if (adsTeamOption) {
      defaultOptions.push(this.formatReprezentantAds());
    }

    defaultOptions.push(this.loadMoreOptions());

    if (defaultOptions.length) {
      this.setState({
        defaultOptions,
        options: defaultOptions,
      });
    }
  };

  formatPersoaneDiriginte = (clasa, type) => {
    let formatPersoaneDiriginte = {};
    const { t } = this.props;

    formatPersoaneDiriginte = {
      label:
        type === 'elev'
          ? t('common:text_elevii_din_', { 0: `${clasa.clNume}` })
          : t('common:text_parintii_elevilor_', { 0: `${clasa.clNume}` }),
      value: type === 'elev' ? `g-elevi-cl-${clasa.clID}` : `g-parinti-cl-${clasa.clID}`,
      info: {
        value: type === 'elev' ? 'elevii-clasei' : 'parintii-clasei',
        clID: clasa.clID,
        clNume: clasa.clNume,
        prefAvatar: type === 'elev' ? 'icon-elevi' : 'icon-parinti',
      },
    };

    return formatPersoaneDiriginte;
  };

  formatPersoaneClasa = () => {
    let formatPersoaneClasa = {};
    const { t, clasaCurenta, uaTip } = this.props;
    if (clasaCurenta) {
      formatPersoaneClasa = {
        label:
          uaTip === TYPE_ELEV
            ? t('common:text_elevii_din_', { 0: `${clasaCurenta.ast.astNume}` })
            : t('common:text_parintii_elevilor_', { 0: `${clasaCurenta.ast.astNume}` }),

        value: uaTip === TYPE_ELEV ? `g-elevi-cl-${clasaCurenta.clID}` : `g-parinti-cl-${clasaCurenta.clID}`,

        info: {
          value: uaTip === TYPE_ELEV ? 'colegii-clasei' : 'parintii-clasei',

          clID: clasaCurenta.clID,
          clNume: clasaCurenta.clNume,
          prefAvatar: uaTip === TYPE_ELEV ? 'icon-elevi' : 'icon-parinti',
        },
      };
    }

    return formatPersoaneClasa;
  };

  formatDiriginte = (diriginte) => {
    const { clasaCurenta, t } = this.props;
    let formatDiriginte = {};
    if (diriginte) {
      diriginte.forEach((item) => {
        formatDiriginte = {
          info: {
            value:
              item.cmcSistemNotare === SN_NOTE
                ? 'diriginte'
                : item.cmcSistemNotare === SN_CALIFICATIVE
                ? 'invatator'
                : 'educator',
            clNume: clasaCurenta.clNume,
            info: item.cmcCadru,
          },
          // info: item.cmcCadru,
          label:
            item.cmcSistemNotare === SN_NOTE
              ? t('common:text_diriginte')
              : item.cmcSistemNotare === SN_CALIFICATIVE
              ? t('common:text_invatator')
              : t('common:text_educator'),
          value: `${item.cmcCadru.uaTip}-${item.cmcCadru.uaID}`,
        };
      });
    }

    return formatDiriginte;
  };

  formatAllStudents = () => {
    let formatAllStudents = {};
    const { t } = this.props;
    formatAllStudents = {
      info: {
        value: 'g-elevi-all',
      },
      label: t('common:text_toti_elevii_scoala'),
      value: 'g-elevi-all',
    };

    return formatAllStudents;
  };

  formatAllParents = () => {
    let formatAllParents = {};
    const { t } = this.props;
    formatAllParents = {
      info: {
        value: 'g-parinti-all',
      },
      label: t('common:text_toti_parintii_scoala'),
      value: 'g-parinti-all',
    };

    return formatAllParents;
  };

  formatAllTeachers = () => {
    let formatAllTeachers = {};
    const { t } = this.props;
    formatAllTeachers = {
      info: {
        value: 'g-cadre-all',
      },
      label: t('common:text_toate_cadrele'),
      value: 'g-cadre-all',
    };

    return formatAllTeachers;
  };

  formatReprezentantAds = () => {
    let formatReprezentantAds = [];
    const { t } = this.props;
    formatReprezentantAds = {
      label: t('common:text_reprezentant_adservio'),
      value: 'g-adservio',
      info: {
        value: 'g-adservio',
      },
    };

    return formatReprezentantAds;
  };

  loadMoreOptions = () => {
    let loadMoreOptions = {};
    const { t } = this.props;
    loadMoreOptions = {
      info: {
        value: 'generic',
        icon: 'icon-more-bullets',
        title: `${t('select_person_more_options')}`,
        subTitle: `${t('new_select_person_share_grupuri')}`,
        extraIcon: 'icon-sageata-dreapta',
      },
      onClick: this.openSelectGroup,
    };
    return loadMoreOptions;
  };

  openSelectGroup = () => {
    this.setState({
      showSelectGroup: true,
    });
  };

  onRequestCloseSelectGroup = () => {
    this.setState({
      showSelectGroup: false,
    });
  };

  formatOptionLabel = (option) => {
    const { optionStyle, selectedStyle } = this.props;
    if (!option.info) {
      if (option.uaID) {
        return (
          <PersonTag
            key={option.uaID}
            person={option}
            styleTag={optionStyle || 'full'}
            selectedStyle={selectedStyle || 'full'}
            tooltip={false}
          />
        );
      }
      return <span>{option.label}</span>;
    }
    if (option.info.uaTip) {
      return (
        <PersonTag
          key={option.info.uaID}
          person={option.info}
          styleTag={optionStyle || 'full'}
          selectedStyle={selectedStyle || 'full'}
          tooltip={false}
        />
      );
    }
    return <GroupTag group={option.info} />;
  };

  searchInput = (q) => {
    const { schools, uaTip, uaID } = this.props;
    const { defaultOptions } = this.state;

    if (
      (schools &&
        schools.length === 1 &&
        schools[0].info.school &&
        (uaTip === TYPE_ADMIN || uaTip === TYPE_PERS_INST)) ||
      (uaTip !== TYPE_ADMIN && uaTip !== TYPE_PERS_INST)
    ) {
      if (q.length === 0) {
        this.setState({
          q,
          options: defaultOptions,
        });
      }

      if (q.length && q.length < 3) {
        this.setState({
          q,
          options: [],
        });
      }

      if (q.length > 2) {
        this.setState({
          loading: true,
          q,
        });
        this.abortController.signal.onabort = () => {};
        Api2.get(
          '/utilizatori/search',
          {
            // Api2.get(this.props.apiUrl, {
            uaID,
            q,
            uaTip: [1, 2, 3, 5],
            _limit: 20,
          },
          {
            signal: this.abortController.signal,
          }
        )
          .then((response) => {
            if (this.abortController.signal.aborted === true) {
              const errorMessage = { name: 'AbortErrorMessageSend' };
              throw errorMessage;
            }

            const listPeople = response.map((item) => ({
              label: `${item.uaFirstName} ${item.uaLastName}`,
              value: item.uaTip === TYPE_PARINTE ? `${item.uaTip}-${item.elev.elevID}` : `${item.uaTip}-${item.uaID}`,
              info: item,
            }));

            this.setState({
              options: listPeople,
              loading: false,
            });
          })
          .catch((error) => {
            if (error === 'AbortErrorMessageSend') {
              // console.log('AbortErrorMessageSend');
            }
          });
      }
    }
  };

  clearStateQ() {
    this.setState({ q: '' });
  }

  render() {
    const { loading, options, value, q, showSelectGroup } = this.state;
    const { selectedStyle, uaID, isMulti, selectIsDisabled, t, adsTeamOption, menuIsOpen, schools } = this.props;

    const customStyles = {
      control: (styles) => ({
        ...styles,
        border: 0,
        borderRadius: 0,
        boxShadow: 0,
        cursor: 'text',
        fontSize: 18,
      }),

      container: (styles) => {
        return {
          ...styles,
          zIndex: '2',
        };
      },

      option: (styles, state) => {
        return {
          ...styles,
          background: 'transparent',
          ':hover': {
            background: state.isDisabled ? 'transparent' : '#c9e9f2',
          },
          height: state.isDisabled ? '40px' : '',
          color: state.isDisabled ? '#888' : '',
          marginTop: state.isDisabled ? '-10px' : '',
          borderBottom: state.isDisabled ? '' : '1px solid #f4f4f4',
        };
      },

      menu: (styles) => ({
        ...styles,
        marginTop: '-1px',
        border: '1px solid #fff',
        borderTop: '0px',
        borderRadius: '0 0 3px 3px',
        boxShadow: '0px 3px 5px rgba(100,100,100, 0.1)',
      }),

      multiValue: (styles) => ({
        ...styles,
        background: '#06a7d3',
        color: '#fff',
      }),

      multiValueLabel: (styles) => ({
        ...styles,
        color: '#fff',
      }),

      placeholder: (styles) => ({
        ...styles,
        fontSize: 13,
        textAlign: 'center',
        width: '100%',
      }),
    };

    return (
      <div key={uaID} className="comp-select-person relative">
        {showSelectGroup && (
          <SelectGroup
            isCloseable
            onRequestClose={this.onRequestCloseSelectGroup}
            onAfterClose={(data) => {
              if (data) {
                this.handleChange([...value, ...data]);
              }
            }}
            adsTeamOption={adsTeamOption}
            schools={schools || null}
          />
        )}
        <Select
          key={uaID}
          className={` selected-style-${selectedStyle} `}
          formatOptionLabel={this.formatOptionLabel}
          id="destinatar"
          isLoading={loading}
          isMulti={isMulti !== false}
          isSearchable
          loadingMessage={() => {
            return t('common:wait_select');
          }}
          noOptionsMessage={() => {
            return q.length > 2 ? t('common:no_result_select') : t('common:add_characters_select');
          }}
          name="destinatar"
          onChange={this.handleChange}
          onInputChange={this.searchInput}
          isOptionDisabled={(option) => option.disabled || false}
          isDisabled={selectIsDisabled}
          options={options}
          placeholder={t('placeholder_serach_select_')}
          styles={customStyles}
          value={value}
          defaultMenuIsOpen={menuIsOpen}
        />
      </div>
    );
  }
}

SelectPerson.propTypes = {
  /** cum sunt stlizate optiunile: full, avatar, inline */
  optionStyle: PropTypes.oneOf(['full', 'avatar', 'inline']).isRequired,
  /** cum sunt stilizate valorile selectate */
  selectedStyle: PropTypes.oneOf(['full', 'avatar', 'inline']).isRequired,
  /** valoarea selectata */
  value: PropTypes.arrayOf(PropTypes.shape({})),
  /** functia de change */
  onChange: PropTypes.func,
  /** selectul este disabled */
  selectIsDisabled: PropTypes.bool,
  /** permite utilizatorului sa selecteze mai multe valori */
  isMulti: PropTypes.bool,
  /** user type */
  uaTip: PropTypes.string.isRequired,
  /** person ID */
  uaID: PropTypes.string.isRequired,
  /** ascCurent - an scolar curent */
  ascCurent: PropTypes.shape({}).isRequired,
  /** anScolar - an scolar */
  anScolar: PropTypes.shape({ ascID: PropTypes.string.isRequired }).isRequired,
  /** informatii cadru */
  cadru: PropTypes.shape({}),
  /** bool - verifica daca este director */
  isDirector: PropTypes.bool.isRequired,
  /** bool - verifica daca este secretar */
  isSecretar: PropTypes.bool.isRequired,
  /** informatii clasa curenta */
  /** ID-ul elevului curent */
  currentElevID: PropTypes.string,
  t: PropTypes.func.isRequired,
  clasaCurenta: PropTypes.shape({
    ast: PropTypes.shape({
      astNume: PropTypes.string,
    }),
    clID: PropTypes.string,
    clNume: PropTypes.string,
  }).isRequired,
  schools: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  adsTeamOption: PropTypes.bool,
  menuIsOpen: PropTypes.bool,
};

SelectPerson.defaultProps = {
  selectIsDisabled: false,
  isMulti: true,
  value: PropTypes.array,
  onChange: () => {},
  cadru: {},
  currentElevID: '',
  schools: [],
  adsTeamOption: true,
  menuIsOpen: false,
};

const mapStateToProps = (state) => {
  return {
    uaTip: getUaTip(state),
    uaID: getUaID(state),
    ascCurent: state.session.anScolar,
    anScolar: state.session.anScolar,
    cadru: state.session.cadru,
    isDirector: getIsDirector(state),
    isSecretar: getIsSecretar(state),
    clasaCurenta: getClasaCurenta(state),
    currentElevID: getCurrentElevID(state),
  };
};

const ConnectedSelectPerson = connect(mapStateToProps)(SelectPerson);
export default withTranslation(['ComponentSelectPerson', 'common'])(ConnectedSelectPerson);
