import React from 'react';

import PropTypes from 'prop-types';

import { Route, Redirect } from 'react-router-dom';

import hooks from './hooks';
import { useHistory } from '../../Helpers';

const RedirectHome = React.lazy(() => import('../../Modules/Site/components/redirect-home'));

const ProtectedRoute = ({
  component: Component,
  uaTip,
  enabled,
  permAny,
  permAll,
  subscription,
  redirect,
  computedMatch,
  location,
  path,
}) => {
  const { sessionUaTip, sessionPerm, sessionSubscription } = hooks();

  const handleRedirect = (redirectUrl) => {
    return redirectUrl ? <Redirect to={`${redirectUrl}`} /> : <RedirectHome />;
  };

  const routeHistory = useHistory();

  return (
    <Route
      computedMatch={computedMatch}
      location={location}
      path={path}
      render={({ location: routeLocation, match: routeMatch, staticContext: routeStaticContext }) => {
        if (!sessionUaTip || !enabled) {
          return <Redirect to="/" />;
        }

        if (uaTip) {
          return uaTip.includes(sessionUaTip) ? (
            <Component
              history={routeHistory}
              location={routeLocation}
              match={routeMatch}
              staticContext={routeStaticContext}
            />
          ) : (
            handleRedirect(redirect)
          );
        }

        if (permAny) {
          const permissions = [];

          permAny.forEach((permission) => {
            if (sessionPerm.includes(permission)) {
              permissions.push(permission);
            }
          });

          return permissions.length ? (
            <Component
              history={routeHistory}
              location={routeLocation}
              match={routeMatch}
              staticContext={routeStaticContext}
            />
          ) : (
            handleRedirect(redirect)
          );
        }

        if (permAll) {
          const missingPermissions = [];

          permAll.forEach((permission) => {
            if (!sessionPerm.includes(permission)) {
              missingPermissions.push(permission);
            }
          });

          return missingPermissions.length ? (
            handleRedirect(redirect)
          ) : (
            <Component
              history={routeHistory}
              location={routeLocation}
              match={routeMatch}
              staticContext={routeStaticContext}
            />
          );
        }

        if (subscription) {
          return subscription === sessionSubscription ? (
            <Component
              history={routeHistory}
              location={routeLocation}
              match={routeMatch}
              staticContext={routeStaticContext}
            />
          ) : (
            handleRedirect(redirect)
          );
        }

        return (
          <Component
            history={routeHistory}
            location={routeLocation}
            match={routeMatch}
            staticContext={routeStaticContext}
          />
        );
      }}
    />
  );
};

ProtectedRoute.defaultProps = {
  uaTip: undefined,
  enabled: true,
  permAny: undefined,
  permAll: undefined,
  subscription: undefined,
  redirect: undefined,
  computedMatch: undefined,
  location: undefined,
};

ProtectedRoute.propTypes = {
  component: PropTypes.instanceOf(Object).isRequired,
  path: PropTypes.string.isRequired,
  location: PropTypes.instanceOf(Object),
  computedMatch: PropTypes.instanceOf(Object),
  uaTip: PropTypes.instanceOf(Array),
  enabled: PropTypes.bool,
  permAny: PropTypes.instanceOf(Array),
  permAll: PropTypes.instanceOf(Array),
  subscription: PropTypes.number,
  redirect: PropTypes.string,
};

export default ProtectedRoute;
