import React from 'react';

import PropTypes from 'prop-types';

import Button from 'Components/Forms/SimpleButton/Button';

class UploadButton extends React.PureComponent {
  render() {
    const { className, id, content } = this.props;

    return (
      <Button className={`${className} `} type="button" haslink id={`${id}`}>
        {content}
      </Button>
    );
  }
}

UploadButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  content: PropTypes.string,
};

UploadButton.defaultProps = {
  className: '',
  id: '',
  content: '',
};

export default UploadButton;
