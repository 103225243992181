/**
 * Disabled rules for this file
 * There is too much business logic tied to refactoring
 * We need automatic tests for this, too much risk
 */
/* eslint-disable consistent-return */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-shadow */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable react/no-did-update-set-state */
/* eslint-disable import/no-named-as-default */
import React, { Component } from 'react';

import { connect } from 'react-redux';

import { withTranslation } from 'react-i18next';

import Api2 from '../../../Libs/api';

import { getUaTip } from '../../../Redux/SessionHelper';

import Breadcrumbs from '../../Navigation/Breadcrumbs/Breadcrumbs';
import Button from '../../Forms/SimpleButton/Button';
import Pagination from '../../Navigation/Pagination/Pagination';

import Loader from '../Loader/Loader/Loader';
import Modal from '../Modal/Modal';
import Link from '../../Content/Link';

import { TYPE_ADMIN } from '../../../constants';

import './SelectGroupSchools.less';

class SelectGroup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uaTip: null,
      selectGroup: null, // mat sau ast -> pt cadre || administrativ
      disabledBtn: true,
      loading: true,

      judete: null,
      orase: null,
      scoli: null,
      page: 1,
      totalCount: null,

      judeteGroup: {
        all: false,
        boxes: [],
      },
      scoliGroup: {
        all: false,
        boxes: [],
      },
      oraseGroup: {
        all: false,
        boxes: [],
      },
      contracteGroup: {
        all: false,
        boxes: [],
      },
      schools: [],
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { selectGroup, schools, page, judet, contract } = this.state;
    const { anScolar } = this.props;

    if (!anScolar) return;

    if (prevState.schools !== schools) {
      this.setState({
        disabledBtn: !(schools.length > 0),
      });
    }

    if (
      (selectGroup !== prevState.selectGroup && selectGroup) ||
      prevState.selectGroup !== selectGroup ||
      prevState.page !== page
    ) {
      if (selectGroup === 'zona') {
        this.getJudete().then((response) => {
          this.setState({
            judete: response.data,
            loading: false,
          });
        });
      }

      if (selectGroup === 'judetZona') {
        this.getOrase(judet.judID).then((response) => {
          this.setState({
            orase: response.data,
            loading: false,
          });
        });
      }

      if (selectGroup === 'orasJudetZona') {
        const { oras } = this.state;
        this.getScoli(oras.liceuOras, judet.judID, null).then((response) => {
          const scoli = response.data;

          scoli &&
            scoli.map((_resp, index) => {
              scoli[index].checked = false;
            });

          this.setState({
            scoli,
            loading: false,
            totalCount: response.meta.countTotal,
          });
        });
      }

      if (selectGroup === 'contract') {
        this.getContracte().then((response) => {
          this.setState({
            contracte: response.data,
            loading: false,
          });
        });
      }

      if (selectGroup === 'tipContract') {
        this.getScoli(null, null, contract.tctrID).then((response) => {
          const scoli = response.data;

          scoli &&
            scoli.map((resp, index) => {
              scoli[index].checked = false;
            });
          this.setState({
            scoli,
            loading: false,
            totalCount: response.meta.countTotal,
          });
        });
      }
    }
  }

  onSelectGroup = (val) => {
    this.setState({
      judete: null,
      orase: null,
      scoli: null,
      ...val,

      loading: true,
    });
  };

  getJudete = () => {
    return Api2.get('/scoli/grup_judete', {
      taraID: 1,
    });
  };

  getOrase = (judID) => {
    return Api2.get('/scoli/grup_orase', {
      judID,
    });
  };

  getScoli = (liceuOras, judID, liceuTipContract) => {
    const { page } = this.state;
    return Api2.get('/scoli', {
      liceuOras,
      liceuJudetID: judID,
      liceuTipContract,
      _offset: (page - 1) * 20,
      _limit: 20,
    });
  };

  getContracte = () => {
    return Api2.get('/scoli/grup_contracte', {
      taraID: 1,
    });
  };

  onCheckBoxChange = (groupName, index, item, e) => {
    const groupData = this.state[groupName];
    let setGroupName;
    const { value } = e.target;
    if (groupName == 'judeteGroup') {
      setGroupName = this.state.judete;
    } else if (groupName == 'scoliGroup') {
      setGroupName = this.state.scoli;
    } else if (groupName == 'oraseGroup') {
      setGroupName = this.state.orase;
    } else if (groupName == 'contracteGroup') {
      setGroupName = this.state.contracte;
    }

    if (index == 'all') {
      item.value = value;

      if (groupData.boxes.length) {
        groupData.boxes.map(() => {
          return !groupData.all;
        });
      }

      groupData.all = !groupData.all;

      if (groupData.all == true) {
        groupData.boxes = [];

        setGroupName &&
          setGroupName.map(() => {
            groupData.boxes.push(true);
          });
      } else if (groupData.all == false) {
        groupData.boxes = [];

        setGroupName &&
          setGroupName.map(() => {
            groupData.boxes.push(false);
          });
      }

      if (groupData.all) {
        this.setState({
          [groupName]: groupData,
          schools: [...this.state.schools, item],
        });
      } else {
        // remove element - disabled
        const onlyElmCheked = this.state.schools.filter((elm) => elm.value !== value);

        this.setState({
          [groupName]: groupData,
          schools: onlyElmCheked,
          groupName,
        });
      }
    } else {
      item.value = e.target.value;
      let newItem;

      if (groupData.boxes) {
        groupData.boxes[index] = !groupData.boxes[index];
        if (item.judet) {
          this.state.judete[index].checked = groupData.boxes[index];
        } else if (item.oras) {
          this.state.orase[index].checked = groupData.boxes[index];
        } else if (item.scoala) {
          this.state.scoli[index].checked = groupData.boxes[index];
        } else if (item.contract) {
          this.state.contracte[index].checked = groupData.boxes[index];
        }
      }

      if (
        item.label.checked ||
        (item.judet && item.judet.checked) ||
        (item.oras && item.oras.checked) ||
        (item.scoala && item.scoala.checked) ||
        (item.contract && item.contract.checked)
      ) {
        newItem = [...this.state.schools];
        newItem.push(item);
      } else {
        newItem = [...this.state.schools];
        newItem.filter((nItem, index) => {
          if (nItem.value == item.value) newItem.splice(index, 1);
        });
      }

      this.setState({
        [groupName]: groupData,
        schools: newItem,
      });
    }

    // if( groupData.boxes.length ) groupData.boxes[index] = !groupData.boxes[index];

    // verificam daca s-au bifat toate sau s-aud debifat toate
    let isEqual = false;

    if (groupData.boxes && setGroupName) {
      for (let i = 0; i < groupData.boxes.length; i++) {
        if (
          !groupData.boxes.includes(false) &&
          !groupData.boxes.includes(undefined) &&
          groupData.boxes[i] == true &&
          groupData.boxes.length == setGroupName.length
        ) {
          isEqual = true;

          if (isEqual == true && groupData.all == false) {
            groupData.all = true;

            return this.setState({
              [groupName]: groupData,
              schools: [...this.state.schools, item],
            });
          }
        } else {
          isEqual = false;

          if (isEqual == false && groupData.all == true) {
            groupData.all = false;
            return this.setState({
              [groupName]: groupData,
              schools: [...this.state.schools, item],
            });
          }
        }
      }
    }
  };

  onAfterClose = (data) => {
    // remove duplicate
    data = this.removeDuplicates(this.state.schools, 'value');
    if (typeof this.props.onAfterClose === 'function') this.props.onAfterClose(data);
    this.props.onRequestClose();
  };

  removeDuplicates = (arr, comp) => {
    const unique = arr
      .map((e) => {
        return e[comp];
      })

      // store the keys of the unique objects
      .map((e, i, final) => {
        return final.indexOf(e) === i && i;
      })

      // eliminate the dead keys & store unique objects
      .filter((e) => {
        return arr[e];
      })
      .map((e) => {
        return arr[e];
      });

    return unique;
  };

  handleChange = (name, val) => {
    this.setState({
      [name]: val,
    });
  };

  render() {
    const {
      selectGroup,
      judeteGroup,
      oraseGroup,
      scoliGroup,
      contracteGroup,
      judete,
      judet,
      orase,
      oras,
      scoli,
      contracte,
      contract,
      page,
      totalCount,
    } = this.state;

    const { uaTip, t } = this.props;

    return (
      <Modal {...this.props} closeable color="blue" className="comp-select-group comp-select-group-schools">
        {selectGroup == null && (
          <div className="select-group-style">
            <header>Selectează categoria de utilizator</header>

            <div className="content-select-group">
              <ul className="select-group-style-list select-user-type select-school-type">
                {uaTip == TYPE_ADMIN && (
                  <li>
                    <span className="select-info" onClick={this.onSelectGroup.bind(this, { selectGroup: 'contract' })}>
                      <span className="_icon icon-fisascolara icon-style" />
                      <span>Tip contract</span>
                      <span className="_icon icon-sageata-dreapta" />
                    </span>
                  </li>
                )}
                <li>
                  <span className="select-info" onClick={this.onSelectGroup.bind(this, { selectGroup: 'zona' })}>
                    <span className="_icon icon-pin icon-style" />
                    <span>Zona geografica</span>
                    <span className="_icon icon-sageata-dreapta" />
                  </span>
                </li>
              </ul>
            </div>
          </div>
        )}

        {/* lista judete */}
        {selectGroup == 'zona' && (
          <div className="select-group-style">
            <header>Selectează judetul</header>
            {this.state.loading && <Loader />}

            {!this.state.loading && (
              <>
                <Breadcrumbs>
                  <Link to="#" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </Link>
                  <Link to="#">Zonă geografică</Link>
                </Breadcrumbs>

                {judete && judete.length > 0 && (
                  <>
                    <div className="select-group-style-list full-width">
                      <input
                        type="checkbox"
                        id="allJudID"
                        name="all[]"
                        value="g-scoli-all"
                        checked={judeteGroup.all ? judeteGroup.all : false}
                        onChange={this.onCheckBoxChange.bind(this, 'judeteGroup', 'all', {
                          label: `${t('select_group.text_toate_scolile')}`,
                          value: 'g-scoli-all',
                          info: { value: 'g-scoli-all', type: 'all' },
                        })}
                      />
                      <label htmlFor="allJudID">{t('select_group.text_toate_scolile')}</label>

                      <p className="select-group-schools-subtitle">{t('select_group.text_scoli_judete')}</p>
                    </div>

                    <ul className="select-group-style-list">
                      {judete &&
                        judete.map((judet, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                id={judet.judID}
                                value={`g-judet-${judet.judID}`}
                                checked={judeteGroup.boxes[index] ? judeteGroup.boxes[index] : false}
                                onChange={this.onCheckBoxChange.bind(this, 'judeteGroup', index, {
                                  label: `${t('common:text_toti_elevii_din_', `${judet.judNume}`)}`,
                                  value: `g-judet-${judet.judID}`,
                                  info: {
                                    value: `g-judet-${judet.judID}`,
                                    judID: judet.judID,
                                    judNume: judet.judNume,
                                    type: 'judet',
                                  },
                                  judet,
                                })}
                              />

                              <label
                                htmlFor={judet.judID}
                                className="select-info"
                                onClick={this.onSelectGroup.bind(this, {
                                  selectGroup: `judet${this.state.selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                                  judet,
                                })}
                              >
                                {judet.judNume}
                                <span className="select-info-bull">{judet.countScoli}</span>
                                <span className="_icon icon-sageata-dreapta" />
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {/* lista orase */}
        {selectGroup == 'judetZona' && (
          <div className="select-group-style">
            <header>Selectează orașul</header>
            {this.state.loading && <Loader />}

            {!this.state.loading && (
              <>
                <Breadcrumbs>
                  <Link to="#" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </Link>
                  <Link to="#" onClick={this.onSelectGroup.bind(this, { selectGroup: 'zona' })}>
                    {t('select_group.zona_geo')}
                  </Link>
                  <Link to="#">{judet.judNume}</Link>
                </Breadcrumbs>

                {orase && orase.length > 0 && (
                  <>
                    <div className="select-group-style-list full-width">
                      <input
                        type="checkbox"
                        id="allSchoolTown"
                        name="all[]"
                        value={`g-judet-${judet.judID}`}
                        checked={oraseGroup.all ? oraseGroup.all : false}
                        onChange={this.onCheckBoxChange.bind(this, 'oraseGroup', 'all', {
                          label: `${t('common:text_toate_scoli_judet_', `${judet.judNume}`)} `,
                          value: `g-judet-${judet.judID}`,
                          info: {
                            value: `g-judet-${judet.judID}`,
                            judID: `${judet.judID}`,
                            judNume: `${judet.judNume}`,
                            type: 'judet',
                          },
                          judID: `${judet.judID}`,
                          orasNume: `${judet.judNume}`,
                        })}
                      />
                      <label htmlFor="allSchoolTown">{t('common:text_toate_scoli_judet_', `${judet.judNume}`)}</label>

                      <p className="select-group-schools-subtitle">{t('select_group.text_scoli_orase')}</p>
                    </div>

                    <ul className="select-group-style-list">
                      {orase &&
                        orase.map((oras, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                value={`g-oras-${oras.liceuOras}`}
                                checked={oraseGroup.boxes[index] ? oraseGroup.boxes[index] : false}
                                onChange={this.onCheckBoxChange.bind(this, 'oraseGroup', index, {
                                  label: `${t('common:text_toate_scoli_oras_', `${oras.liceuOras}`)} `,
                                  value: `g-oras-${oras.liceuOras}`,
                                  info: {
                                    value: `g-oras-${oras.liceuOras}`,
                                    orasNume: `${oras.liceuOras}`,
                                    judID: `${judet.judID}`,
                                    type: 'oras',
                                  },
                                  oras,
                                })}
                              />

                              <label
                                className="select-info"
                                onClick={this.onSelectGroup.bind(this, {
                                  selectGroup: `oras${this.state.selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                                  oras,
                                })}
                              >
                                {oras.liceuOras}
                                <span className="select-info-bull">{oras.countScoli}</span>
                                <span className="_icon icon-sageata-dreapta" />
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        )}

        {/* lista scoli */}
        {(selectGroup == 'orasJudetZona' || selectGroup == 'tipContract') && (
          <div className="select-group-style">
            <header>Selectează școala</header>
            {this.state.loading && <Loader />}

            {!this.state.loading && (
              <>
                <Breadcrumbs>
                  <Link to="#" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </Link>
                  {selectGroup == 'orasJudetZona' && (
                    <Link to="#" onClick={this.onSelectGroup.bind(this, { selectGroup: 'zona' })}>
                      {t('select_group.zona_geo')}
                    </Link>
                  )}
                  {selectGroup == 'orasJudetZona' && (
                    <Link to="#" onClick={this.onSelectGroup.bind(this, { selectGroup: 'judetZona' })}>
                      {judet.judNume}
                    </Link>
                  )}
                  {selectGroup == 'orasJudetZona' && <Link to="#">{oras.liceuOras}</Link>}

                  {selectGroup == 'tipContract' && (
                    <Link to="#" onClick={this.onSelectGroup.bind(this, { selectGroup: 'contract' })}>
                      Tip contract
                    </Link>
                  )}
                  {selectGroup == 'tipContract' && <Link to="#">{contract.tctrNume}</Link>}
                </Breadcrumbs>

                {scoli && scoli.length > 0 && (
                  <>
                    <div className="select-group-style-list full-width">
                      {selectGroup == 'orasJudetZona' && (
                        <>
                          <input
                            type="checkbox"
                            id="allOrasID"
                            name="all[]"
                            value={`g-oras-${oras.liceuOras}`}
                            checked={scoliGroup.all ? scoliGroup.all : false}
                            onChange={this.onCheckBoxChange.bind(this, 'scoliGroup', 'all', {
                              label: `${t('select_group.text_toate_scolile')}`,
                              value: `g-oras-${oras.liceuOras}`,
                              info: {
                                value: `g-oras-${oras.liceuOras}`,
                                orasNume: `${oras.liceuOras}`,
                                type: 'oras',
                              },
                            })}
                          />

                          <label htmlFor="allOrasID">{t('common:text_toate_scoli_oras_', `${oras.liceuOras}`)}</label>
                        </>
                      )}

                      {selectGroup == 'tipContract' && (
                        <>
                          <input
                            type="checkbox"
                            id="allOrasID"
                            name="all[]"
                            value={`g-contract-${contract.tctrID}`}
                            checked={scoliGroup.all ? scoliGroup.all : false}
                            onChange={this.onCheckBoxChange.bind(this, 'scoliGroup', 'all', {
                              label: `${t('select_group.text_toate_scolile')}`,
                              value: `g-contract-${contract.tctrID}`,
                              info: {
                                value: `g-contract-${contract.tctrID}`,
                                tctrID: contract.tctrID,
                                tctrNume: contract.tctrNume,
                                type: 'contract',
                              },
                            })}
                          />
                          {t('common:text_toate_scoli_contract_', `${contract.tctrNume}`)}
                        </>
                      )}
                    </div>

                    <ul className="select-group-style-list select-group-margin-top">
                      {scoli &&
                        scoli.map((scoala, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                id={scoala.liceuID}
                                value={`${scoala.liceuID}`}
                                checked={scoliGroup.boxes[index] ? scoliGroup.boxes[index] : false}
                                onChange={this.onCheckBoxChange.bind(this, 'scoliGroup', index, {
                                  label: `${`${scoala.liceuClasificare} ${scoala.liceuNume}${scoala.liceuAdresa.adrJudet.judNume} ${scoala.liceuAdresa.adrLocalitate}`}`,
                                  value: `${scoala.liceuID}`,
                                  info: {
                                    value: `g-scoala-${scoala.liceuID}`,
                                    school: scoala,
                                    type: 'scoala',
                                  },
                                  scoala,
                                })}
                              />

                              <label htmlFor={scoala.liceuID}>
                                {`${scoala.liceuClasificare} ${scoala.liceuNume}, ${scoala.liceuAdresa.adrJudet.judNume}, ${scoala.liceuAdresa.adrLocalitate}`}
                              </label>
                            </li>
                          );
                        })}
                    </ul>

                    {scoli && (
                      <Pagination
                        arrow
                        totalPages={Math.ceil(totalCount / 20)}
                        currentPage={parseInt(page, 10)}
                        onChangePage={this.handleChange}
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {/* tip contract */}
        {selectGroup == 'contract' && (
          <div className="select-group-style">
            <header>Selectează tipul de contract</header>
            {this.state.loading && <Loader />}

            {!this.state.loading && (
              <>
                <Breadcrumbs>
                  <Link to="#" onClick={this.onSelectGroup.bind(this, { selectGroup: null })}>
                    {t('select_group.categorii')}
                  </Link>
                  <Link to="#">Tip contract</Link>
                </Breadcrumbs>

                {contracte && contracte.length > 0 && (
                  <>
                    <div className="select-group-style-list full-width">
                      <input
                        type="checkbox"
                        id="allJudID"
                        name="all[]"
                        value="g-scoli-all"
                        checked={contracteGroup.all ? contracteGroup.all : false}
                        onChange={this.onCheckBoxChange.bind(this, 'contracteGroup', 'all', {
                          label: `${t('select_group.text_toate_scolile')}`,
                          value: 'g-scoli-all',
                          info: { value: 'g-scoli-all', type: 'all' },
                        })}
                      />
                      <label htmlFor="allJudID">{t('select_group.text_toate_scolile')}</label>

                      <p className="select-group-schools-subtitle">{t('select_group.text_scoli_judete')}</p>
                    </div>

                    <ul className="select-group-style-list">
                      {contracte &&
                        contracte.map((contract, index) => {
                          return (
                            <li key={index}>
                              <input
                                type="checkbox"
                                id={contract.tctrID}
                                value={`g-contract-${contract.tctrID}`}
                                checked={contracteGroup.boxes[index] ? contracteGroup.boxes[index] : false}
                                onChange={this.onCheckBoxChange.bind(this, 'contracteGroup', index, {
                                  label: `${t('common:text_toti_elevii_din_', `${contract.tctrNume}`)}`,
                                  value: `g-contract-${contract.tctrID}`,
                                  info: {
                                    value: `g-contract-${contract.tctrID}`,
                                    tctrID: contract.tctrID,
                                    tctrNume: contract.tctrNume,
                                    type: 'contract',
                                  },
                                  contract,
                                })}
                              />

                              <label
                                htmlFor={contract.tctrID}
                                className="select-info"
                                onClick={this.onSelectGroup.bind(this, {
                                  selectGroup: `tip${this.state.selectGroup.replace(/^\w/, (c) => c.toUpperCase())}`,
                                  contract,
                                })}
                              >
                                {contract.tctrNume}
                                <span className="select-info-bull">{contract.countScoli}</span>
                                <span className="_icon icon-sageata-dreapta" />
                              </label>
                            </li>
                          );
                        })}
                    </ul>
                  </>
                )}
              </>
            )}
          </div>
        )}

        <footer>
          <Button color="ghost-orange" onClick={this.props.onRequestClose}>
            {' '}
            {t('common:text_cancel')}
          </Button>
          <Button onClick={this.onAfterClose}> {t('common:text_add')}</Button>
        </footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    anScolar: state.session.anScolar,
    uaTip: getUaTip(state),
  };
};

const ConnectedSelectGroup = connect(mapStateToProps)(SelectGroup);
export default withTranslation(['ComponentSelectGroupSchools', 'common'])(ConnectedSelectGroup);
