import { useEffect, useState } from 'react';

import debounce from './helpers/hooks';
import widthHandling from './helpers/widthHandling';

const DetectDevice = () => {
  const [device, setDevice] = useState(widthHandling() || window.innerWidth);

  useEffect(() => {
    const handledDevice = widthHandling();

    setDevice(handledDevice);
  }, []);

  useEffect(() => {
    const handleWindowSizeChange = () => {
      const handledDevice = widthHandling();

      setDevice(handledDevice);
    };

    const debouncedHandleResize = debounce(handleWindowSizeChange, 50);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  });

  return device;
};

export default DetectDevice;
