/* eslint-disable react/jsx-fragments */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';
import shortId from 'short-id';
import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

import './ShowMore.less';

class ShowMore extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false,
      calculatedHeight: 0,
      setHeightRow: 0,
    };

    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.setHeight();
  }

  componentDidUpdate(prevProps) {
    const { linesNumber } = this.props;
    if (prevProps.linesNumber !== linesNumber) {
      this.setHeight();
    }
  }

  setHeight = () => {
    const { linesNumber } = this.props;
    const node = this.myRef.current;
    if (node) {
      const calculatedHeight = node.offsetHeight;
      let lineHeight = window.getComputedStyle(node).getPropertyValue('line-height');
      const fontSize = window.getComputedStyle(node).getPropertyValue('font-size');

      if (lineHeight === 'normal') lineHeight = parseInt(fontSize, 10) + 6;

      const setHeightRow = parseInt(linesNumber * parseInt(lineHeight, 10) + 1, 10);

      this.setState({
        calculatedHeight,
        setHeightRow,
      });
    }
  };

  showMore = () => {
    const { expanded } = this.state;
    this.setState({
      expanded: !expanded,
    });
  };

  render() {
    const { expanded, setHeightRow, calculatedHeight } = this.state;
    const { linesNumber, text, children, displayMore, t } = this.props;

    return (
      <div className="comp-show-more">
        <span className={expanded ? 'more-text' : `less-text linesNumber-${linesNumber}`}>
          {children?.length > 0 && (
            <span ref={this.myRef}>
              {typeof children === 'string' && children}
              {typeof children === 'object' &&
                children.map((el) => {
                  if (typeof el === 'object' && el.props.children !== null) {
                    return (
                      <React.Fragment key={shortId.generate()}>
                        {el.props.children}
                        <br />
                      </React.Fragment>
                    );
                  }

                  if (typeof el === 'string' && el.length > 0) {
                    return (
                      <React.Fragment key={shortId.generate()}>
                        {el}
                        <br />
                      </React.Fragment>
                    );
                  }

                  return null;
                })}
            </span>
          )}

          {((children && children.props) || text) && (
            <span ref={this.myRef}>{!text ? children.props.children : text}</span>
          )}
        </span>
        {setHeightRow < calculatedHeight && displayMore && (
          <span className="show-more-btn" onClick={this.showMore}>
            {expanded ? t('show_less') : t('show_more')}
          </span>
        )}
      </div>
    );
  }
}

ShowMore.propTypes = {
  /** textul propriu-zis */
  children: PropTypes.node,
  /** textul propriu-zis poate fi scris si cu ajutorul proprietatii -> text */
  text: PropTypes.string,
  /** daca exista mai mult de "linesNumber" - randuri, apare read more */
  linesNumber: PropTypes.number,
  displayMore: PropTypes.bool,
  t: PropTypes.func.isRequired,
};

ShowMore.defaultProps = {
  text: '',
  children: [],
  linesNumber: 1,
  displayMore: true,
};

export default withTranslation('common')(ShowMore);
