import React, { useRef } from 'react';

import PropTypes from 'prop-types';

import './Search.less';

const Search = ({
  value,
  getUrlParameter,
  keyName,
  handleChange,
  delay,
  domainText,
  titlesCount,
  titles,
  placeholder,
}) => {
  const search = useRef();
  const timer = useRef(null);

  const clearSearch = () => {
    search.current.value = '';
    if (typeof getUrlParameter === 'function' && (getUrlParameter('q') !== '' || getUrlParameter('domain') !== '')) {
      handleChange('inputValue', '');
    } else {
      handleChange(keyName, '');
    }
  };

  const filterSearch = () => {
    if (value !== search.current.value) {
      if (timer) {
        clearTimeout(timer.current);
        timer.current = null;
      }

      if (!search.current.value) {
        clearSearch();
        return true;
      }

      timer.current = setTimeout(() => handleChange(keyName, search.current.value), delay);
      return true;
    }
    return null;
  };

  return (
    <div className="comp-search">
      <input
        type="text"
        id="search-input"
        className="search-input"
        ref={search}
        onKeyUp={filterSearch}
        defaultValue={value}
        placeholder={placeholder}
      />
      {value ? (
        <button type="button" aria-label="close menu" className="_icon icon-inchide-meniu" onClick={clearSearch} />
      ) : (
        <button type="button" aria-label="search" className="_icon icon-cautare-lupa" />
      )}
      {domainText && titlesCount && (
        <div className="search-filter-description">{`${domainText} / ${titlesCount} ${titles}`}</div>
      )}
    </div>
  );
};

Search.propTypes = {
  /** valoare din camp */
  value: PropTypes.string.isRequired,
  /** delay - dupa cat timp apar rezultatele */
  delay: PropTypes.number,
  /** fct de handleChange */
  handleChange: PropTypes.func.isRequired,
  /** string pentru placeholder */
  placeholder: PropTypes.string,
  /** numele inputului */
  keyName: PropTypes.string.isRequired,
  getUrlParameter: PropTypes.func,
  domainText: PropTypes.string,
  titlesCount: PropTypes.number,
  titles: PropTypes.string,
};

Search.defaultProps = {
  delay: 500,
  placeholder: 'Search...',
  domainText: '',
  titlesCount: undefined,
  titles: '',
  getUrlParameter: undefined,
};

export default Search;
