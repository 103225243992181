import PropTypes from 'prop-types';

const HandleNoLangRoute = ({ location }) => {
  const { pathname } = location;

  // eslint-disable-next-line no-unused-vars
  const [empty, lang] = pathname.split('/');

  if (/^([a-z]{2})$/.test(lang)) return null;

  const defLang = window.location.host.match(/\.education/) ? 'en' : 'ro';
  window.location.href = `/${defLang}${pathname}`;

  return null;
};

HandleNoLangRoute.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default HandleNoLangRoute;
