import React from 'react';

import PropTypes from 'prop-types';

import './EmptyState.less';

function EmptyState(props) {
  const { className, image, icon, children } = props;
  return (
    <div className={`comp-empty-state ${className || ''}`}>
      <div className="wrapp-image">
        {image ? <img src={image} alt={image} /> : <div className={` ${icon ? `_icon ${icon}` : ''}  `} />}
      </div>
      <div className="wrapp-content">{children}</div>
    </div>
  );
}

EmptyState.propTypes = {
  /** textul din empty-state */
  children: PropTypes.node,
  /** path-ul pentru imaginea folosita in empty-state */
  image: PropTypes.string,
  /** nume icon folosit in empty-state  */
  icon: PropTypes.string,
  /** general class name  */
  className: PropTypes.string,
};

EmptyState.defaultProps = {
  children: '',
  image: '',
  icon: '',
  className: '',
};

export default EmptyState;
