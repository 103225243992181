/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import CheckBoxList from '../CheckBoxList';

export default class elementList extends Component {
  constructor(props) {
    super(props);
    const { checkList } = props;
    this.state = {
      isAllSelected: false,
      checkList,
    };
  }

  onCheckBoxChange = (checkName, isChecked) => {
    const isAllChecked = checkName === 'all' && isChecked;
    const isAllUnChecked = checkName === 'all' && !isChecked;
    const checked = isChecked;

    const checkList =
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.state.checkList &&
      // eslint-disable-next-line react/no-access-state-in-setstate
      this.state.checkList.map((element) => {
        if (isAllChecked || element.value === checkName) {
          return { ...element, checked };
        }
        if (isAllUnChecked) {
          return { ...element, checked: false };
        }

        return element;
      });

    const isAllSelected = !this.props.singleValue ? isAllChecked : false;

    this.setState({
      checkList,
      isAllSelected,
    });

    this.props.itemSelected({ checkList, isAllSelected, allValue: this.props.allValue });
  };

  render() {
    return (
      <div className="element-list">
        <CheckBoxList
          options={this.state.checkList}
          isCheckedAll={this.state.isAllSelected}
          checkedAllLabel={this.props.allValue}
          // eslint-disable-next-line react/jsx-no-bind
          onCheck={this.onCheckBoxChange.bind(this)}
          onClickItem={(option) => this.props.onClickItem(option)}
          person={this.props.person}
          singleValue={this.props.singleValue}
          toggleItems={this.props.toggleItems}
          restrictii={this.props.restrictii}
          uaTip={this.props.uaTip}
          adsTeamOption={this.props.adsTeamOption}
          selectMoreUsers={this.props.selectMoreUsers}
          restrictiiGrupAllItem={this.props.restrictiiGrupAllItem}
          firstStep={this.props.firstStep}
        />
      </div>
    );
  }
}
