/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from 'react';

import './Tooltip.less';

import PropTypes from 'prop-types';

class Tooltip extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 'theme': props.theme ? props.theme : 'light',
      theme: 'light',
      tooltipTop: null,
      tooltipBottom: null,
      tooltipLeft: null,
      toolTipOpened: false,
      tooltipWidth: null,
      tooltipHeight: null,
      tooltipRight: null,
      popupWidth: null,
      popupHeight: null,
      showOnClick: null,
      screenHt: null,
      screenWd: null,
      isOpenState: null,
    };

    this.showOnClickRef = React.createRef();
    this.subMenuRef = this.subMenuRef.bind(this);
    this.handleClickInsideMenu = this.handleClickInsideMenu.bind(this);
  }

  componentDidMount() {
    const { isOpen } = this.props;
    window.addEventListener('click', this.handleClickOutside);

    window.addEventListener('click', this.handleClickInsideMenu);

    if (isOpen) {
      this.setState({
        toolTipOpened: true,
        isOpenState: true,
      });
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    // Disable rules becouse this will not work, comparison of typeof(string) with undefined...
    // But let's not break something with eslint refactor
    // eslint-disable-next-line react/destructuring-assignment, valid-typeof, react/prop-types
    if (typeof this.props.shouldUpdate !== undefined && this.props.shouldUpdate !== nextProps.shouldUpdate) {
      this.forceUpdate();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { getPopupWidth, toolTipOpened, updatePop } = this.state;
    if (
      getPopupWidth === nextState.getPopupWidth &&
      toolTipOpened === nextState.toolTipOpened &&
      updatePop === nextState.updatePop
    ) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
    window.removeEventListener('click', this.handleClickInsideMenu);
  }

  handleMouseOver = (evt) => {
    // get hovered element reference
    const el = evt.currentTarget;
    const screenHeight = window.innerHeight;
    const screenWidth = window.innerWidth;

    if (el != null) {
      const rect = el.getBoundingClientRect();

      this.setState({
        toolTipOpened: true,
        updatePop: true,
        tooltipTop: rect.top,
        tooltipBottom: rect.bottom,
        tooltipLeft: rect.left,
        tooltipRight: rect.right,
        tooltipWidth: rect.width,
        tooltipHeight: rect.height,
        screenHt: screenHeight,
        screenWd: screenWidth,
      });
    }
  };

  handleClickInsideMenu(event) {
    const { hideOnClick } = this.props;
    if (this.selectedWrapperRef && this.selectedWrapperRef.contains(event.target) && hideOnClick) {
      this.setState({
        showOnClick: false,
        toolTipOpened: false,
        popupWidth: null,
        popupHeight: null,
        tooltipTop: null,
        tooltipBottom: null,
        tooltipLeft: null,
        tooltipRight: null,
        tooltipWidth: null,
        tooltipHeight: null,
        screenHt: null,
        screenWd: null,
        isOpenState: null,
      });
    }
  }

  getPopupWidth = (el) => {
    const clientRectWidth = el ? el.getBoundingClientRect().width : null;
    const clientRectHeight = el ? el.getBoundingClientRect().height : null;

    this.setState({
      updatePop: false,
      popupWidth: clientRectWidth,
      popupHeight: clientRectHeight,
    });
  };

  handleMouseLeave = () => {
    const { worksOnClick } = this.props;
    if (!worksOnClick) {
      this.setState({
        // eslint-disable-next-line react/no-access-state-in-setstate
        ...this.state,
        toolTipOpened: false,
        popupWidth: null,
        popupHeight: null,
        tooltipTop: null,
        tooltipBottom: null,
        tooltipLeft: null,
        tooltipRight: null,
        tooltipWidth: null,
        tooltipHeight: null,
        screenHt: null,
        screenWd: null,
        isOpenState: null,
        // updatePop:false
      });
    }
  };

  handleClickInside = () => {
    const { worksOnClick, hideOnClick } = this.props;
    if (worksOnClick) {
      this.setState({
        showOnClick: true,
        toolTipOpened: true,
        updatePop: true,
      });
    } else if (hideOnClick) {
      this.setState({
        toolTipOpened: true,
      });
    }
    return undefined;
  };

  handleClickOutside = (event) => {
    const { worksOnClick } = this.props;
    if (worksOnClick) {
      const node = this.showOnClickRef.current;
      if (node && !node.contains(event.target)) {
        this.setState({
          showOnClick: false,
          toolTipOpened: false,
          popupWidth: null,
          popupHeight: null,
          tooltipTop: null,
          tooltipBottom: null,
          tooltipLeft: null,
          tooltipRight: null,
          tooltipWidth: null,
          tooltipHeight: null,
          screenHt: null,
          screenWd: null,
          isOpenState: null,
        });
      }
    }
    return undefined;
  };

  subMenuRef(node) {
    this.selectedWrapperRef = node;
  }

  render() {
    const {
      children,
      worksOnClick,
      arrowPosition,
      isOpen,
      tooltipClass,
      isHidden,
      WrapperClass,
      poupClass,
    } = this.props;
    const {
      theme,
      toolTipOpened,
      popupWidth,
      popupHeight,
      tooltipLeft,
      tooltipTop,
      tooltipBottom,
      tooltipRight,
      showOnClick,
      screenHt,
      screenWd,
      isOpenState,
    } = this.state;
    const adjustTop = tooltipClass.includes('adjust-top');

    return (
      <div
        className={`comp-tooltip-menu
                ${WrapperClass || ''}
                ${arrowPosition && arrowPosition === 'center' ? 'tooltip-centered' : ''}
                ${arrowPosition && arrowPosition === 'right' ? 'tooltip-right' : ''}
                ${arrowPosition && arrowPosition === 'left' ? 'adjust-left' : ''}
                ${arrowPosition && arrowPosition === 'left-side' ? 'adjust-left-side' : ''}
                ${worksOnClick ? 'works-on-click' : ''}
                ${showOnClick ? 'works-on-click-show' : ''}
                ${popupWidth && tooltipLeft && tooltipTop && popupWidth - 10 > tooltipLeft ? 'adjust-left' : ''}

                ${
                  popupWidth &&
                  tooltipRight &&
                  screenWd &&
                  tooltipRight + popupWidth > screenWd + 40 /* 40 is an adjustment for small res Ex:iphone 5/SE */
                    ? 'adjust-right'
                    : ''
                }

                ${
                  popupWidth &&
                  tooltipLeft &&
                  tooltipTop &&
                  tooltipRight &&
                  popupWidth > tooltipLeft &&
                  tooltipLeft > 70 &&
                  popupWidth > tooltipRight + 5 &&
                  arrowPosition !== 'left' &&
                  arrowPosition !== 'right'
                    ? 'adjust-left-right'
                    : ''
                }

                ${
                  (tooltipBottom && screenHt && tooltipBottom > screenHt - popupHeight) || adjustTop
                    ? 'adjust-top-bottom'
                    : ''
                }

                ${isOpen && toolTipOpened && isOpenState ? 'default-open' : ''}
                ${tooltipClass ? `${tooltipClass}` : ''}`}
        onMouseEnter={this.handleMouseOver}
        onMouseLeave={this.handleMouseLeave}
        onClick={this.handleClickInside}
        ref={worksOnClick ? this.showOnClickRef : null}
      >
        <div className="more-actions">{children[0]}</div>

        <div ref={this.subMenuRef}>
          <div
            className={`submenu ${theme} ${poupClass} ${isHidden && 'hide-submenu'}`}
            ref={(el) => {
              if (toolTipOpened === true) {
                this.getPopupWidth(el);
              }
            }}
            // top: `calc(100% + ${12}px)`
            //   top: `${tooltipBottom && screenHt && tooltipBottom > screenHt - popupHeight ? `-${popupHeight + 15}px` : `unset`}`
            style={{
              top: `${40}px`,
              marginTop: `${tooltipBottom && screenHt && tooltipBottom > screenHt - 120 ? 'unset' : 'unset'}`,
            }}
          >
            {children[1]}
          </div>
        </div>
      </div>
    );
  }
}

export default Tooltip;

Tooltip.propTypes = {
  hideOnClick: PropTypes.bool,
  /** The component can receive children from parent component */
  children: PropTypes.arrayOf(PropTypes.element),
  /** You need to specify if the component works on click or not */
  worksOnClick: PropTypes.bool,
  /** Where the arrow of the tooltip is positioned by default */
  arrowPosition: PropTypes.oneOf(['center', 'right', 'left', 'left-side']),
  /** If the tooltip is opened by default or not */
  isOpen: PropTypes.bool,
  /** Add a class to the tooltip */
  tooltipClass: PropTypes.string,
  /** Should the submenu be displayed or not */
  isHidden: PropTypes.bool,
  /** Add a class to the wrapper container of the tooltip */
  WrapperClass: PropTypes.string,
  /** Add a class to the popup */
  poupClass: PropTypes.string,
};

Tooltip.defaultProps = {
  hideOnClick: false,
  worksOnClick: false,
  isOpen: false,
  isHidden: false,
  tooltipClass: '',
  WrapperClass: '',
  poupClass: '',
  children: [],
  arrowPosition: undefined,
};
