/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { PureComponent } from 'react';

import PropTypes from 'prop-types';

import Button from 'Components/Forms/SimpleButton/Button';

import Modal from '../Modal/Modal';

import './Toaster.less';

class Toaster extends PureComponent {
  componentDidMount() {
    const { modalTimer } = this.props;
    if (!modalTimer) return;
    this.closeModalTimeout();

    window.addEventListener('scroll', this.handleScroll);
  }

  componentDidUpdate(prevProps) {
    const { modalTimer } = this.props;
    if (!modalTimer) return;
    if (modalTimer !== prevProps.modalTimer) {
      this.closeModalTimeout();
    }

    if (document.getElementsByClassName('reactModal-toaster')[0]) this.handleScroll();
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    if (window.scrollY > 20) {
      document.getElementsByClassName('reactModal-toaster')[0].classList.add('fixed-toaster-modal');
    } else {
      document.getElementsByClassName('reactModal-toaster')[0].classList.remove('fixed-toaster-modal');
    }
  };

  closeModalTimeout = () => {
    const { modalTimer, onRequestCloseToaster } = this.props;
    if (!modalTimer || typeof modalTimer !== 'number') return;
    setTimeout(() => {
      // closeModal(modalRef)
      if (typeof onRequestCloseToaster === 'function') onRequestCloseToaster();
    }, modalTimer);
  };

  render() {
    const {
      overlay,
      className,
      color,
      position,
      onAfterOpen,
      content,
      showBtn,
      confirmBtn,
      onRequestCloseToaster,
      children,
    } = this.props;
    return (
      <Modal
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        isCloseable
        onRequestClose={onRequestCloseToaster}
        overlay={overlay}
        className={className ? `reactModal-toaster ${className}` : 'reactModal-toaster'}
        color={color || 'orange'}
        position={position || 'center-small'}
        onAfterOpen={
          onAfterOpen ||
          (() => {
            document.body.style.overflow = 'auto';
          })
        }
      >
        <div className="toaster-modal" onClick={onRequestCloseToaster}>
          {children}
          <div className="modal-body">
            <div className="modal-text">{content}</div>
          </div>
          {showBtn && (
            <div className="modal-btns">
              <Button className="modal-btn-no" onClick={onRequestCloseToaster}>
                {confirmBtn || 'OK'}
              </Button>
            </div>
          )}
        </div>
      </Modal>
    );
  }
}

export const HeaderToast = (props) => {
  const { children } = props;
  return <div className="modal-header">{props && children}</div>;
};

HeaderToast.propTypes = {
  /* the content of the header */
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

Toaster.propTypes = {
  /* A callback function that is called when the modal close */
  onRequestClose: PropTypes.func,
  /* A callback function that is called after the modal open */
  onAfterOpen: PropTypes.func,

  /* false if you don't whant it,  else className  */
  overlay: PropTypes.bool,
  /* add a classname for the overlay to be styled */
  overlayClassName: PropTypes.string,
  /* color of the left border */
  color: PropTypes.oneOf(['red', 'green', 'orange', 'blue', 'black']).isRequired,
  /* position of the modal to one of the sides and animate it from that side. is true for center. */
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'top-center',
    'bottom-left',
    'bottom-right',
    'bottom-center',
    'center-left',
    'center-right',
    'center-small',
    'center',
  ]).isRequired,
  /* the width of the modal */
  width: PropTypes.string,
  /* the height of the modal */
  height: PropTypes.string,
  /* the content of the modal */
  children: PropTypes.node,
  /* extra class for the modal is used for styling purposes */
  className: PropTypes.string,
  /* if you have the x icon for closing */
  isCloseable: PropTypes.bool,
  content: PropTypes.string,
  showBtn: PropTypes.bool,
  confirmBtn: PropTypes.string,
  onRequestCloseToaster: PropTypes.func,
  modalTimer: PropTypes.number,
};

HeaderToast.propTypes = {
  /* the content of the header */
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

Toaster.defaultProps = {
  onRequestClose: () => {},
  onAfterOpen: () => {},
  overlay: false,
  overlayClassName: '',
  width: '',
  height: '',
  children: [],
  className: '',
  isCloseable: false,
  content: '',
  showBtn: false,
  confirmBtn: '',
  onRequestCloseToaster: () => {},
  modalTimer: undefined,
};

export default Toaster;
