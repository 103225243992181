/* eslint-disable quotes */
import React from 'react';

import { DateTime } from 'luxon';

import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

const outputDate = (date, formatDt, t, i18n) => {
  const fixDateForAllBrowsers = (dateString) => dateString && dateString.replace(/-/g, '/');

  const pDate = new Date(date);
  const now = new Date();

  const format = formatDt === undefined ? 'relative' : formatDt;

  let out = '';

  const minutes = 1000 * 60;
  const hours = minutes * 60;
  const days = hours * 24;

  const newDate = fixDateForAllBrowsers(date);

  const diffBetweenNowAndMyDate = now.getTime() - pDate.getTime();
  const selLang = i18n && i18n.language ? i18n.language : 'ro';

  const weekDay = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).weekdayLong;
  const simpleDay = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).day;
  const simpleMonth = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).monthLong;

  switch (format) {
    case 'HH:mm':
      out = DateTime.fromJSDate(new Date(newDate))
        .setLocale(`${selLang}`)
        .toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false });
      break;

    case 'dd':
      out = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).toLocaleString({ day: '2-digit' });
      break;

    case 'dd LLL yyyy':
      out = DateTime.fromJSDate(new Date(newDate))
        .setLocale(`${selLang}`)
        .toLocaleString({ day: '2-digit', month: 'short', year: 'numeric' });
      break;

    case 'dd LLL yy':
      out = DateTime.fromJSDate(new Date(newDate))
        .setLocale(`${selLang}`)
        .toLocaleString({ day: '2-digit', month: 'short', year: '2-digit' });
      break;

    case 'dd LLL':
      out = DateTime.fromJSDate(new Date(newDate))
        .setLocale(`${selLang}`)
        .toLocaleString({ day: '2-digit', month: 'short' });
      break;

    case 'DATE_FULL':
      out = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).toLocaleString(DateTime.DATE_FULL);
      break;

    case 'DATE_HUGE':
      out = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).toLocaleString(DateTime.DATE_HUGE);
      break;

    case 'huge_without_year':
      out = `${weekDay}, ${simpleDay} ${simpleMonth}`;
      break;

    case 'cccc, d LLL, ora  HH:mm':
      out = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).toFormat("cccc',' d LLL', ora' HH':'mm");
      out = out.replace(/\./, '');
      out = out.charAt(0).toUpperCase() + out.slice(1);
      break;

    case 'cccc d LLL / HH:mm':
      out = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).toFormat("cccc d LLL' / 'HH':'mm");
      out = out.replace(/\./, '');
      out = out.charAt(0).toUpperCase() + out.slice(1);
      break;

    case 'cccc d LLL yyyy':
      out = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).toFormat('cccc d LLL yyyy');
      out = out.replace(/\./, '');
      out = out.charAt(0).toUpperCase() + out.slice(1);
      break;

    case 'future': {
      // 'future' param for future expiration-->today / tomorow / normal date
      const checkFutureDate = Math.floor(pDate.getTime() / days) - Math.floor(now.getTime() / days);

      if (parseInt(checkFutureDate, 10) === 0) {
        out = `${t('moments_today')} ${DateTime.fromJSDate(new Date(newDate))
          .setLocale(`${selLang}`)
          .toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false })}`;
      } else if (parseInt(checkFutureDate, 10) === 1) {
        out = `${t('moments_tomorow')} ${DateTime.fromJSDate(new Date(newDate))
          .setLocale(`${selLang}`)
          .toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false })}`;
      } else {
        out = DateTime.fromJSDate(new Date(newDate)).setLocale(`${selLang}`).toLocaleString({
          day: '2-digit',
          month: 'short',
          year: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        });
      }
      break;
    }
    case 'relative':
    default:
      if (diffBetweenNowAndMyDate <= minutes) {
        out = t ? t('moments_ago') : '';
      } else if (diffBetweenNowAndMyDate <= hours) {
        out = t
          ? `${t('moments_ago_txt1')} ${Math.round(diffBetweenNowAndMyDate / minutes)} ${t('moments_ago_txt2')}`
          : '';
      } else if (Math.floor(now.getTime() / days) === Math.floor(pDate.getTime() / days)) {
        out = DateTime.fromJSDate(new Date(newDate))
          .setLocale(`${selLang}`)
          .toLocaleString({ hour: '2-digit', minute: '2-digit', hour12: false });
      } else if (now.getFullYear() === pDate.getFullYear()) {
        out = DateTime.fromJSDate(new Date(newDate))
          .setLocale(`${selLang}`)
          .toLocaleString({ day: '2-digit', month: 'short' });
      } else {
        out = DateTime.fromJSDate(new Date(newDate))
          .setLocale(`${selLang}`)
          .toLocaleString({ day: '2-digit', month: 'short', year: '2-digit' });
      }

      break;
  }
  return out;
};

const FormatDate = (props) => {
  const { date, format } = props;
  const { t, i18n } = useTranslation('ComponentDate');

  return <span>{outputDate(date, format, t, i18n)}</span>;
};

FormatDate.propTypes = {
  format: PropTypes.string,
  date: PropTypes.string,
};

FormatDate.defaultProps = {
  format: undefined,
  date: undefined,
};

export default FormatDate;

export function formatDate(newDate, formatDateType) {
  return outputDate(newDate, formatDateType);
}
