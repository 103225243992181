/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import { withTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-named-as-default
import FileIcon from '../../Content/Files/FileIcon/FileIcon';
import Icon from '../../Content/Icon/Icon';
import Modal from '../Modal/Modal';

import './Preview.less';
import previewFail from './images/submarin.svg';

class Preview extends Component {
  constructor(props) {
    super(props);
    this.handleEsc = this.handleEsc.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEsc, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleEsc, false);
  }

  handleEsc = (event) => {
    if (event.keyCode === 27) {
      this.onRequestClose();
    }
  };

  renderFile = (file) => {
    const { t } = this.props;

    if (file.fsMimeType) {
      if (file.fsMimeType.match(/video\//i)) {
        return (
          <video width="100%" controls autoPlay onLoadedData={this.handleImageLoaded.bind(this)}>
            <source src={`${file.fsUrl}`} type="video/mp4" />
            {t('preview.browser_not_support')}
          </video>
        );
      }

      if (file.fsMimeType.match(/audio\//i)) {
        return (
          <audio
            width="100%"
            controls
            autoPlay
            controlsList="nodownload"
            onLoadedData={this.handleImageLoaded.bind(this)}
          >
            <source src={`${file.fsUrl}`} type={`${file.fsMimeType}`} />
            {t('preview.browser_not_support')}
          </audio>
        );
      }

      if (file.fsMimeType.match(/image\//i)) {
        return <img onLoad={this.handleImageLoaded.bind(this)} src={`${file.fsUrl}`} alt="" />;
      }

      if (file.fsMimeType.match(/pdf/i)) {
        return (
          <div className="fs-preview">
            <iframe
              src={`${file.fsUrl}`}
              onLoad={this.handleImageLoaded.bind(this)}
              width="100%"
              height="100%"
              frameBorder="0"
            />
          </div>
        );
      }

      if (file.fsMimeType.match(/word|excel|powerpoint|office/i)) {
        return (
          <iframe
            src={`https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(`${file.fsUrl}`)}`}
            onLoad={this.handleImageLoaded.bind(this)}
            frameBorder="0"
          />
        );
      }

      return (
        <div className="fs-preview-fail">
          {t('preview.fail')}
          <br />
          <a href={file.fsUrl} target="_blank" rel="noreferrer" className="download-fs-fail">
            {t('preview.download_file')}
          </a>
          <img src={previewFail} alt="fail-preview" />
        </div>
      );
    }
  };

  handleImageLoaded = () => {
    // this.setState({ isLoading: false });
  };

  onRequestClose = () => {
    const { onRequestClose } = this.props;

    if (typeof onRequestClose === 'function') {
      onRequestClose();
    }
  };

  render() {
    const { file, t } = this.props;

    return (
      <Modal {...this.props} closeable overlay className="comp-modal-preview" overlayClassName="preview-files-overlay">
        <div className="preview-header">
          <div className="preview-header-left">
            <FileIcon fsTip={file.fsTip} fsExt={file.fsExt} />
            {file.fsNume}
          </div>
          <div className="preview-header-right">
            <a href={file.fsUrl} target="_blank" rel="noreferrer">
              <Icon icon="icon-download-circle" />
              {t('preview.download')}
            </a>
            <button
              type="button"
              onClick={this.onRequestClose}
              onTouchEnd={this.onRequestClose}
              className="close-modal _icon icon-inchide-x"
            />
          </div>
        </div>
        <div className="preview-content">{this.renderFile(file)}</div>
      </Modal>
    );
  }
}

Preview.propTypes = {
  file: PropTypes.shape({
    fsNume: PropTypes.string,
    fsTip: PropTypes.string,
    fsExt: PropTypes.string,
    fsUrl: PropTypes.string,
  }),
  t: PropTypes.func.isRequired,
  onRequestClose: PropTypes.func,
};

Preview.defaultProps = {
  file: {},
  onRequestClose: () => {},
};

export default withTranslation('ComponentPreview')(Preview);
