import React from 'react';
import PropTypes from 'prop-types';
import LaddaButton, { EXPAND_RIGHT } from 'react-ladda';
import FileIcon from 'Components/Content/Files/FileIcon/FileIcon';

import './AttachmentLoader.less';

const AttachmentLoader = (props) => {
  const { fileInfo, theme, progress } = props;

  return (
    <LaddaButton
      loading
      data-style={EXPAND_RIGHT}
      progress={progress}
      className={`comp-attachemnt-loader ${theme}`}
      data-spinner-color="#999999"
    >
      <FileIcon fsTip={fileInfo?.fsTip} fsExt={fileInfo?.fsExt} />
      {fileInfo?.fsNume}
    </LaddaButton>
  );
};

AttachmentLoader.propTypes = {
  /** File's details */
  fileInfo: PropTypes.shape({
    fsNume: PropTypes.string,
    fsSize: PropTypes.string,
    fsExt: PropTypes.string,
    fsID: PropTypes.string,
    fsTip: PropTypes.string,
  }),
  progress: PropTypes.number,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  theme: PropTypes.string,
};

AttachmentLoader.defaultProps = {
  fileInfo: {
    fsNume: '',
    fsSize: '',
    fsExt: '',
    fsID: '',
    fsTip: '',
  },
  progress: 0,
  history: {
    push: () => {},
  },
  location: {
    search: '',
  },
  theme: 'accent',
};

export default AttachmentLoader;
