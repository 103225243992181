import React from 'react';
import PropTypes from 'prop-types';

import './Table.less';

class Table extends React.PureComponent {
  render() {
    const { children } = this.props;

    return <table className="comp-table">{children}</table>;
  }
}

Table.propTypes = {
  /** continutul tabelului propriu-zis */
  children: PropTypes.node,
};

Table.defaultProps = {
  children: undefined,
};

export default Table;
