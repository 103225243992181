import React from 'react';

import PropTypes from 'prop-types';

import { SN_INSIGNE, SN_CALIFICATIVE, SN_CAMBRIDGE, SN_NOTE, NT_TI, NT_TF, NT_SIMULARE } from '../../../../constants';

import './Grade.less';

import getGradeColor from '../helpers/getGradeColor';
import gradeParser from '../helpers/GradeParser';

const Grade = (props) => {
  const { grade, forceDecimals, gradeSystem, gradeColor } = props;

  let gradeColorToShow = 'none';

  if (gradeColor) {
    gradeColorToShow = `${gradeColor} gradeColorSetNew`;
  } else if (grade && grade.notaTip) {
    gradeColorToShow = getGradeColor(grade.notaTip);
  }

  if (!grade) {
    return null;
  }
  return (
    <span>
      {`${gradeSystem}` === SN_NOTE && (
        <span className={`show-grade show-color-${gradeColorToShow} `}>
          <span className="show-grade-integer">{gradeParser(grade, 'int', forceDecimals)}</span>
          {gradeParser(grade, 'decimal', forceDecimals) && (
            <span className="show-grade-decimal">.{gradeParser(grade, 'decimal', forceDecimals)}</span>
          )}
          {grade.notaTip && (grade.notaTip === NT_TI || grade.notaTip === NT_TF || grade.notaTip === NT_SIMULARE) && (
            <span className="show-grade-extra">*</span>
          )}
        </span>
      )}

      {`${gradeSystem}` === SN_CALIFICATIVE && (
        <span className={`show-grade-ratings show-color-${gradeColorToShow}`}>
          {gradeParser(grade, 'ratings', forceDecimals)}
        </span>
      )}

      {`${gradeSystem}` === SN_CAMBRIDGE && (
        <span className={`show-grade-ratings show-color-${gradeColorToShow}`}>
          {gradeParser(grade, 'cambridge', forceDecimals)}
        </span>
      )}

      {`${gradeSystem}` === SN_INSIGNE && (
        <span className="show-grade-img">{gradeParser(grade, 'img', forceDecimals)}</span>
      )}
    </span>
  );
};

export default Grade;

Grade.propTypes = {
  /** The actual grade that will be parsed by the component.It can be object, number or string */
  grade: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.object]),
  /** This option can add decimals on a certain grade if you want */
  forceDecimals: PropTypes.bool,
  /** Different type of grade system (grades/ratings/badges) */
  gradeSystem: PropTypes.oneOf(['0', '1', '2', '3']),
  /**
   * Some grades have different color code (the average/some exams)
   * Currently "red","blue","green","black" are supported, but more colors can be added if needed
   */
  gradeColor: PropTypes.string,
};

Grade.defaultProps = {
  grade: '',
  forceDecimals: false,
  gradeSystem: '',
  gradeColor: '',
};
