// Remove rules, I don't know how this works with assignment in ternary and in return statements...
// But let's not break anything with eslint refactor
/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import React, { Component } from 'react';

import PropTypes from 'prop-types';

import './TimePicker.less';

class TimePicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hoursStart: '',
      minutesStart: '',
      hoursEnd: '',
      minutesEnd: '',
    };

    this.hoursStartRef = React.createRef();
    this.minutesStartRef = React.createRef();
    this.hoursEndRef = React.createRef();
    this.minutesEndRef = React.createRef();
  }

  componentDidMount() {
    const { defaultData } = this.props;
    if (defaultData) {
      const hrStart =
        defaultData.indexOf(' ') >= 0 ? defaultData.split(' ')[1].split(':')[0] : defaultData.split(':')[0];
      const minStart =
        defaultData.indexOf(' ') >= 0 ? defaultData.split(' ')[1].split(':')[1] : defaultData.split(':')[1];
      this.setState({
        hoursStart: hrStart,
        minutesStart: minStart,
      });
    }

    window.addEventListener('click', this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState) {
    const { defaultData, interval, cbTimeData } = this.props;
    const { hoursStart, minutesStart, hoursEnd, minutesEnd } = this.state;

    if (defaultData && prevProps.defaultData !== defaultData) {
      const hrStart = defaultData.split(' ')[1].split(':')[0];
      const minStart = defaultData.split(' ')[1].split(':')[1];
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        hoursStart: hrStart,
        minutesStart: minStart,
      });
    }

    if (
      prevState.hoursStart !== hoursStart ||
      prevState.minutesStart !== minutesStart ||
      prevState.hoursEnd !== hoursEnd ||
      prevState.minutesEnd !== minutesEnd
    ) {
      const singleTime = {
        hour: hoursStart,
        minutes: minutesStart,
      };

      const intervalTime = {
        hoursStart,
        minutesStart,
        hoursEnd,
        minutesEnd,
      };

      cbTimeData(interval ? intervalTime : singleTime);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickOutside);
  }

  adjustMinHourForBtn = () => {
    // Remove rules, I don't know how this works with assignment in ternary.. But let's not break anything with eslint refactor
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-constant-condition
    const hrsMinSlice = (val) => (val = '' ? `0${0}`.slice(-2) : `0${parseInt(val, 10)}`.slice(-2));
    const nodeHrSt = this.hoursStartRef.current;
    const nodeMinSt = this.minutesStartRef.current;

    if (nodeHrSt && nodeHrSt.value.length === 1) {
      this.setState({
        hoursStart: hrsMinSlice(nodeHrSt.value),
      });
    }

    if (nodeMinSt && nodeMinSt.value.length === 1) {
      this.setState({
        minutesStart: hrsMinSlice(nodeMinSt.value),
      });
    }
  };

  handleClickOutside = () => {
    const { interval } = this.props;
    // Remove rules, I don't know how this works with assignment in ternary.. But let's not break anything with eslint refactor
    // eslint-disable-next-line no-return-assign, no-param-reassign, no-constant-condition
    const hrsMinSlice = (val) => (val = '' ? `0${0}`.slice(-2) : `0${parseInt(val, 10)}`.slice(-2));
    const nodeHrSt = this.hoursStartRef.current;
    const nodeMinSt = this.minutesStartRef.current;
    const nodeHrEnd = this.hoursEndRef.current;
    const nodeMinEnd = this.minutesEndRef.current;

    // eslint-disable-next-line no-restricted-globals
    if (nodeHrSt && !nodeHrSt.contains(event.target) && nodeHrSt.value.length === 1) {
      this.setState({
        hoursStart: hrsMinSlice(nodeHrSt.value),
      });
    }

    // eslint-disable-next-line no-restricted-globals
    if (nodeMinSt && !nodeMinSt.contains(event.target) && nodeMinSt.value.length === 1) {
      this.setState({
        minutesStart: hrsMinSlice(nodeMinSt.value),
      });
    }

    // eslint-disable-next-line no-restricted-globals
    if (interval && nodeHrEnd && !nodeHrEnd.contains(event.target) && nodeHrEnd.value.length === 1) {
      this.setState({
        hoursEnd: hrsMinSlice(nodeHrEnd.value),
      });
    }

    // eslint-disable-next-line no-restricted-globals
    if (interval && nodeMinEnd && !nodeMinEnd.contains(event.target) && nodeMinEnd.value.length === 1) {
      this.setState({
        minutesEnd: hrsMinSlice(nodeMinEnd.value),
      });
    }
  };

  handleChangeTime = (type, event) => {
    const { interval, cbTimeData } = this.props;
    let hrsStart = '';
    let minStart = '';
    let hoursEnd = '';
    let minutesEnd = '';
    const hrsMinSlice = event.target.value;
    const MAX_LENGTH = 2;

    if (type === 'hoursStart') {
      const regex = /^\d{0,2}$/;

      if (!regex.test(hrsMinSlice)) {
        return;
      }

      if (Number.isNaN(hrsMinSlice)) {
        return (hrsStart = '');
      }

      if (parseInt(hrsMinSlice, 10) > 23) {
        return (hrsStart = 23);
      }
      hrsStart = hrsMinSlice;

      if (event.target.value.length === MAX_LENGTH) {
        this.minutesStartRef.current.focus();
      }
    }

    if (type === 'minutesStart') {
      const regex = /^\d{0,2}$/;

      if (!regex.test(hrsMinSlice)) {
        return;
      }

      if (Number.isNaN(hrsMinSlice)) {
        minStart = '';
      } else if (parseInt(hrsMinSlice, 10) > 59) {
        minStart = 59;
      } else minStart = hrsMinSlice;
    }

    if (type === 'hoursEnd') {
      const regex = /^\d{0,2}$/;

      if (!regex.test(hrsMinSlice)) {
        return;
      }

      if (Number.isNaN(hrsMinSlice)) {
        hoursEnd = '';
      } else if (parseInt(hrsMinSlice, 10) > 23) {
        hoursEnd = 23;
      } else hoursEnd = hrsMinSlice;

      if (event.target.value.length === MAX_LENGTH) {
        this.minutesEndRef.focus();
      }
    }

    if (type === 'minutesEnd') {
      const regex = /^\d{0,2}$/;

      if (!hrsMinSlice.match(regex)) {
        return;
      }

      if (Number.isNaN(hrsMinSlice)) {
        minutesEnd = '';
      } else if (parseInt(hrsMinSlice, 10) > 59) {
        minutesEnd = 59;
      } else minutesEnd = hrsMinSlice;
    }

    const singleTime = {
      // eslint-disable-next-line react/destructuring-assignment
      hour: type === 'hoursStart' ? hrsStart : this.state.hoursStart,
      // eslint-disable-next-line react/destructuring-assignment
      minutes: type === 'minutesStart' ? minStart : this.state.minutesStart,
    };

    const intervalTime = {
      // eslint-disable-next-line react/destructuring-assignment
      hoursStart: type === 'hoursStart' ? hrsStart : this.state.hoursStart,
      // eslint-disable-next-line react/destructuring-assignment
      minutesStart: type === 'minutesStart' ? minStart : this.state.minutesStart,
      // eslint-disable-next-line react/destructuring-assignment
      hoursEnd: type === 'hoursEnd' ? hoursEnd : this.state.hoursEnd,
      // eslint-disable-next-line react/destructuring-assignment
      minutesEnd: type === 'minutesEnd' ? minutesEnd : this.state.minutesEnd,
    };

    this.setState(
      {
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        hoursStart: type === 'hoursStart' ? hrsStart : this.state.hoursStart,
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        minutesStart: type === 'minutesStart' ? minStart : this.state.minutesStart,
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        hoursEnd: type === 'hoursEnd' ? hoursEnd : this.state.hoursEnd,
        // eslint-disable-next-line react/destructuring-assignment,react/no-access-state-in-setstate
        minutesEnd: type === 'minutesEnd' ? minutesEnd : this.state.minutesEnd,
      },
      () => cbTimeData(interval ? intervalTime : singleTime)
    );
  };

  render() {
    const { hoursStart, hoursEnd, minutesStart, minutesEnd } = this.state;
    const { interval, cbTimeData, placeholder, className } = this.props;
    return (
      <>
        {cbTimeData && (
          <div className={`time-picker-container ${className}`}>
            <div className="time-interval-picker-start">
              <input
                className="hours-start"
                // type="number"
                // min="0"
                // max="23"
                value={hoursStart}
                onChange={(e) => this.handleChangeTime('hoursStart', e)}
                placeholder={placeholder[0]}
                ref={this.hoursStartRef}
                onBlur={() => {
                  this.adjustMinHourForBtn();
                }}
              />
              <div className="time-separation"> :</div>
              <input
                className="minutes-start"
                // type="number"
                // min="0"
                // max="59"
                value={minutesStart}
                onChange={(e) => this.handleChangeTime('minutesStart', e)}
                placeholder={placeholder[1]}
                ref={this.minutesStartRef}
                onBlur={() => {
                  this.adjustMinHourForBtn();
                }}
              />
            </div>
            {interval && (
              <>
                <div className="time-interval-separation">:</div>
                <div className="time-interval-picker-end">
                  <input
                    className="hours-start"
                    // type="number"
                    // min="0"
                    // max="23"
                    value={hoursEnd}
                    onChange={(e) => this.handleChangeTime('hoursEnd', e)}
                    placeholder={placeholder[0]}
                    ref={this.hoursEndRef}
                  />
                  <div className="time-separation"> :</div>
                  <input
                    className="minutes-end"
                    // type="number"
                    // min="0"
                    // max="59"
                    value={minutesEnd}
                    onChange={(e) => this.handleChangeTime('minutesEnd', e)}
                    placeholder={placeholder[1]}
                    ref={this.minutesEndRef}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </>
    );
  }
}

TimePicker.propTypes = {
  /** daca tipul picker-ului este de tip interval sau simplu */
  interval: PropTypes.bool,
  /** fct de callback .trimite date la fiecare schimbare de ora/min cu datele actualizate */
  cbTimeData: PropTypes.func,
  /** daca este prezent, populeaza componenta cu datele predefinite */
  defaultData: PropTypes.oneOfType([
    PropTypes.bool, // if not present or false
    PropTypes.string,
  ]),
  placeholder: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
};

TimePicker.defaultProps = {
  interval: false,
  cbTimeData: () => {},
  defaultData: false,
  placeholder: ['--', '--'],
  className: '',
};

export default TimePicker;
