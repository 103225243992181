/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import Button from '../../Forms/SimpleButton/Button';
// eslint-disable-next-line import/no-named-as-default
import Modal from '../Modal/Modal';

import './Notice.less';

const Notice = (props) => {
  const { t } = useTranslation('ComponentNotice');
  const { overlay, className, width, height, position, children, onRequestClose, confirmBtn } = props;

  return (
    <Modal
      {...props}
      isCloseable
      overlay={overlay}
      className={className ? `reactModal-notice ${className}` : 'reactModal-notice'}
      width={width || '400px'}
      height={height || null}
      position={position || 'center-small'}
    >
      <div className="notice-modal">
        {children}
        <div className="modal-btns">
          <Button className="modal-btn-no" onClick={onRequestClose}>
            {confirmBtn || t('notice.confirm')}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const HeaderNtc = (props) => {
  const { children } = props;
  return <div className="modal-header">{props && children}</div>;
};

export const BodyNtc = (props) => {
  const { children } = props;
  return (
    <div className="modal-body">
      <div className="modal-text">{children}</div>
    </div>
  );
};

Notice.propTypes = {
  /* A callback function that is called when the modal close */
  onRequestClose: PropTypes.func,
  /* A callback function that is called after the modal open */
  onAfterOpen: PropTypes.func,

  /* false if you don't whant it,  else className  */
  overlay: PropTypes.bool,
  /* add a classname for the overlay to be styled */
  overlayClassName: PropTypes.string,
  /* color of the left border */
  color: PropTypes.oneOf(['red', 'green', 'orange', 'blue', 'black']),
  /* position of the modal to one of the sides and animate it from that side. is true for center. */
  position: PropTypes.oneOf([
    'top-left',
    'top-right',
    'top-center',
    'bottom-left',
    'bottom-right',
    'bottom-center',
    'center-left',
    'center-right',
    'center-small',
    'center',
  ]),
  /* the width of the modal */
  width: PropTypes.string,
  /* the height of the modal */
  height: PropTypes.string,
  /* the content of the modal */
  children: PropTypes.node,
  /* extra class for the modal is used for styling purposes */
  className: PropTypes.string,
  /* if you have the x icon for closing */
  isCloseable: PropTypes.bool,
  /* flag to see if the modal is visible */
  isVisible: PropTypes.bool,
  confirmBtn: PropTypes.string,
};

Notice.defaultProps = {
  onRequestClose: () => {},
  onAfterOpen: () => {},
  confirmBtn: '',
  width: '',
  height: '',
  children: undefined,
  className: '',
  isCloseable: false,
  isVisible: true,
  position: undefined,
  color: undefined,
  overlay: true,
  overlayClassName: '',
};

HeaderNtc.propTypes = {
  children: PropTypes.node,
};
HeaderNtc.defaultProps = {
  children: undefined,
};

BodyNtc.propTypes = {
  children: PropTypes.node,
};
BodyNtc.defaultProps = {
  children: undefined,
};

export default Notice;
